import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import { useComponentModels } from '../../../../../../api/rebalancer'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { useDialogStyles } from './common'

function getNumberOrDefault (str) {
  const result = Number.parseInt(str)
  if (isNaN(result)) return 0

  return result || 0
}

function AddComponentModelForm ({ onComplete, classificationTagTypeId }) {
  const classes = useDialogStyles()

  const [searchText, setSearchText] = useState()
  const query = useMemo(() => {
    return {
      filters: {
        classificationTagTypeId
      },
      textFilters: {
        name: [{ op: 'contains', value: searchText }]
      },
      take: 20
    }
  }, [searchText, classificationTagTypeId])
  const { data, isFetching } = useComponentModels(query)
  const weightRef = useRef()

  return (
    <>
      <div className={classes.form}>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput onChange={setSearchText} placeholder='Search Name of Component Model' style={{ height: 'unset', minHeight: '54px' }} />
          </SydLabel>
          <SydLabel label='Weight'>
            <SydInput ref={weightRef} type='number' minWidth='0' />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : data?.length ? (
            <div className={classes.list}>
              {data.map(x => (
                <div className={classes.listItem} key={x.componentModelId}>
                  <div>{x.name}</div>
                  <SydButton size='xs' variant='primary' onClick={() => { onComplete({ ...x, weight: getNumberOrDefault(weightRef.current.value) }) }}>Add</SydButton>
                </div>
              ))}
            </div>
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
    </>
  )
}

AddComponentModelForm.propTypes = {
  onComplete: PropTypes.func,
  classificationTagTypeId: PropTypes.number
}

export default AddComponentModelForm
