import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../../atoms/Text'
import EditButton from './EditButton'

const useStyles = makeStyles((theme) => ({
  titleContainer: ({ adornment }) => ({
    marginBottom: '18px',
    display: 'flex',
    flexDirection: 'row',
    ...adornment ? { alignItems: 'center' } : {}
  }),
  title: {
    fontSize: '22px',
    color: '#141929'
  }
}))

const TitleWithEditButton = ({
  text,
  onClick,
  editMode,
  disabled,
  adornment,
  hideEditButton = false
}) => {
  const classes = useStyles({ adornment })
  return (
    <div className={classes.titleContainer}>
      <Text
        text={text}
        className={classes.title}
      />
      {!hideEditButton && (
        <EditButton
          onClick={onClick}
          editMode={editMode}
          disabled={disabled}
        />
      )}
      {adornment}
    </div>
  )
}

TitleWithEditButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  adornment: PropTypes.node,
  hideEditButton: PropTypes.bool
}

export default TitleWithEditButton
