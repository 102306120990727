import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import InfiniteScroll from 'react-infinite-scroll-component'
import isEmpty from 'lodash/isEmpty'
import { darken, makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import KeyMirror from 'keymirror'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import {
  fetchClientBalances,
  fetchClientsBalanceActivity,
  fetchSalesforceClient, integrations
} from '../../../service'
import {
  useAppContext,
  useAvailableDates,
  useSetCurrentClient,
  useSetIsNotesBoardOpen,
  useSetShowHeaderBackButton
} from '../../../redux/slices/appContext'
import { getActiveCRMFromIntegrationsArray, getSafeDate, numeralByCase } from '../../../utils'
import {
  CALC_TYPES,
  DEFAULT_CLIENT_CARDS_PAGE_SIZE,
  FEATURE_FLAG,
  CLIENTS_CHILDREN_TYPE,
  CLIENT_CONVERSATION_FILTERS,
  TEXT_VARIANTS,
  SALESFORCE_INTEGRATION
} from '../../../constants'
import { useFeatureFlag } from '../../../redux/slices/appConfig'
import { useFetchState, useToggle } from '../../../hooks'
import Avatar from '../../atoms/Avatar'
import EmptySection from '../../atoms/EmptySection'
import TableSkeleton from '../../atoms/TableSkeleton'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import Select from '../../atoms/Select'
import Icon from '../../atoms/Icon'
import NoteModal from '../../molecules/NoteModal'
import { TableVirtualizedWithDynamicHeight } from '../../molecules/Table'
import RightPanel from '../RightPanel'
import { useSetNotesClientId } from '../../../redux/slices/noteContext'
import fastDeepEqual from '../../../utils/fastDeepEqual'
import ClientCard from './ClientCard'
import SalesforceInfo from './SalesforceInfo'
import ClientCardList from './ClientCardList'
import ClientCardBody from './clientCardBody'
import ClientTags from './ClientTags'

dayjs.extend(utc)

const useClientCardStyles = makeStyles((theme) => ({
  notification: {
    backgroundColor: theme.palette.success.A500,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: `${darken(theme.palette.success.A500, 0.1)} !important`
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  container: () => ({
    margin: 'inherit',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    padding: '0px 24px'
  }),
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem'
  },
  conversationsTimeFilter: {
    marginLeft: 'auto'
  },
  filters: {
    marginBottom: '40px'
  },
  filter: {
    marginRight: '12px'
  },
  section: ({ viewType }) => ({
    position: 'relative',
    ...(viewType === VIEW_TYPES.LIST
      ? {
        height: 'calc(100% - 6.5rem)'
      }
      : {
        marginBottom: '2rem'
      })
  }),
  sectionTitle: {
    marginBottom: '1rem'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 0px 32px 0px'
  },
  circularProgressContainer: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  clientsTypeSelect: {
    paddingTop: '0.75rem'
  },
  iconButton: {
    padding: '0.5rem',
    backgroundColor: theme.palette.seaFoam,
    '&:hover': {
      backgroundColor: darken(theme.palette.seaFoam, 0.1)
    }
  }
}))

const getListViewColumns = (includeOptionsColumn = false) => {
  const columns = [
    { hidden: true },
    { name: 'Client Name', alignment: 'left' },
    { name: 'Total Account Value', alignment: 'left' },
    { name: 'Message Client Button', alignment: 'center' }
  ]
  if (includeOptionsColumn) {
    columns.push({ name: 'Options', alignment: 'center' })
  }
  return columns
}

const CLIENT_CARD_WIDTH = 350
const DEFAULT_PAGE_SIZE_CARD_RECENT = 5
const DEFAULT_PAGE_SIZE_CARD_CONVERSATIONS = 10

const getClientCardsPageSizeFixedToMultipleOf = (
  containerCapacity,
  multiple = 5
) => {
  return (
    Math.round(containerCapacity / multiple) * multiple * 2 ||
    DEFAULT_CLIENT_CARDS_PAGE_SIZE
  )
}

const formatGroupParamName = (groupName) => {
  return `${groupName}Ids`
}

const getCustomLevelTypeFilters = (customLevelTypeFilters) => {
  return customLevelTypeFilters
    .filter(({ groupName }) => !isEmpty(groupName))
    .reduce(
      (acc, { groupName, groupIds }) => ({
        ...acc,
        [formatGroupParamName(groupName)]: groupIds
      }),
      {}
    )
}

const VIEW_TYPES = KeyMirror({
  LIST: null,
  BLOCKS: null
})

const CLIENT_CARD_TYPES = [
  { label: 'Conversations', value: CLIENTS_CHILDREN_TYPE.cardConversation },
  { label: 'Recents', value: CLIENTS_CHILDREN_TYPE.cardRecent }
]

const CLIENT_CONVERSATION_FILTER_OPTIONS = [
  { label: 'Last 30 days', value: CLIENT_CONVERSATION_FILTERS.last30Days }
]

const orderClients = (clients, sortBy) => {
  const [orderByField, orderDirection] = sortBy.split('+')
  const clientsSorted = orderBy(clients, [orderByField], [orderDirection])
  return clientsSorted
}

const [selectedCardConversationFilter] = CLIENT_CONVERSATION_FILTER_OPTIONS

// TODO: use viewContext to handle the open state of sidePanel
function ClientCardsView ({
  title,
  pageSize,
  sort,
  enableSalesForceClientInfo,
  hideRecentsSection
}) {
  const history = useHistory()
  const {
    clientsNotifications,
    isAdvisor,
    userId
  } = useAppContext()

  const setCurrentClient = useSetCurrentClient()
  const [availableDates, loadingAvailableDates] = useAvailableDates()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()
  const [tagFilters, setTagFilters] = useState({})
  const [clientCardsContainerRef, setClientCardsContainerRef] = useState(null)

  const [viewContext, setViewContext] = useState({
    clients: [],
    categoryGroupsFilter: [],
    clientCardsPageNumber: 0,
    clientCardsPageSize: pageSize,
    clientCardsHasMore: true,
    useDynamicPageSize: true,
    sort
  })

  const clientCardClasses = useClientCardStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCardType, setCardType] = useState(
    CLIENTS_CHILDREN_TYPE.cardRecent
  )
  const [viewType, setViewType] = useState(VIEW_TYPES.BLOCKS)
  const [rows, setRows] = useState([])
  const [
    isListViewLoading,
    ,
    toggleListViewLoadingOn,
    toggleListViewLoadingOff
  ] = useToggle(true)
  const [openRightPanel, setOpenRightPanel] = useState(false)
  const [salesforceInfo, setSalesforceInfo] = useState({})

  const setNotesClientId = useSetNotesClientId()
  const setIsNotesBoardOpen = useSetIsNotesBoardOpen()

  const onClientCardClick = useCallback(
    ({ clientId }) => {
      setCurrentClient(clientId)
      history.push('/')
    },
    [history, setCurrentClient]
  )

  const defaultDateRange = useMemo(() => {
    return {
      startDate: getSafeDate(availableDates, {
        operation: 'subtract',
        unitType: 'day',
        unitValue: 30
      }),
      endDate: getSafeDate(availableDates, { useMax: true })
    }
  }, [availableDates])

  const { cardRecent = [] } = useFetchState(useCallback(async (setSafeState) => {
    const { data } = await fetchClientsBalanceActivity({
      take: DEFAULT_PAGE_SIZE_CARD_RECENT,
      userId,
      ...defaultDateRange
    })
    setSafeState({ cardRecent: data })
  }, [userId, defaultDateRange]))

  const { cardConversations = [] } = useFetchState(useCallback(async (setSafeState) => {
    const { data } = await fetchClientsBalanceActivity({
      take: DEFAULT_PAGE_SIZE_CARD_CONVERSATIONS,
      userId,
      clientIds: [],
      ...defaultDateRange
    })
    setSafeState({ cardConversations: data })
  }, [userId, defaultDateRange]))

  const { active: INTEGRATIONS, extras: INTEGRATIONS_LIST = { active: [] } } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  const SHOW_SALESFORCE_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(SALESFORCE_INTEGRATION)
  const [activeCrm] = useState(INTEGRATIONS ? getActiveCRMFromIntegrationsArray(INTEGRATIONS_LIST.active) : null)
  const [isCrmAuthorized, setIsCrmAuthorized] = useState(false)

  useEffect(() => {
    if (activeCrm) {
      integrations.getAuthorizations()
        .then(({ data }) => {
          setIsCrmAuthorized(data[activeCrm]?.authorized || false)
        })
    }
  }, [activeCrm])

  const renderCardList = useCallback((clients) => {
    if (isEmpty(clients)) return null
    return (
      <ClientCardList>
        {clients.map((client) => (
          <ClientCard
            key={client.clientId}
            data={{ client }}
            isCrmAuthorized={isCrmAuthorized}
            onClick={() => onClientCardClick({ clientId: client.clientId })}
          >
            <ClientCardBody client={client} />
          </ClientCard>
        ))}
      </ClientCardList>
    )
  }, [isCrmAuthorized, onClientCardClick])

  const cardRecentChildren = useMemo(() => {
    return renderCardList(cardRecent)
  }, [cardRecent, renderCardList])

  const cardConversationChildren = useMemo(() => {
    return renderCardList(cardConversations)
  }, [cardConversations, renderCardList])

  const cardChildren = useMemo(() => {
    return renderCardList(viewContext.clients)
  }, [viewContext.clients, renderCardList])

  const classes = useStyles({ viewType })

  const enableSalesForceButton = useMemo(() => {
    if (enableSalesForceClientInfo !== undefined) {
      return enableSalesForceClientInfo
    }
    return SHOW_SALESFORCE_CLIENT_INFO
  }, [enableSalesForceClientInfo, SHOW_SALESFORCE_CLIENT_INFO])

  const fetchPaginatedClients = useCallback(
    async ({ take, offset = 0, sort }, tagFilters) => {
      try {
        setIsLoading(true)

        const startDate = getSafeDate(availableDates, { useMin: true })
        const endDate = getSafeDate(availableDates, { useMax: true })

        const groupFilters = getCustomLevelTypeFilters(Object.values(tagFilters))
        const { data: clients } = await fetchClientBalances({
          ...groupFilters,
          startDate,
          endDate,
          offset,
          take,
          sort,
          calcType: CALC_TYPES.balance
        })

        return clients
      } catch (err) {
        console.error(err)
        return []
      } finally {
        setIsLoading(false)
      }
    },
    [availableDates]
  )

  const clientCardsPageSize = useMemo(() => {
    if (viewContext.useDynamicPageSize) {
      if (clientCardsContainerRef) {
        const { width: cardsContainerWidth } =
          clientCardsContainerRef.getBoundingClientRect()
        const clientCardContainerCapacity =
          cardsContainerWidth / CLIENT_CARD_WIDTH
        const clientCardContainerPageSize =
          getClientCardsPageSizeFixedToMultipleOf(clientCardContainerCapacity)
        return clientCardContainerPageSize
      }
      return null
    }
    return viewContext.clientCardsPageSize
  }, [
    viewContext.useDynamicPageSize,
    viewContext.clientCardsPageSize,
    clientCardsContainerRef
  ])

  useEffect(() => {
    setShowHeaderBackButton(false)

    return () => {
      setViewContext((prevState) => ({
        ...prevState,
        clients: [],
        clientCardsPageNumber: 0,
        clientCardsHasMore: true,
        clientCardsPageSize: DEFAULT_CLIENT_CARDS_PAGE_SIZE
      }))
    }
  }, [setShowHeaderBackButton])

  useEffect(() => {
    async function fetchClientCardsFirstPage () {
      const clients = await fetchPaginatedClients(
        {
          take: clientCardsPageSize,
          offset: 0,
          sort: viewContext.sort
        },
        tagFilters
      )
      setViewContext((prevState) => ({
        ...prevState,
        clients: orderClients([...clients], sort),
        clientCardsPageNumber: 1,
        clientCardsHasMore: !isEmpty(clients)
      }))
    }
    if (!loadingAvailableDates && clientCardsPageSize) {
      fetchClientCardsFirstPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewContext.clientCardsPageSize,
    sort,
    loadingAvailableDates,
    tagFilters,
    clientCardsPageSize
  ])

  const onCommentClick = useCallback(
    async (event, clientId) => {
      event.stopPropagation()
      setNotesClientId(clientId)
      setIsNotesBoardOpen(true)
    },
    [setNotesClientId, setIsNotesBoardOpen]
  )

  const onGetExtraInfo = useCallback(async (event, salesforceId) => {
    event.stopPropagation()
    setOpenRightPanel(true)
    const { data } = await fetchSalesforceClient({
      clientId: salesforceId
    })
    setSalesforceInfo(data)
  }, [])

  const hasNotification = useCallback(
    ({ client }) => {
      if (client) {
        const { clientId } = client
        const clientNotification = clientsNotifications.find(
          ({ clientId: clientWithNotificationId }) =>
            clientWithNotificationId === clientId
        )
        return (
          clientNotification && clientNotification.notifications.unread !== 0
        )
      }
    },
    [clientsNotifications]
  )

  const rowsMapper = useCallback(
    (data) => {
      return data.map((datum) => {
        const {
          clientId,
          longName,
          shortName,
          clientAbbreviation,
          profilePic,
          endingValue,
          salesforceId
        } = datum
        const clientName = longName || shortName
        const avatarLetters = clientAbbreviation || shortName || ''
        const useAbbreviation = !!clientAbbreviation
        const rowsHydrated = [
          { value: `${clientId}-${salesforceId}`, hidden: true },
          {
            value: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                onClick={(e) => {
                  setCurrentClient(clientId)
                  history.push('/')
                }}
              >
                <Avatar
                  customSize='2.5rem'
                  avatarLetters={avatarLetters}
                  src={profilePic}
                  useAbbreviation={useAbbreviation}
                  useOneInitial
                />
                <Box ml='0.5rem'>
                  <Text text={clientName} />
                </Box>
              </Box>
            ),
            alignment: 'left'
          },
          {
            value: (
              <NumberFormat
                title={numeralByCase(endingValue)}
                number={endingValue}
                format='0,0a'
              />
            ),
            alignment: 'left'
          },
          {
            value: (
              <IconButton
                size='small'
                className={clsx(classes.iconButton, {
                  [clientCardClasses.notification]: hasNotification(datum)
                })}
                onClick={(event) => onCommentClick(event, clientId)}
              >
                <Icon name='chat' customSize='1rem' padding='1rem' />
              </IconButton>
            ),
            alignment: 'center'
          }
        ]
        if (enableSalesForceButton && isAdvisor) {
          rowsHydrated.push({
            value: (
              <IconButton
                size='small'
                className={clsx(classes.iconButton)}
                onClick={(event) => {
                  onGetExtraInfo(event, salesforceId)
                }}
              >
                <Icon name='salesforce' customSize='1rem' padding='1rem' />
              </IconButton>
            ),
            colSpan: 2,
            alignment: 'center'
          })
        }
        return rowsHydrated
      })
    },
    [
      classes.iconButton,
      clientCardClasses.notification,
      history,
      isAdvisor,
      onGetExtraInfo,
      onCommentClick,
      hasNotification,
      setCurrentClient,
      enableSalesForceButton
    ]
  )

  const fetchMoreData = useCallback(async () => {
    const clientCardsPageNumber = viewContext.clientCardsPageNumber
    if (!clientCardsPageSize || viewType === VIEW_TYPES.LIST) return
    if (loadingAvailableDates || isLoading || clientCardsPageNumber === 0) {
      return
    }

    try {
      setIsLoading(true)
      const clients = await fetchPaginatedClients(
        {
          take: clientCardsPageSize,
          offset: clientCardsPageNumber * clientCardsPageSize
        },
        tagFilters
      )
      setViewContext((prevState) => ({
        ...prevState,
        clients: orderClients([...prevState.clients, ...(clients || [])], sort),
        clientCardsPageNumber: clientCardsPageNumber + 1,
        clientCardsHasMore: !isEmpty(clients)
      }))
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [
    viewContext.clientCardsPageNumber,
    sort,
    viewType,
    isLoading,
    tagFilters,
    clientCardsPageSize,
    fetchPaginatedClients,
    loadingAvailableDates
  ])

  const loader = useMemo(
    () => (
      <div className={classes.circularProgressContainer}>
        <CircularProgress size='4rem' />
      </div>
    ),
    [classes.circularProgressContainer]
  )

  const onClientCardTypeChange = useCallback((event) => {
    setCardType(event.target.value)
  }, [])

  const onClientCardsContainerRefChange = useCallback((node) => {
    if (!isEmpty(node)) {
      setClientCardsContainerRef(node)
    }
  }, [])

  const onTagChange = useCallback((groupTypeId, selectedTags) => {
    const tags = selectedTags.reduce(
      (acc, { value: groupId, payload: { groupTypeId, groupName } }) => ({
        ...acc,
        [groupTypeId]: {
          groupTypeId,
          groupName,
          groupIds: [...(acc[groupTypeId]?.groupIds || []), groupId]
        }
      }),
      {}
    )
    const clearTagsIfAny = (prevState) =>
      isEmpty(selectedTags)
        ? { [groupTypeId]: { ...prevState[groupTypeId], groupIds: [] } }
        : {}

    setTagFilters((prevState) => {
      const newState = {
        ...prevState,
        ...tags,
        ...clearTagsIfAny(prevState)
      }
      return fastDeepEqual(prevState, newState) ? prevState : newState
    })

    setViewContext((prevState) => ({
      ...prevState,
      clientCardsHasMore: true,
      clientCardsPageNumber: 0
    }))
  }, [])

  const closeRightPanel = useCallback((event) => {
    event.stopPropagation()
    setOpenRightPanel(false)
  }, [])

  useEffect(() => {
    async function initListView () {
      try {
        toggleListViewLoadingOn()
        const clients = await fetchPaginatedClients(
          { take: 300 },
          tagFilters
        )
        const rows = rowsMapper(
          clients.sort((clientA, clientB) =>
            clientA.longName.localeCompare(clientB.longName)
          )
        )
        setRows(rows)
      } catch (err) {
        console.error(err)
      } finally {
        toggleListViewLoadingOff()
      }
    }
    if (viewType === VIEW_TYPES.LIST) {
      initListView()
    }
  }, [
    viewType,
    rowsMapper,
    tagFilters,
    fetchPaginatedClients,
    toggleListViewLoadingOn,
    toggleListViewLoadingOff
  ])

  useEffect(() => {
    if (
      viewContext.clients?.length &&
      viewContext.clients?.length >= DEFAULT_CLIENT_CARDS_PAGE_SIZE
    ) {
      const infiniteElement = clientCardsContainerRef.lastChild.firstChild
      const isParentHeightHigher =
        clientCardsContainerRef.clientHeight >= infiniteElement.clientHeight

      const timeout = setTimeout(() => {
        if (isParentHeightHigher && viewType === VIEW_TYPES.BLOCKS) {
          fetchMoreData()
        }
      }, 0)

      return () => clearTimeout(timeout)
    }
  }, [viewContext.clients, clientCardsContainerRef, fetchMoreData, viewType])

  const cardsListRendered = useMemo(() => {
    if (isListViewLoading) {
      return <TableSkeleton columns={4} rows={10} />
    }
    const shouldIncludeSalesForceOption =
      isAdvisor && enableSalesForceButton

    return (
      <TableVirtualizedWithDynamicHeight
        height='100%'
        rows={rows}
        labels={getListViewColumns(shouldIncludeSalesForceOption)}
      />
    )
  }, [rows, isAdvisor, enableSalesForceButton, isListViewLoading])

  const cardsBlocksRendered = useMemo(() => {
    if (isEmpty(viewContext.clients) && !viewContext.clientCardsHasMore) {
      return (
        <EmptySection
          title='No clients found'
          description=''
          titleStyles={{
            fontSize: '2rem !important',
            fontWeight: 'bold'
          }}
        />
      )
    }

    return (
      <InfiniteScroll
        scrollableTarget='client-cards-list'
        loader={loader}
        next={fetchMoreData}
        hasMore={
          viewContext.clientCardsHasMore &&
          viewContext.clients.length >= clientCardsPageSize
        }
        dataLength={viewContext.clients.length || clientCardsPageSize}
        scrollThreshold={0.7}
      >
        {cardChildren}
      </InfiniteScroll>
    )
  }, [
    viewContext.clients,
    viewContext.clientCardsHasMore,
    loader,
    cardChildren,
    fetchMoreData,
    clientCardsPageSize
  ])

  return (
    <div
      id='client-cards-list'
      ref={onClientCardsContainerRefChange}
      className={classes.container}
    >
      <div className={classes.title}>
        <Text
          text={title}
          variant={TEXT_VARIANTS.h1}
          customFontWeight='400'
          customFontSize='40px'
        />
        <Box ml='auto'>
          <ToggleButtonGroup
            exclusive
            value={viewType}
            onChange={(_, nextView) => setViewType(nextView)}
          >
            <ToggleButton value={VIEW_TYPES.LIST} aria-label={VIEW_TYPES.LIST}>
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton
              value={VIEW_TYPES.BLOCKS}
              aria-label={VIEW_TYPES.BLOCKS}
            >
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>
      {viewType === VIEW_TYPES.BLOCKS && !hideRecentsSection && (
        <>
          <div>
            <div className={classes.filterTitle}>
              <Select
                className={classes.clientsTypeSelect}
                textVariant={TEXT_VARIANTS.h1}
                onChange={onClientCardTypeChange}
                options={CLIENT_CARD_TYPES}
                selectedValue={selectedCardType}
              />
              <Select
                disabled
                className={classes.conversationsTimeFilter}
                options={CLIENT_CONVERSATION_FILTER_OPTIONS}
                selectedValue={selectedCardConversationFilter.value}
              />
            </div>
            {selectedCardType === CLIENTS_CHILDREN_TYPE.cardConversation ? (
              <div>{cardConversationChildren}</div>
            ) : (
              <div>{cardRecentChildren}</div>
            )}
          </div>
        </>
      )}
      <div className={classes.filters}>
        <ClientTags onChange={onTagChange} />
      </div>
      <div className={classes.section}>
        {viewType === VIEW_TYPES.LIST ? cardsListRendered : cardsBlocksRendered}
        <RightPanel
          open={openRightPanel}
          title={<Text text='Salesforce Info' variant={TEXT_VARIANTS.h2} />}
          width='400px'
          height='100%'
        >
          <SalesforceInfo data={salesforceInfo} onClose={closeRightPanel} />
        </RightPanel>
        <NoteModal />
      </div>
    </div>
  )
}

ClientCardsView.propTypes = {
  title: PropTypes.string,
  pageSize: PropTypes.number,
  sort: PropTypes.string,
  enableSalesForceClientInfo: PropTypes.bool,
  hideRecentsSection: PropTypes.bool
}

ClientCardsView.defaultProps = {
  title: 'My Clients',
  pageSize: 10,
  sort: 'shortName+desc',
  enableSalesForceClientInfo: undefined,
  hideRecentsSection: false
}

export default ClientCardsView
