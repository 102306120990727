import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import SecuritySummarySection from './SecuritySummarySection'
import SecurityDetailsSection from './SecurityDetailsSection'

function SecurityTab ({ userId }) {
  return (
    <FadeIn>
      <SecuritySummarySection userId={userId} />
      <SecurityDetailsSection userId={userId} />
      <PersonalSpace />
    </FadeIn>
  )
}

SecurityTab.propTypes = {
  userId: PropTypes.number
}

export default SecurityTab
