import React from 'react'
import { Box, Grid } from '@material-ui/core'
import Skeleton from '../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../constants'

const UsersSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item container direction='column' alignItems='center' md={2} xs={12}>
        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Skeleton
            variant={SKELETON_VARIANTS.circle}
            width='120px'
            height='120px'
          />
        </Box>
      </Grid>
      <Grid item md={10} xs={12}>
        {new Array(3).fill(0).map((_, index) => {
          return (
            <React.Fragment key={`skeleton-${index}`}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Skeleton
                    variant={SKELETON_VARIANTS.text}
                    width='100%'
                    height='88px'
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Skeleton
                    variant={SKELETON_VARIANTS.text}
                    width='100%'
                    height='88px'
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Skeleton
                    variant={SKELETON_VARIANTS.text}
                    width='100%'
                    height='88px'
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default UsersSkeleton
