import React from 'react'
import PropTypes from 'prop-types'
import { useSearchParam } from '../../../hooks/useSearchParam'
import FadeIn from '../../molecules/Transitions/FadeIn'
import FirmReport from '../../pages/firmReporting/FirmReport'

function DenaliReport ({ reportId, columns, hideExport }) {
  const filter = useSearchParam('filter')
  const _col = columns?.length ? columns : undefined

  return (
    <FadeIn>
      <FirmReport reportId={reportId} defaultFilter={filter} columns={_col} hideExport={hideExport} />
    </FadeIn>
  )
}

DenaliReport.propTypes = {
  reportId: PropTypes.string,
  columns: PropTypes.array,
  hideExport: PropTypes.bool
}

DenaliReport.propTypes = {
  hideExport: false
}

export default DenaliReport
