import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import EditBillingAccountsForm from './form'

const EditBillingAccountsDialog = React.forwardRef(function EditBillingAccountsDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (account, billingAccounts) => setDialogState({ open: true, processing: false, account, billingAccounts })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Edit Billing Accounts'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<EditBillingAccountsForm account={dialogState.account} billingAccounts={dialogState.billingAccounts} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

EditBillingAccountsDialog.propTypes = {
}

export default EditBillingAccountsDialog
