import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import get from 'lodash/get'
import clsx from 'clsx'
import DebouncedInput from '../../molecules/DebouncedInput'

const useStyles = makeStyles((theme) => ({
  selectBox: {
    position: 'relative',
    overflowY: 'auto',
    borderRadius: theme.layout.radius.thin,
    border: `1px solid ${theme.palette.gray.darker}`,
    maxHeight: '300px',
    minHeight: '300px'
  },
  selectBoxContent: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse'
  },
  selectBoxItem: {
    display: 'table-row',
    '& span': {
      display: 'table-cell',
      padding: theme.layout.padding.thin
    },
    '&.__selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    '&.__disabled': {
      color: theme.palette.gray.darker,
      fontStyle: 'italic',
      '&:hover': {
        backgroundColor: 'inherit',
        cursor: 'not-allowed'
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.gray.main
    }
  }
}))

function SydSelectBox ({ options, labelAccessor, valueAccessor, value, onChange, className }) {
  const classes = useStyles()
  const _labelAccessor = useCallback((opt) => {
    if (Array.isArray(labelAccessor)) {
      return labelAccessor.map(a => get(opt, a))
    }
    return [get(opt, labelAccessor)]
  }, [labelAccessor])
  const _valueAccessor = useCallback((opt) => {
    return get(opt, valueAccessor)
  }, [valueAccessor])

  const _disabledAccessor = useCallback((opt) => {
    return get(opt, 'disabled') ?? false
  }, [])

  const selected = useMemo(() => {
    return (options || []).find(x => _valueAccessor(x) === value)
  }, [options, value, _valueAccessor])

  const [filter, setFilter] = useState('')
  const filteredOptions = useMemo(() => {
    return (options || []).filter(x => _labelAccessor(x)?.some(y => y?.toLowerCase().includes(filter?.toLowerCase() || '')))
  }, [filter, options, _labelAccessor])

  return (
    <div className={classes.selectBoxWrap}>
      <DebouncedInput delay={100} onChange={setFilter} style={{ minWidth: 'unset !important' }} />
      <div className={clsx(classes.selectBox, className)}>
        <div className={classes.selectBoxContent}>
          {filteredOptions.map(opt => {
            const v = _valueAccessor(opt)
            const l = _labelAccessor(opt)
            const disabled = _disabledAccessor(opt)
            return (
              <div
                key={v}
                onClick={() => !disabled ? onChange(v) : null}
                className={clsx(classes.selectBoxItem, {
                  __selected: v === _valueAccessor(selected),
                  __disabled: disabled
                })}
              >
                {l.map((li, lix) => (
                  <span key={`${v}_${lix}`}>{li}</span>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

SydSelectBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  labelAccessor: PropTypes.string,
  valueAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string
}

SydSelectBox.defaultProps = {
  labelAccessor: 'label',
  valueAccessor: 'value'
}

export default SydSelectBox
