import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'

import {
  Grid,
  Button,
  Box,
  FormControlLabel,
  FormLabel,
  makeStyles,
  useTheme
} from '@material-ui/core'
import {
  Controller,
  useFormContext
} from 'react-hook-form'

import {
  useGetUser,
  useCurrentUser,
  useRemoveUserQueries
} from '../../../../api/users'

import ClientProfilePicture from '../ClientDetailsView/ClientProfilePicture'
import Text from '../../../atoms/Text'
import TitleSubtitle from '../../../atoms/TitleSubtitle'
import TextInput from '../../../molecules/TextInput'
import Switch from '../../../molecules/Switch'
import RoundedButton from '../../../atoms/RoundedButton'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import Dialog from '../../../molecules/Dialog/Dialog'
import {
  messageToUse,
  emailRules
} from '../common'
import {
  OKTA_USER_STATUS,
  BUTTON_SIZES,
  SUCCESS_ALERT,
  ERROR_ALERT,
  USERS_FORM_ACTIONS,
  ADMIN_ROUTES
} from '../../../../constants'

import {
  editUser,
  createAdvisor,
  createAdvisorSkipInvite,
  reactivateUser,
  inviteUser
} from '../../../../service'
import UsersSkeleton from './Skeleton'
import PermissionSelect from './PermissionSelect'
import { ADVISOR_FORM_NAMES } from './helpers'

const STATUS = {
  staged: 'Staged',
  provisioned: 'Pending User Action',
  active: 'Active',
  recovery: 'Password Reset'
}

const useStyles = makeStyles((theme) => ({
  pillBtn: {
    border: `1.5px solid ${theme.palette.summitBlue}`,
    color: theme.palette.darkJungle,
    borderRadius: 25,
    padding: '0.25rem 1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillMargin: {
    marginTop: '0.625rem'
  },
  flexItem: {
    display: 'flex'
  },
  headerContainer: {
    display: 'flex',
    gap: '1.5rem',
    marginBottom: '10px'
  },
  userInfo: {
    gap: '1.5rem'
  },
  switch: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: '1.625rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0'
    },
    '& > .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      '& > .MuiFormControlLabel-label': {
        marginRight: '2.25rem',
        fontSize: '1rem',
        color: theme.palette.darkJungle,
        fontWeight: 'bold'
      }
    }
  },
  centerColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  label: {
    color: theme.palette.darkJungle,
    fontSize: '0.875rem',
    lineHeight: '19px',
    fontWeight: 'bold',
    display: 'block'
  },
  inputLabel: {
    color: theme.palette.darkJungle,
    fontSize: '12px',
    lineHeight: '19px',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '10px'
  },
  status: {
    width: '0.875rem',
    height: '0.875rem',
    borderRadius: '14px',
    display: 'block'
  },
  active: {
    border: `1px solid ${theme.palette.green.seaFoam}`,
    backgroundColor: theme.palette.green.light
  },
  staged: {
    border: `1px solid ${theme.palette.silverFoil}`,
    backgroundColor: theme.palette.platinum
  },
  provisioned: {
    border: `1px solid ${theme.palette.yellowOrange}`,
    backgroundColor: theme.palette.navajo
  },
  recovery: {
    border: `1px solid ${theme.palette.melon}`,
    backgroundColor: theme.palette.palePink
  },
  bottomContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '2%',
    right: 0,
    left: 0,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'column-reverse'
    },
    zIndex: 3
  },
  gralInfo: {
    padding: '0 1.7rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0'
    }
  },
  editInfo: {
    gap: '1.5rem',
    display: 'flex',
    flexDirection: 'column'
  },
  userTypeSelect: {
    width: '100% !important',
    padding: '16px 14px',
    border: '2px solid #EEF0F8',
    opacity: '1'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem'
  },
  editingStatus: {
    gap: '1rem'
  },
  statusLabel: ({ titleFontSize }) => ({
    fontSize: titleFontSize || '0.875rem'
  }),
  userTypeLabel: {
    fontSize: '0.75rem',
    marginBottom: '0.625rem'
  },
  dialogBody: {
    padding: '8px 0'
  },
  switchLabel: {
    position: 'relative',
    bottom: '1.25rem'
  },
  internalSwitch: {
    justifyContent: 'center'
  }
}))

const USER_TYPES = [
  { label: 'Advisor', value: 'advisor' },
  { label: 'Wealth Owner', value: 'client' }
]

const advisor = USER_TYPES[0].value

function checkUserStatus (user) {
  return user?.status === OKTA_USER_STATUS.staged || user?.status === OKTA_USER_STATUS.provisioned
}

const Status = ({
  titleFontSize,
  subtitleFontSize,
  user,
  onReactivateUser,
  reactivating
}) => {
  const classes = useStyles({ titleFontSize })
  const theme = useTheme()

  // Possible status of a user
  const isStagedUser = user?.status === OKTA_USER_STATUS.staged
  const isProvisionedUser = user?.status === OKTA_USER_STATUS.provisioned
  const isActiveUser = user?.status === OKTA_USER_STATUS.active
  const isRecoveryUser = user?.status === OKTA_USER_STATUS.recovery
  const isNotActiveUser = isStagedUser || isProvisionedUser

  return (
    <>
      <FormLabel
        className={classes.label}
        classes={{
          root: clsx(classes.statusLabel)
        }}
      >
        Status
      </FormLabel>
      <Box
        display='flex'
        gridGap='1.5rem'
      >
        <Box
          display='flex'
          alignItems='center'
          gridGap='0.5rem'
        >
          <span
            className={clsx(classes.status, {
              [classes.active]: isActiveUser,
              [classes.staged]: isStagedUser,
              [classes.provisioned]: isProvisionedUser,
              [classes.recovery]: isRecoveryUser
            })}
          />
          <Text
            customFontSize={subtitleFontSize}
            customFontWeight='bold'
            color={theme.palette.summitBlue}
            textTransform='capitalize'
          >
            {STATUS[user?.status?.toLowerCase()]}
          </Text>
        </Box>
        {
          isNotActiveUser && (
            <Box>
              <Button
                className={classes.pillBtn}
                onClick={onReactivateUser}
                variant='outlined'
                disabled={reactivating || !checkUserStatus(user)}
              >
                {isStagedUser ? 'Reactivate' : 'Resend Invite'}
              </Button>
            </Box>
          )
        }
      </Box>
    </>
  )
}
function UserForm ({
  editingUser,
  setEditingUser,
  disableEdit,
  setDisableEdit
}) {
  const { userId } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const isNew = !userId

  const [alert, setAlert] = useState({})
  const [openCreateUser, setOpenCreateUser] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userAction, setUserAction] = useState({})
  const [reactivating, setReactivating] = useState(false)

  const internalRef = useRef(null)
  const allAccountsRef = useRef(null)

  const removeUserQueries = useRemoveUserQueries()

  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState,
    getValues
  } = useFormContext()

  const { isValid, errors } = formState

  const setUser = useCallback((user) => {
    // We setup some attributes that we are using in the parent
    // userId
    // internalId
    // currentInternal
    // currentInternalId
    // userStatus
    let allAccountsAccess = user.allAccounts
    if (user.allAccounts === undefined) {
      allAccountsAccess = user.allAccountsAccess
    }

    reset(
      {
        ...getValues(),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        internal: user.internal,
        internalId: user.internalId,
        allAccountsAccess,
        userId: user.userId,
        userStatus: user.status,
        avatarMediaId: user.avatarMediaId,
        userType: advisor
      }
    )
  }, [getValues, reset])

  function onSuccesUser (user) {
    if (user) {
      setUser(user)
    }
  }

  function onSuccessCurrentUser (currentUser) {
    if (currentUser) {
      reset(
        {
          ...getValues(),
          currentInternal: currentUser.internal,
          currentInternalId: currentUser.internalId
        }
      )
    }
  }

  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser(onSuccessCurrentUser)
  const { data: user, refetch: refetchUser, isLoading: isLoadingUser } = useGetUser(userId, onSuccesUser, isNew)

  const fullName = `${user?.firstName} ${user?.lastName}`

  const handleEditUser = useCallback(async (data) => {
    setIsSubmitting(true)

    try {
      const payload = {
        ...data,
        userId: user.internalId,
        avatarMediaId: data.avatarMediaId || null
      }

      await editUser(user.userId, payload)
      setAlert({ ...SUCCESS_ALERT, alertMessage: 'User edited successfully' })
      refetchUser()
      setDisableEdit.off()
      setEditingUser.off()
    } catch (error) {
      setAlert(ERROR_ALERT)
    } finally {
      setIsSubmitting(false)
    }
  }, [
    user,
    refetchUser,
    setDisableEdit,
    setEditingUser
  ])

  const handleCreateUser = useCallback(async (data, action) => {
    try {
      setOpenCreateUser(false)
      setIsSubmitting(true)

      const payload = {
        ...data,
        internal: !currentUser?.internal ? currentUser?.internal : data.internal,
        avatarMediaId: data.avatarMediaId || null,
        clientId: []
      }

      let response = null

      if (action === USERS_FORM_ACTIONS.ADD_INVITE) {
        response = await createAdvisor(payload)
      } else {
        response = await createAdvisorSkipInvite(payload)
      }

      setDisableEdit.off()
      setEditingUser.off()
      history.push(`${ADMIN_ROUTES.ADVISORS}/${response.data.userId}`)
    } catch (error) {
      if (error.code === 409) {
        setAlert({ ...ERROR_ALERT, alertMessage: 'Email must be unique - please enter a new email address' })
      } else {
        setAlert(ERROR_ALERT)
      }
    } finally {
      setIsSubmitting(false)
    }
  }, [currentUser, history, setDisableEdit, setEditingUser])

  const openCreateUserDialog = useCallback((data, action) => {
    setOpenCreateUser(true)
    setUserAction({
      action,
      createAdvisor: () => handleCreateUser(data, action)
    })
  }, [handleCreateUser])

  const onSubmitHandler = useCallback((values, event) => {
    const action = event.nativeEvent.submitter.id

    if (user) {
      return handleEditUser(values)
    }
    openCreateUserDialog(values, action)
  }, [user, handleEditUser, openCreateUserDialog])

  const onReactivateUser = useCallback(async () => {
    if (!isEmpty(user)) {
      setReactivating(true)

      try {
        const { status, userId } = user

        if (status === OKTA_USER_STATUS.staged) {
          await reactivateUser(userId)
        } else {
          await inviteUser(userId)
        }

        setAlert({
          ...SUCCESS_ALERT,
          alertMessage: 'An email was sent to the client with instructions to activate the account'
        })
      } catch (e) {
        setAlert(ERROR_ALERT)
      } finally {
        setReactivating(false)
      }
    }
  }, [user])

  const resetForm = useCallback(() => {
    if (user?.internal !== getValues('internal')) {
      internalRef.current.control.click()
    }

    if (user?.allAccountsAccess !== getValues('allAccountsAccess')) {
      allAccountsRef.current.control.click()
    }

    reset()
    setDisableEdit.off()
    setEditingUser.off()
  }, [reset, user, getValues, setDisableEdit, setEditingUser])

  const handleCancel = useCallback(() => {
    if (!isNew) {
      resetForm()
      return
    }
    history.push(ADMIN_ROUTES.ADVISORS)
  }, [history, isNew, resetForm])

  const closeDialog = () => setOpenCreateUser(false)

  useEffect(() => {
    return () => {
      removeUserQueries()
    }
  }, [removeUserQueries])

  useEffect(() => {
    if (!isNew && user && user?.internalId !== getValues('internalId')) {
      setUser(user)
    }
  }, [isNew, user, setUser, getValues])

  if ((!isNew && isLoadingUser) || isLoadingCurrentUser) {
    return <UsersSkeleton />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid
          container
          spacing={0}
          direction='column'
          className={classes.headerContainer}
        >
          <Grid container className={classes.userInfo}>
            <Grid
              item
              lg={1}
              xs={12}
              className={classes.centerColumn}
            >
              <ClientProfilePicture
                disabled={!editingUser}
                disabledOpacity={1}
                formMediaName='avatarMediaId'
                defaultMediaId={user?.avatarMediaId}
                uploadLabel={editingUser ? 'Upload' : ''}
              />
            </Grid>
            <Grid
              item
              lg={10}
              xs={12}
            >
              {
                editingUser ? (
                  <Grid container className={classes.editInfo}>
                    <Grid container spacing={4}>
                      <Grid item md={6} xs={12}>
                        <Controller
                          name='firstName'
                          render={() => (
                            <TextInput
                              fullWidth
                              name='firstName'
                              placeholder='First Name'
                              label='First Name*'
                              error={Boolean(errors.firstName)}
                              {...register('firstName', { required: true, maxLength: 30 })}
                            />)}
                          control={control}
                        />
                        {errors.firstName && (
                          <Text
                            text={messageToUse(errors.firstName)}
                            color={theme.palette.error.main}
                            className={classes.confirmError}
                          />
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Controller
                          name='lastName'
                          render={() => (
                            <TextInput
                              fullWidth
                              name='lastName'
                              placeholder='Last Name'
                              label='Last Name*'
                              error={Boolean(errors.lastName)}
                              {...register('lastName', { required: true, maxLength: 30 })}
                            />)}
                          control={control}
                          rules={{ required: true }}
                        />
                        {errors.lastName && (
                          <Text
                            text={messageToUse(errors.lastName)}
                            color={theme.palette.error.main}
                            className={classes.confirmError}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item md={8} xs={12}>
                        <Controller
                          name='email'
                          render={() => (
                            <TextInput
                              fullWidth
                              name='email'
                              placeholder='Email'
                              label='Email*'
                              disabled={Boolean(user)}
                              error={Boolean(errors.email)}
                              {...register('email', emailRules)}
                            />)}
                          control={control}
                          rules={{ required: true }}
                        />
                        {errors.email && (
                          <Text
                            text={messageToUse(errors.email)}
                            color={theme.palette.error.main}
                            className={classes.confirmError}
                          />
                        )}
                      </Grid>
                      {
                        !isNew && (
                          <Grid item md={4} xs={12} className={clsx(classes.statusContainer, classes.editingStatus)}>
                            <Status
                              titleFontSize='0.75rem'
                              subtitleFontSize='1rem'
                              user={user}
                              onReactivateUser={onReactivateUser}
                              reactivating={reactivating}
                            />
                          </Grid>
                        )
                      }
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item md={4} xs={12} className={classes.switch}>
                        <FormLabel
                          className={clsx(classes.label, classes.switchLabel)}
                        >
                          Client & Accounts
                        </FormLabel>
                        <Controller
                          name='allAccountsAccess'
                          render={({ field: { onChange } }) => (
                            <FormControlLabel
                              ref={allAccountsRef}
                              control={
                                <Switch
                                  onChange={(e) => {
                                    onChange(e.target.checked)
                                  }}
                                  checked={getValues('allAccountsAccess')}
                                />
                              }
                              label='All Access'
                              labelPlacement='start'
                            />)}
                          control={control}
                        />
                      </Grid>
                      {
                        !!currentUser?.internal && (
                          <Grid
                            item
                            md={4}
                            xs={12}
                            className={clsx(classes.switch, classes.internalSwitch)}
                          >
                            <Controller
                              name='internal'
                              render={({ field: { onChange } }) => (
                                <FormControlLabel
                                  ref={internalRef}
                                  control={
                                    <Switch
                                      onChange={(e) => {
                                        onChange(e.target.checked)
                                      }}
                                      checked={getValues('internal')}
                                    />
                                  }
                                  label='Internal User'
                                  labelPlacement='start'
                                />)}
                              control={control}
                            />
                          </Grid>
                        )
                      }
                    </Grid>
                    {isNew && (
                      <Box>
                        <FormLabel htmlFor={ADVISOR_FORM_NAMES.roleId} required className={classes.inputLabel}>
                          Permissions
                        </FormLabel>
                        <PermissionSelect
                          accessor={ADVISOR_FORM_NAMES.roleId}
                          editMode
                          value={watch(ADVISOR_FORM_NAMES.roleId)}
                          inputProps={{
                            disableUnderline: true,
                            ...register(ADVISOR_FORM_NAMES.roleId, { required: 'Please select a role' })
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                ) : (
                  <>
                    <Text
                      text={fullName}
                      color={theme.palette.summitBlue}
                      customFontSize='2.125rem'
                      customFontFamily='GothamPro-Bold'
                    />
                    <Text
                      text={user?.email}
                      color={theme.palette.darkJungle}
                      customFontSize='0.875rem'
                    />
                  </>
                )
              }
            </Grid>
          </Grid>
          {
            !editingUser && (
              <Grid container className={classes.gralInfo} spacing={4}>
                <Grid item>
                  <TitleSubtitle title='User Type' subtitle={advisor} />
                </Grid>
                <Grid item>
                  <TitleSubtitle title='Clients & Accounts' subtitle={getValues('allAccountsAccess') ? 'All Access' : 'Limited Access'} />
                </Grid>
                <Grid item className={classes.statusContainer}>
                  <Status
                    user={user}
                    onReactivateUser={onReactivateUser}
                    reactivating={reactivating}
                  />
                </Grid>
              </Grid>
            )
          }
        </Grid>
        {
          editingUser && (
            <Grid container spacing={4} justifyContent='center' className={classes.bottomContainer}>
              <Grid item md={3} lg={2} xs={12}>
                <RoundedButton
                  fullWidth
                  secondary
                  onClick={handleCancel}
                  size={BUTTON_SIZES.medium}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  className={classes.cancel}
                >
                  Cancel
                </RoundedButton>
              </Grid>
              {
                isNew ? (
                  <>
                    <Grid item md={3} lg={2} xs={12}>
                      <RoundedButton
                        id={USERS_FORM_ACTIONS.ADD}
                        fullWidth
                        tertiary
                        type='submit'
                        size={BUTTON_SIZES.medium}
                        isLoading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                      >
                        Add Only
                      </RoundedButton>
                    </Grid>
                    <Grid item md={3} lg={2} xs={12}>
                      <RoundedButton
                        id={USERS_FORM_ACTIONS.ADD_INVITE}
                        primary
                        fullWidth
                        type='submit'
                        size={BUTTON_SIZES.medium}
                        isLoading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                      >
                        Add and Invite
                      </RoundedButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item md={3} lg={2} xs={12}>
                    <RoundedButton
                      primary
                      fullWidth
                      type='submit'
                      size={BUTTON_SIZES.medium}
                      isLoading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                    >
                      Save
                    </RoundedButton>
                  </Grid>
                )
              }
            </Grid>
          )
        }
      </form>
      <Dialog size='xs' open={openCreateUser} onClose={closeDialog}>
        <Dialog.Header>
          <Text
            text={userAction.action === USERS_FORM_ACTIONS.ADD ? 'Add User' : 'Add and Invite User'}
            color={theme.palette.summitBlue}
            customFontSize='1.5rem'
            customFontWeight='900'
          />
        </Dialog.Header>
        <Dialog.Body className={classes.dialogBody}>
          <Text color={theme.palette.summitBlue} customFontSize='0.875rem'>
            {
              userAction.action === USERS_FORM_ACTIONS.ADD
                ? `Are you sure you want to add ${getValues('email')} as a user?`
                : `Are you sure you want to add and send an invite to ${getValues('email')}?`
            }
          </Text>
        </Dialog.Body>
        <Dialog.Actions>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item lg={5} xs={12}>
              <RoundedButton
                onClick={closeDialog}
                secondary
                fullWidth
                size={BUTTON_SIZES.medium}
              >
                Cancel
              </RoundedButton>
            </Grid>
            <Grid item lg={7} xs={12}>
              <RoundedButton
                primary
                fullWidth
                size={BUTTON_SIZES.medium}
                onClick={userAction.createAdvisor}
              >
                {
                  userAction.action === USERS_FORM_ACTIONS.ADD ? 'Yes, add user' : 'Yes, add & invite'
                }
              </RoundedButton>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
      <SnackAlert alert={alert} />
    </>
  )
}

UserForm.propTypes = {
  editingUser: PropTypes.bool,
  setIsLoadingUser: PropTypes.func,
  disableEdit: PropTypes.bool,
  setDisableEdit: PropTypes.any,
  setEditingUser: PropTypes.any
}

Status.propTypes = {
  titleFontSize: PropTypes.string,
  subtitleFontSize: PropTypes.string,
  status: PropTypes.object,
  user: PropTypes.object,
  onReactivateUser: PropTypes.func,
  reactivating: PropTypes.bool
}

Status.defaultProps = {
  subtitleFontSize: '1.375rem'
}

export default UserForm
