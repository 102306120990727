import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import keyMirror from 'keymirror'

dayjs.extend(utc)

export const USERS_PATH = '/admin/users'

export const userTypeName = (userTypeId, internal) => {
  let userType
  switch (userTypeId) {
    case 1:
      userType = 'Wealth Owner'
      break
    case 2:
      userType = 'Advisor'
      break
    default:
      userType = 'Unknown'
      break
  }

  return internal ? `${userType} (SWS)` : userType
}

const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  drilldownLink: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${USERS_PATH}/${row.original.userId}`}
    >
      {accessor(row.original)}
    </Link>
  ),
  userType: ({ row }) => userTypeName(row.original.userTypeId, row.original.internal)
}

const emailAccesor = (row) => row.email

export const defaultColumnConfig = {
  columns: [
    { Header: 'First Name', accessor: 'firstName', id: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName', id: 'lastName' },
    { Header: 'Email', accessor: emailAccesor, id: 'email', Cell: cellTemplates.drilldownLink(emailAccesor) },
    { Header: 'User Type', accessor: 'userTypeId', id: 'userTypeId', Cell: cellTemplates.userType }
  ],
  defaultSort: [
    { id: 'firstName', desc: false }
  ]
}

export const ADVISOR_FORM_NAMES = keyMirror({
  roleId: null,
  title: null,
  email: null,
  phone1: null,
  phone2: null,
  roleTypeId: null
})

export const CONTACT_INFO_FIELDS = [
  {
    key: 'title',
    label: 'Title',
    value: 'title'
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email'
  },
  {
    key: 'phone1',
    label: 'Phone 1',
    value: 'phone1'
  },
  {
    key: 'phone2',
    label: 'Phone 2',
    value: 'phone2'
  },
  {
    key: 'roleTypeId',
    label: 'Role',
    value: 'roleTypeId'
  }
]
