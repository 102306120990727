import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Box, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useFilterSubscription } from '../useFilterSubscription'
import { useFilterSubscriptionContext } from '../FilterSubscriptionProvider'
import Skeleton from '../../../components/atoms/Skeleton'
import GroupTagFilters from '../../../components/molecules/GroupTagFilters'
import { BUTTON_SIZES } from '../../../constants'
import SelectWithCheckbox from '../../../components/molecules/Select/SelectWithCheckbox'
import Text from '../../../components/atoms/Text'
import GroupTypeDetailsModalToggle from '../GroupTypeDetailsModal/GroupTypeDetailsModalToggle'
import { useGroupPinnedOptions, useGroupTypes, useVisibleFilters } from './helperHooks'
import { getGroupTypeKey } from './helpers'

const useStyles = makeStyles(() => ({
  addFilter: {
    background: '#F3F4F5 !important',
    color: '#141929 !important'
  }
}))

const GroupTypeSubscriptionFilter = ({
  filterSubscription,
  editablePinnedOptions,
  showAddFilterButton,
  defaultFilter,
  shouldFilterByAccounts
}) => {
  const classes = useStyles()

  const {
    filters,
    configStorageKey,
    initialFilterState
  } = useFilterSubscriptionContext()

  const {
    pinnedOptions,
    pinnedGroupIds,
    isLoadingPinnedOptions
  } = useGroupPinnedOptions(initialFilterState, editablePinnedOptions)

  const accountIds = !isEmpty(filters?.levelFilters?.accountIds)
    ? filters?.levelFilters?.accountIds
    : filters?.accountIds

  const {
    groupTypes,
    isLoadingGroupTypes,
    groupTypesGroupedByKey
  } = useGroupTypes(
    defaultFilter,
    pinnedGroupIds,
    accountIds,
    shouldFilterByAccounts
  )

  const { publishFilters } = useFilterSubscription({
    publishKeys: filterSubscription.publish
  })

  const [selectedGroupTypes, setSelectedGroupTypes] = useState([])

  // set default selected groups
  useEffect(() => {
    if (!isEmpty(filters?.levelFilters) && !isEmpty(groupTypesGroupedByKey)) {
      const defaultGroupTypes = Object.entries(filters.levelFilters).reduce(
        (acc, [groupTypeKey, groupIds]) => {
          const { groupTypeId, groupName } = groupTypesGroupedByKey?.[groupTypeKey] || {}
          if (!groupTypeId) return acc
          return [
            ...acc,
            {
              groupIds,
              groupName,
              groupTypeId
            }
          ]
        },
        []
      )
      setSelectedGroupTypes(defaultGroupTypes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilterState, groupTypesGroupedByKey, pinnedOptions])

  const onChangeSelectedGroupTypes = useCallback((values) => {
    setSelectedGroupTypes(prevState => {
      const state = values(prevState)

      const groupTypeFilters = state.reduce((acc, groupType) => {
        const groupTypeKey = getGroupTypeKey(groupType.groupName)
        return {
          ...acc,
          [groupTypeKey]: groupType.groupIds
        }
      }, {})

      publishFilters((prevState) => {
        const levelFilters = prevState?.levelFilters ?? {}
        const accountCategoryIds = levelFilters?.accountCategoryIds ?? []
        const accountIds = levelFilters?.accountIds ?? []
        return {
          ...prevState,
          levelFilters: {
            ...groupTypeFilters,
            accountIds,
            accountCategoryIds
          }
        }
      })
      return state
    })
  }, [publishFilters])

  const { visibleFilters, setVisibleFilters } = useVisibleFilters(configStorageKey)

  const onChangeAddFilter = useCallback((groupTypes) => {
    setVisibleFilters(groupTypes.map(({ label, value }) => ({ label, value })))
  }, [setVisibleFilters])

  const visibleGroupTypes = useMemo(() => {
    return (groupTypes || []).map((groupType) => {
      const groupTypePinnedOptions = pinnedOptions?.[groupType.value] || []
      const visibleFilterIds = visibleFilters.map(({ value }) => value)
      return {
        ...groupType,
        pinnedOptions: groupTypePinnedOptions,
        ...(showAddFilterButton
          ? { hidden: !visibleFilterIds.includes(groupType.value) }
          : {})
      }
    })
  }, [groupTypes, visibleFilters, pinnedOptions, showAddFilterButton])

  const addFilterLabelRender = useCallback(() => {
    return <Text customFontSize='0.8125rem'>Add Filter</Text>
  }, [])

  const groupTypeId = useMemo(() => {
    if (Array.isArray(defaultFilter?.groupTypeId) && defaultFilter?.groupTypeId.length) {
      return defaultFilter?.groupTypeId?.[0]?.value?.[0] ?? null
    }
    return defaultFilter?.groupTypeId ?? null
  }, [defaultFilter?.groupTypeId])
  const showInfoButton = useMemo(() => !!groupTypeId, [groupTypeId])
  const infoTitle = useMemo(() => {
    if (!showInfoButton) return ''
    if (!visibleGroupTypes?.length) {
      return 'Details'
    }
    return `${visibleGroupTypes[0]?.label ?? ''} Insights`
  }, [showInfoButton, visibleGroupTypes])
  if (isLoadingGroupTypes || isLoadingPinnedOptions) {
    return <Skeleton height='2rem' width='8rem' />
  }

  if (!visibleGroupTypes.some((groupType) => groupType.groups.length > 0)) {
    return null
  }

  return (
    <Box display='flex' alignItems='center' gridGap='.25rem'>
      <GroupTagFilters
        disableSlider
        groupTypes={visibleGroupTypes}
        isLoading={isLoadingGroupTypes}
        tagFilters={selectedGroupTypes}
        setTagFilters={onChangeSelectedGroupTypes}
      />
      {showAddFilterButton && (
        <SelectWithCheckbox
          className={classes.addFilter}
          labelRenderer={addFilterLabelRender}
          options={groupTypes}
          selectedOptions={visibleFilters}
          onChange={onChangeAddFilter}
          size={BUTTON_SIZES.small}
          caretIconOpen={AddIcon}
          caretIconClose={AddIcon}
        />
      )}
      {showInfoButton && (
        <GroupTypeDetailsModalToggle
          groupTypeId={groupTypeId}
          title={infoTitle}
        />
      )}
    </Box>
  )
}

GroupTypeSubscriptionFilter.propTypes = {
  filterSubscription: PropTypes.shape({
    publish: PropTypes.arrayOf(PropTypes.string)
  }),
  editablePinnedOptions: PropTypes.bool,
  showAddFilterButton: PropTypes.bool,
  defaultFilter: PropTypes.object,
  // if true means the accounts filter is present and everything in this filter
  // should be filtered considering those accounts
  shouldFilterByAccounts: PropTypes.bool
}

GroupTypeSubscriptionFilter.defaultProps = {
  filterSubscription: {
    publish: ['levelFilters']
  },
  editablePinnedOptions: false,
  showAddFilterButton: false,
  defaultFilter: undefined,
  shouldFilterByAccounts: true
}

export default GroupTypeSubscriptionFilter
