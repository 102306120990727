import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ClickAwayListener, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import dayjs from 'dayjs'
import { useToggle } from '../../hooks'
import { useWealthJourneyPermissions } from '../organisms/WealthJourney/v2/hooks'
import entryType, { ACTIONS, entryTypeIdentifier } from '../organisms/WealthJourney/v2/entryType'
import { clearSuppression, setSuppression } from '../../utils/globalSuppression'
import WealthJourneyCategoryHeader from './WealthJourneyCategoryHeader'
import WealthJourneyCategoryBody from './WealthJourneyCategoryBody'

const useStyles = makeStyles((theme) => ({
  container: ({ activitiesEmpty }) => ({
    height: '24rem',
    width: '100%',
    border: '2px solid #21294533',
    borderRadius: '0.75rem',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    ...(activitiesEmpty ? {} : { cursor: 'pointer' })
  })
}))

const filterActivities = (categoryId, entryDateYear) => (entry) => {
  return (
    (entry.entryTypeId === null || entry.entryTypeId === entryTypeIdentifier.ACTIVITIES) &&
    entry.categoryId === categoryId &&
    dayjs.utc(entry.entryDate).year() === entryDateYear
  )
}

const WealthJourneyCategoryV2 = ({
  title,
  categoryId,
  entryDateYear,
  isLoading,
  entries,
  onAdd,
  onEdit,
  onDelete
}) => {
  const [hovered, , toggleHoveredOn, toggleHoveredOff] = useToggle()
  const [editMode, toggleEditMode, toggleEditModeOn, toggleEditModeOff] = useToggle()
  useEffect(() => {
    if (editMode) {
      setSuppression('wheel', true)
      return () => {
        clearSuppression('wheel')
      }
    }
  }, [editMode])

  const canEditActivities = useWealthJourneyPermissions(entryType.ACTIVITIES, ACTIONS.EDIT)

  const activities = useMemo(() => {
    return entries.filter(filterActivities(categoryId, entryDateYear))
  }, [entries, categoryId, entryDateYear])
  const activitiesEmpty = useMemo(() => !isLoading && isEmpty(activities), [activities, isLoading])

  const classes = useStyles({ activitiesEmpty })

  const onAddActivity = useCallback((entryText, ...rest) => {
    const newActivity = {
      entryText,
      categoryId,
      entryDate: dayjs().year(entryDateYear).toISOString(),
      entryTypeId: entryTypeIdentifier.ACTIVITIES
    }
    onAdd(newActivity, ...rest)
  }, [onAdd, categoryId, entryDateYear])

  return (
    <ClickAwayListener onClickAway={toggleEditModeOff}>
      <div
        className={classes.container}
        onClick={activitiesEmpty ? noop : toggleEditModeOn}
        onMouseEnter={toggleHoveredOn}
        onMouseLeave={toggleHoveredOff}
      >
        <WealthJourneyCategoryHeader
          classes={classes}
          activitiesEmpty={activitiesEmpty}
          isLoading={isLoading}
          hovered={hovered}
          editMode={editMode}
          onEdit={toggleEditMode}
          canEditActivities={canEditActivities}
        >
          {title}
        </WealthJourneyCategoryHeader>
        <WealthJourneyCategoryBody
          classes={classes}
          isLoading={isLoading}
          activities={activities}
          activitiesEmpty={activitiesEmpty}
          editMode={editMode}
          toggleEditModeOn={toggleEditModeOn}
          onAddActivity={onAddActivity}
          onEditActivity={onEdit}
          onDeleteActivity={onDelete}
          canEditActivities={canEditActivities}
        />
      </div>
    </ClickAwayListener>
  )
}

WealthJourneyCategoryV2.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoading: PropTypes.bool,
  entryDateYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  entries: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

WealthJourneyCategoryV2.defaultProps = {
  categoryId: undefined,
  isLoading: false,
  entries: []
}

export default WealthJourneyCategoryV2
