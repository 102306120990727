import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import GroupingProvider from '../../../../organisms/GroupingProvider'
import OperationalTable from '../../../../organisms/OperationalTable'
import { getDynamicColumnIds, getFilterColumnId } from '../../Clients/ClientListTab/helpers'
import { useAccountsContext } from './AccountsContext'
import { COLUMN_FILTER_MAPPING } from './helpers'

const AccountsTableWrapper = ({ children }) => {
  const { configurationKey, onChangeFilters, setVisibleFilters } =
    useAccountsContext()

  const onSaveTableConfiguration = useCallback(({ columns, hiddenColumns }) => {
    // show or hide filters based on column visibility
    const filterColPrefix = getFilterColumnId()
    const visibleColFilters = columns.filter((col) =>
      col.startsWith(filterColPrefix)
    )
    setVisibleFilters(visibleColFilters)

    // if a group type filter column is hidden, we remove its filter too
    onChangeFilters(({ tagFilters, ...prevFilters }) => {
      const filterColIds = getDynamicColumnIds(hiddenColumns)
      const filters = Object.entries(COLUMN_FILTER_MAPPING).reduce(
        (acc, [colKey, filterKey]) => {
          return {
            ...acc,
            [filterKey]: !hiddenColumns.includes(colKey)
              ? prevFilters[filterKey]
              : []
          }
        }, {})
      return {
        ...filters,
        tagFilters: tagFilters.filter(({ groupTypeId }) => {
          return !filterColIds.includes(groupTypeId.toString())
        })
      }
    })
  }, [onChangeFilters, setVisibleFilters])

  return (
    <GroupingProvider
      configurationKey={configurationKey}
      onApplyClick={onSaveTableConfiguration}
      options={{
        hideAddAllColumnsButton: true
      }}
    >
      <OperationalTable.Wrapper>{children}</OperationalTable.Wrapper>
    </GroupingProvider>
  )
}

AccountsTableWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default AccountsTableWrapper
