import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = () => {
  const theme = useTheme()
  const styles = makeStyles(() => ({
    pillButton: {
      background: theme.palette.seaFoam,
      fontWeight: 700,
      textTransform: 'none',
      borderColor: theme.palette.baseBackground.main,
      '&:hover': {
        background: theme.palette.cloudBurst,
        color: theme.palette.white
      }
    },
    isFirstInGroup: {
      borderBottomLeftRadius: '2rem',
      borderTopLeftRadius: '2rem',
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    },
    isInMiddle: {
      borderRadius: 0,
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    },
    isLastInGroup: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: '2rem',
      borderTopRightRadius: '2rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    },
    selected: {
      background: theme.palette.cloudBurst,
      color: theme.palette.white
    }
  }))()

  return [styles, theme]
}

const PillButton = ({
  children,
  isInGroup,
  isFirstInGroup,
  isLastInGroup,
  selected,
  ...rest
}) => {
  const [classes] = useStyles()
  const cn = useMemo(() => clsx(classes.pillButton, {
    [classes.selected]: selected,
    [classes.isFirstInGroup]: isFirstInGroup,
    [classes.isInMiddle]: isInGroup && !isFirstInGroup && !isLastInGroup,
    [classes.isLastInGroup]: isLastInGroup
  }), [selected, classes, isFirstInGroup, isLastInGroup, isInGroup])

  return (
    <Button className={cn} {...rest}>{children}</Button>
  )
}

PillButton.propTypes = {
  children: PropTypes.node.isRequired,

  /** Is the PillButton in a group? */
  isInGroup: PropTypes.bool,

  /** Is the PillButton the first in the group? */
  isFirstInGroup: PropTypes.bool,

  /** Is the PillButton the last in the group? */
  isLastInGroup: PropTypes.bool,

  selected: PropTypes.bool,

  ...Button.propTypes
}

PillButton.defaultProps = {
  isInGroup: false,
  isFirstInGroup: false,
  isLastInGroup: false,
  selected: false
}

export default PillButton
