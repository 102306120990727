import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { useClassificationTags } from '../../../../../../api/coreData'
import { modelItem, tagItem, useDialogStyles } from './common'

function EditTagForm ({ onComplete, classificationTagTypeId, onCancel, item }) {
  const classes = useDialogStyles()

  const [searchText, setSearchText] = useState()
  const query = useMemo(() => {
    return {
      textSearch: {
        longName: [{ op: 'contains', value: searchText }]
      },
      filters: {
        classificationTagTypeId: classificationTagTypeId
      },
      take: 20
    }
  }, [searchText, classificationTagTypeId])
  const { data, isFetching } = useClassificationTags(query)
  const weightRef = useRef()

  return (
    <>
      <div className={classes.form}>
        <div className={classes.info}>
          <div>Current Classification Tag</div>
          <div><span>{item.name}</span></div>
        </div>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput
              onChange={setSearchText} placeholder='Search Name'
              style={{ height: 'unset', minHeight: '54px' }}
            />
          </SydLabel>
          <SydLabel label='Weight'>
            <SydInput ref={weightRef} type='number' minWidth='0' defaultValue={item.weight.toString()} />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : data?.length ? (
            <div className={classes.list}>
              <table className={classes.formTable}>
                <thead>
                  <tr>
                    <th>Identifier</th>
                    <th>Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(x => (
                    <tr key={x.classificationTagId}>
                      <td>{x.classificationTagTypeLongName}</td>
                      <td>{x.longName}</td>
                      <td>
                        <SydButton
                          size='xs' variant='primary' onClick={() => {
                            onComplete(tagItem(x, weightRef.current.value))
                          }}
                        >Add
                        </SydButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={() => {
            onComplete(modelItem(item, weightRef.current.value))
          }}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditTagForm.propTypes = {
  onComplete: PropTypes.func,
  classificationTagTypeId: PropTypes.number,
  onCancel: PropTypes.func,
  item: PropTypes.object
}

export default EditTagForm
