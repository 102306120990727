import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import noop from 'lodash/noop'
import { useSetCurrentClient } from '../../../../../redux/slices/appContext'
import useClientBookmarks from '../../hooks/useClientBookmarks'
import ClientCardItem from './ClientCardItem'

const ClientCard = ({ onClick, loading, ...client }) => {
  const history = useHistory()
  const setCurrentClient = useSetCurrentClient()

  const { clientBookmarked, persistClientBookmark } = useClientBookmarks({
    clientId: client.clientId
  })

  const onClientCardClick = useCallback(
    ({ clientId }) =>
      (event) => {
        const tagName = event.target?.tagName
        if (event.target?.className?.includes?.('Rating')) return
        if (
          tagName.includes('svg') ||
          tagName.includes('path') ||
          tagName.includes('button')
        ) { return }
        onClick()
        setCurrentClient(clientId)
        history.push('/')
      },
    [history, onClick, setCurrentClient]
  )

  return (
    <ClientCardItem
      client={client}
      loading={loading}
      onClick={onClientCardClick({ clientId: client.clientId })}
      clientBookmarked={clientBookmarked}
      onBookmarkChange={(event, value) => {
        event.stopPropagation()
        persistClientBookmark(value)
      }}
    />
  )
}

ClientCard.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

ClientCard.defaultProps = {
  onClick: noop
}

export default memo(ClientCard)
