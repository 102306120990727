import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useListAssignedClientAccounts, useSearchAccountsMultiple } from '../../../../../api/accounts'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import FastAvatar from '../../../../molecules/FastAvatar'
import Icon from '../../../../atoms/Icon'
import DebouncedInput from '../../../../molecules/DebouncedInput'
import { useClientAccountsActions, ClientAccountsTableContext } from './ClientAccountsTableContext'
import AccountNameCell from './AccountNameCell'

const CustodianCell = ({ row: { original: item } }) => {
  const custodian = item.custodian?.length ? item.custodian[0] : null
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
      <FastAvatar avatarUrl={custodian?.imageUrl} size='xs' />
      {custodian?.name ?? '--'}
    </div>
  )
}

CustodianCell.propTypes = {
  row: PropTypes.object
}

const EditActionCell = ({ value }) => {
  const { added, removed, onAdd, onRemove, assignedAccounts } = useClientAccountsActions()
  const action = useMemo(() => {
    const assigned = assignedAccounts.some(x => x.accountId === value)
    if (assigned && removed.includes(value)) {
      return (
        <Button
          className='__action-button __removed'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onRemove(value)}
        >
          Removed
        </Button>
      )
    }
    if (assigned) {
      return (
        <Button
          className='__action-button'
          variant='outlined'
          startIcon={<Icon name='removeCircle' />}
          onClick={() => onRemove(value)}
        >
          Remove
        </Button>
      )
    }
    if (added.includes(value)) {
      return (
        <Button
          className='__action-button __added'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onAdd(value)}
        >
          Added
        </Button>
      )
    }
    return (
      <Button
        className='__action-button'
        variant='outlined'
        startIcon={<Icon name='addCircle' />}
        onClick={() => onAdd(value)}
      >
        Add
      </Button>
    )
  }, [added, removed, onAdd, onRemove, assignedAccounts, value])
  return (
    <div>
      {action}
    </div>
  )
}

EditActionCell.propTypes = {
  value: PropTypes.any
}

const defaultColumnConfig = {
  columns: [
    {
      id: 'accountName',
      accessor: 'accountName',
      Header: 'Account Name',
      Cell: AccountNameCell
    },
    {
      id: 'accountNumber',
      accessor: 'accountNumber',
      Header: 'Account Number',
      width: 200,
      Cell: AccountNameCell
    },
    {
      id: 'ordinal',
      accessor: 'ordinal',
      Header: 'Ordinal'
    },
    {
      id: 'custodian',
      accessor: 'custodian[0].name',
      Header: 'Custodian',
      Cell: CustodianCell,
      width: 200
    },
    {
      id: 'asOfDate',
      accessor: 'asOfDate',
      Header: 'As of Date',
      format: '@MM/DD/YYYY',
      width: 200
    },
    {
      id: 'closeDate',
      accessor: 'closeDate',
      Header: 'Close Date',
      format: '@MM/DD/YYYY',
      width: 200
    }
  ],
  defaultSort: [{ id: 'ordinal', desc: false }]
}

const editingColumns = [{
  id: 'actions',
  accessor: 'accountId',
  Header: 'Actions',
  width: 200,
  Cell: EditActionCell
}]

export const useColumns = ({ editing }) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const { columns, defaultSort } = defaultColumnConfig
    const allColumns = editing ? [...columns, ...editingColumns] : columns
    const templatedColumns = allColumns.map((column) => {
      if (column?.Cell) return column

      return {
        ...column,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [formatter, editing])
}

const useClientAccounts = (client, editing) => {
  const [search, setSearch] = useState('')
  const { data: assigned, isLoading: assignedLoading } = useListAssignedClientAccounts(client.clientId)

  const query = useMemo(() => {
    const searchValue = search || undefined
    const paramsFilter = (searchValue && editing) ? undefined : {
      assignedToClientIds: [client.clientId]
    }
    return {
      assignedAccounts: {
        includes: {
          custodian: true,
          ordinal: {
            groupLevelTypeId: 201,
            groupId: client.clientId
          }
        },
        filters: paramsFilter,
        textSearch: {
          accountName: searchValue ? [{ op: 'contains', value: searchValue }] : undefined,
          accountNumber: searchValue ? [{ op: 'contains', value: searchValue }] : undefined
        }
      },
      totalAssignedAccounts: {
        resultType: 'total',
        filters: paramsFilter
      }
    }
  }, [client.clientId, search, editing])

  const { data: searchResults, isLoading: searchResultsLoading, error } = useSearchAccountsMultiple(query)

  const { accounts, total } = useMemo(() => {
    if (searchResultsLoading) {
      return {
        accounts: [],
        total: null
      }
    }
    return {
      accounts: searchResults.assignedAccounts.data,
      total: searchResults.totalAssignedAccounts.total
    }
  }, [searchResults, searchResultsLoading])

  const [added, setAdded] = useState([])
  const [removed, setRemoved] = useState([])
  const onAdd = useCallback((accountId) => {
    setAdded(p => p.includes(accountId) ? p.filter(x => x !== accountId) : [...p, accountId])
  }, [setAdded])
  const onRemove = useCallback((accountId) => {
    setRemoved(p => p.includes(accountId) ? p.filter(x => x !== accountId) : [...p, accountId])
  }, [setRemoved])

  return {
    accounts,
    total,
    isLoading: searchResultsLoading || assignedLoading,
    error,
    setSearch,
    added,
    removed,
    onAdd,
    onRemove,
    assignedAccounts: assigned?.clientAccounts
  }
}

const useStyles = makeStyles((theme) => ({
  clientAccountsTable: {
    marginTop: '10px',

    '& .__action-button': {
      textTransform: 'none',
      borderRadius: '1rem'
    },
    '& .__removed': {
      color: theme.palette.danger.main
    },
    '& .__added': {
      color: theme.palette.success.main
    },
    '& .__acct-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '36px'
    }
  },
  search: {
    maxWidth: '300px'
  }
}))

const ClientAccountsTable = forwardRef(function ({ client, editing }, ref) {
  const {
    accounts, // the search results
    total, // the total number of accounts assigned
    isLoading, // is any query loading?
    setSearch, // search account text
    added,
    removed,
    onAdd,
    onRemove,
    assignedAccounts // the assigned account list
  } = useClientAccounts(client, editing)
  const columnConfig = useColumns({ editing })
  const searchRef = useRef()
  const onChange = useCallback((newValue) => {
    setSearch(newValue)
  }, [setSearch])
  const classes = useStyles()
  const providerValue = useMemo(() => {
    return { added, removed, onAdd, onRemove, assignedAccounts, editing }
  }, [added, removed, onAdd, onRemove, assignedAccounts, editing])

  useImperativeHandle(ref, () => {
    return {
      added,
      removed
    }
  }, [added, removed])

  return (
    <ClientAccountsTableContext.Provider value={providerValue}>
      <OperationalTable.Wrapper className={classes.clientAccountsTable}>
        <DebouncedInput
          className={classes.search}
          ref={searchRef}
          onChange={onChange}
          placeholder={editing ? 'Search for Accounts to Add' : 'Search Assigned Accounts'}
        />
        <OperationalTable
          variant='v2'
          loading={isLoading}
          mode='client'
          columns={columnConfig.columns || []}
          defaultSort={columnConfig.defaultSort || []}
          data={accounts}
          total={total}
          itemName='Accounts'
          hideFooter
        />
      </OperationalTable.Wrapper>
    </ClientAccountsTableContext.Provider>
  )
})

ClientAccountsTable.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  editing: PropTypes.bool
}

ClientAccountsTable.defaultProps = {
  editing: false
}

export default ClientAccountsTable
