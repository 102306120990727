import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ClientInfoSection from './ClientInfoSection'
import ClientTeamSection from './ClientTeamSection'

function GeneralTab () {
  return (
    <FadeIn>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ClientInfoSection />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClientTeamSection />
        </Grid>
      </Grid>
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
