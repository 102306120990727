import { makeStyles } from '@material-ui/core'

export const useTableStyles = makeStyles((theme) => ({
  '@keyframes tableCellOpen': {
    '0%': {
      transform: 'scale(1, 0)',
      transformOrigin: 'top',
      maxHeight: 0
    },
    '100%': {
      transform: 'scale(1, 1)',
      transformOrigin: 'top',
      maxHeight: '2rem'
    }
  },
  '@keyframes fadey': {
    '0%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 1
    }
  },
  presentationTable: ({ depthStyles }) => ({
    padding: '0',
    display: 'block',
    '& .__table': {
      borderSpacing: 0,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) => superHeaderRect ? superHeaderRect.height : 0,
        boxShadow: '0px 10px 6px -6px rgba(24, 27, 53, 0.06)',
        overflowY: 'auto',
        overflowX: 'hidden'
      },
      '& .__headerGroup': {
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '0.5rem 0.75rem',
        userSelect: 'none',
        backgroundColor: theme.palette.gray.light,
        borderBottom: '2px solid #212945',
        lineHeight: '30px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '& .__tf': {
        fontWeight: theme.typography.weights.bold,
        padding: '0 0.75rem',
        userSelect: 'none',
        backgroundColor: theme.palette.background.default,
        borderTop: `2px solid ${theme.palette.gray.main}`,
        lineHeight: '30px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        },
        '& .__td': {
          textAlign: 'left',
          padding: '8px 12px',
          fontWeight: '400',
          transition: 'background-color .2s ease-in-out 0s',
          '&.__right': {
            textAlign: 'right'
          },
          '&.__center': {
            textAlign: 'center'
          }
        }
      },

      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {
      },
      '& .__tr:hover .__tr-wrapper .__td': {
        backgroundColor: 'rgba(0, 0, 30, .1)',
        transition: 'background-color 3s ease-in-out .5s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        backgroundColor: theme.palette.gray.light,
        '& .__td': depthStyles?.[1] ?? {},
        '& .__td > *:first-child': {
          marginLeft: '18px'
        }
      },
      '& .__tr.__depth_2': {
        backgroundColor: theme.palette.gray.main,
        '& .__td': depthStyles?.[2] ?? {},
        '& .__td > *:first-child': {
          marginLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        backgroundColor: theme.palette.gray.dark,
        '& .__td': depthStyles?.[3] ?? {},
        '& .__td > *:first-child': {
          marginLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        backgroundColor: theme.palette.gray.darker,
        '& .__td': depthStyles?.[4] ?? {},
        '& .__td > *:first-child': {
          marginLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '8px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 4px 0 0'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      }
    },
    '& .__sticky': {
      overflow: 'auto',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0,
        boxShadow: '0px 3px 3px #ccc'
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,.1)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.gray.light
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.main
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.dark
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.darker
      }
    },
    '&.bordered': {
      '& .__thead': {
        border: `.5px solid ${theme.palette.gray.dark}`
      },
      '& .__headerGroup .__th': {
        border: `.5px solid ${theme.palette.gray.dark}`
      },
      '& .__headerGroup:not(:first-child) .__th': {
        backgroundColor: theme.palette.gray.main
      }
    }
  }),
  sparkRoot: {
    opacity: ({ loading }) => loading ? 1 : 0,
    transition: ({ loading }) => loading ? 'opacity .25s ease-in-out .25s' : 'opacity .1s ease-in-out',
    height: '2px'
  }
}))
