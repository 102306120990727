import { Card, CardContent, Paper, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import { useAbundanceEngineParameters } from '../../../../hooks/useAbundanceEngineParameters'
import { replacePlaceholders } from '../../../../utils/replacers'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: theme.palette.gallery,
      transition: 'background-color 0 ease-in-out'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    transition: 'background-color .25s ease-in-out'
  },
  card: {
    textDecoration: 'none'
  },
  cardContent: {
    padding: '2.5rem',
    '&:last-child': {
      paddingBottom: '2.5rem'
    }
  },
  largeIcon: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  icon: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  largeTitle: {
    marginBottom: theme.spacing(3),
    fontSize: '1.125rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  cardTitle: {
    marginBottom: '1rem'
  },
  cardDisabled: {
    opacity: '0.4',
    cursor: 'unset !important',
    backgroundColor: theme.palette.gallery,
    '&:hover': {
      backgroundColor: theme.palette.gallery
    },
    '& > a': {
      cursor: 'unset !important'
    }
  }
}))

const CardTile = ({ to, disabled, icon, title, children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const abundanceParams = useAbundanceEngineParameters()
  const replacedTo = useMemo(() => replacePlaceholders(to, abundanceParams), [to, abundanceParams])

  const largeIconProps = useMemo(
    () => ({
      additionalClasses: clsx(classes.largeIcon, classes.icon),
      roundedBackground: '#EEE',
      customSize: mdDown ? (smDown ? '25px' : '35px') : '45px',
      customHeight: '45px'
    }),
    [mdDown, smDown, classes.icon, classes.largeIcon]
  )

  const largeTitleProps = useMemo(
    () => ({
      variant: mdDown ? TEXT_VARIANTS.h2 : TEXT_VARIANTS.h1,
      className: clsx(classes.cardTitle, classes.largeTitle)
    }),
    [mdDown, classes.cardTitle, classes.largeTitle]
  )

  return (
    <Paper
      elevation={0}
      className={clsx(classes.paper, { [classes.cardDisabled]: disabled })}
    >
      <Card elevation={0} className={classes.card} component={Link} to={replacedTo}>
        <CardContent className={classes.cardContent}>
          {children || (
            <>
              <Icon name={icon} {...largeIconProps} />
              <Typography {...largeTitleProps}>{title}</Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Paper>
  )
}

CardTile.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
}

CardTile.defaultProps = {
  to: '#',
  disabled: false,
  icon: ICON_NAMES.document,
  title: '',
  children: null
}

export default CardTile
