import * as PropType from 'prop-types'
import { MenuItem, TextField, useTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { noop } from 'lodash'
import { ADMIN_ROUTES, TEXT_VARIANTS, THEME_COLORS } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import Skeleton from '../../../../atoms/Skeleton'
import Tag from '../../../../atoms/Tag'
import { QUERY_KEYS } from '../../../../../api/queryKeys'
import { getBillingRuns } from '../../../../../service'
import { RUN_STATUS } from '../../billing/runBilling/helpers'
import { messageToUse } from '../../common'

const BillingRunOverride = ({
  error = null,
  value = null,
  onChange = noop,
  classes = {}
}) => {
  const theme = useTheme()

  const {
    data: billingRuns,
    isLoading: isLoadingBillingRuns
  } = useQuery({
    queryKey: [QUERY_KEYS.reports, 'billingRuns'],
    queryFn: async () => {
      const { data } = await getBillingRuns({
        take: 20,
        skip: 0,
        filters: {
          status: [
            { op: 'eq', value: RUN_STATUS.MARK_AS_FINAL }
          ]
        },
        sort: [{ field: 'createdAt', dir: 'desc' }]
      })
      return data
    },
    enabled: true
  })

  useEffect(() => {
    if (billingRuns?.length && !value?.billingRunId) {
      onChange({ key: 'billingRun', value: billingRuns[0] })
    }
  }, [billingRuns, onChange, value?.billingRunId])

  const frequencyMap = {
    M: 'Monthly',
    Q: 'Quarterly',
    A: 'Annually'
  }
  const levelTypeMap = {
    CLIENT: {
      label: 'Client',
      backgroundColor: THEME_COLORS.lightGrey,
      color: THEME_COLORS.shuttleGrey
    },
    ACCOUNT: {
      label: 'Account',
      backgroundColor: THEME_COLORS.shuttleGrey,
      color: '#fff'
    }
  }

  const handleOnChange = useCallback((e) => {
    const billingRunId = e.target.value
    onChange({
      key: 'billingRun',
      value: billingRuns.find(billingRun => billingRun.billingRunId === billingRunId)
    })
  }, [billingRuns, onChange])

  return (
    <div className={classes.groupItem}>
      <div className={classes.labelWithInfo}>
        <Text text='Finalized Billing Run to use' variant='h3' />
        <Link to={ADMIN_ROUTES.BILLING_RUN} target='_blank'>Manage</Link>
      </div>
      {
        isLoadingBillingRuns ? (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />
        ) : !billingRuns?.length ? (
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='There are currently no finalized billing runs.  Use the manage button above to update your billing runs'
              variant='body2'
            />
          </div>
        ) : (
          <TextField
            select
            fullWidth
            className={classes.itemTextField}
            InputProps={{ disableUnderline: true }}
            onChange={handleOnChange}
            error={Boolean(error?.length)}
            disabled={isLoadingBillingRuns}
            defaultValue={billingRuns[0].billingRunId}
            value={value?.billingRunId}
          >
            {billingRuns.map((
              {
                billingRunId, levelType, asOfDate, createdAt, frequency
              }) => {
              return (
                <MenuItem key={billingRunId} value={billingRunId}>
                  <div className={classes.taggedSelectOption}>
                    <Text variant={TEXT_VARIANTS.body}>
                      <div>({frequencyMap[frequency]})</div>
                      <div><strong>As of:</strong> {asOfDate}</div>
                      <div><strong>Run Date:</strong> {dayjs(createdAt).format('M/D/YY h:mm a')}</div>
                    </Text>
                    <div className={classes.taggedSelectOptionTags}>
                      <Tag {...levelTypeMap[levelType]} />
                    </div>
                  </div>
                </MenuItem>
              )
            })}
          </TextField>
        )
      }

      {!!error?.length && (
        <Text
          text={messageToUse(error)}
          color={theme.palette.error.main}
          className={classes.confirmError}
        />
      )}
    </div>
  )
}

BillingRunOverride.propTypes = {
  error: PropType.string,
  value: PropType.object,
  onChange: PropType.func,
  classes: PropType.object
}
export default BillingRunOverride
