import { Link, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { noop } from 'lodash'
import clsx from 'clsx'
import { getBillingRunFile } from '../../../../../service'
import { getFileExtension } from '../../../../../utils'
import { REPORT_TYPE_NAMES } from './helpers'

const BILLING_RUN_STATUSES = {
  PENDING: 'PENDING'
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'underline'
  },
  disabled: {
    cursor: 'not-allowed !important',
    opacity: '0.7'
  }
}))

const CellDownload = ({
  row: {
    original: { levelType, status, files }
  }
}) => {
  const classes = useStyles()

  const onClick = useCallback(
    async (event, billingRunFileId, fileName) => {
      event.preventDefault()

      const { data: fileMeta } = await getBillingRunFile(billingRunFileId)
      if (!fileMeta.downloadLink) {
        return
      }

      const fileExtension = getFileExtension(fileName, false)
      const link = document.createElement('a')
      link.href = fileMeta.downloadLink

      const levelName = levelType?.toLowerCase()
      const dateFormatted = dayjs().format('YYYY-MM-DD')
      link.download = `run_billing_report_${levelName}_${dateFormatted}.${fileExtension}`
      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    [levelType]
  )

  return (
    <div className={classes.container}>
      {(files || []).map(({ billingRunFileId, type, fileName }) => {
        const disabled = status === BILLING_RUN_STATUSES.PENDING
        const reportName = REPORT_TYPE_NAMES?.[type] ?? type
        return (
          <Link
            key={billingRunFileId}
            href='#'
            disabled={disabled}
            className={clsx(classes.link, {
              [classes.disabled]: disabled
            })}
            onClick={
              disabled ? noop : (event) => onClick(event, billingRunFileId, fileName)
            }
          >
            {reportName}
          </Link>
        )
      })}
    </div>
  )
}

CellDownload.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      billingRunId: PropTypes.number,
      levelType: PropTypes.string,
      status: PropTypes.string,
      files: PropTypes.array
    })
  })
}

export default CellDownload
