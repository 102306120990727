/**
 * Takes a value and replaces it (if available in replacements)
 * @param {string} value
 * @param {object} replacements
 * @return {*}
 */
export function replaceVariable (value, replacements) {
  if (!value) {
    return value
  }
  const lookup = value.substring(1)
  if (lookup in replacements) {
    return replacements[lookup]
  }
  return value
}

/**
 * Takes an object used for configuration and substitutes tokens with values based upon what is available in appContext
 * @param {object} obj
 * @param {object} replacements
 * @return {{[p: string]: any}}
 */
export function replaceObjectValues (obj, replacements) {
  if (!obj) return obj

  const entries = Object.entries(obj).map(([key, value]) => {
    return [key, replaceValues(value, replacements)]
  })

  return Object.fromEntries(entries)
}

/**
 * Replaces the values of an array
 * @param {Array<*>} array
 * @param {object} replacements
 * @return {Array<*>}
 */
export function replaceArrayValues (array, replacements) {
  if (!array) return array

  return array.map(value => replaceValues(value, replacements))
}

export function replacePlaceholders (str, replacements) {
  return str.replace(/\$\{\{\s*(\w+)\s*\}\}/g, (match, key) => {
    return key in replacements ? replacements[key] : ''
  })
}

export function replaceValues (value, replacements) {
  if (!value) return value

  if (typeof value === 'string' && value.startsWith('$')) {
    return replaceVariable(value, replacements)
  }
  if (Array.isArray(value)) {
    return replaceArrayValues(value, replacements)
  }
  if (typeof value === 'object') {
    return replaceObjectValues(value, replacements)
  }
  return value
}
