import { createTheme } from '@material-ui/core/styles'
import { blue, green, grey, lightBlue, red } from '@material-ui/core/colors'

export const MAIN_HEADER_HEIGHT = 50

export const themeConfiguration = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
      sidebarMin: 1500,
      sidebarMax: 1800
    }
  },
  layers: {
    overlay: 2000
  },
  palette: {
    gray: {
      lighter: grey[50],
      light: grey[100],
      main: grey[200],
      dark: grey[300],
      darker: grey[400],
      A500: grey[500],
      A600: grey[600],
      A700: grey[700]
    },
    bluePalette: {
      main: lightBlue[400]
    },
    background: {
      default: 'rgba(255, 255, 255)',
      paper: grey[50],
      card: 'rgba(255, 255, 255, 0.9)',
      clear: 'transparent'
    },
    baseBackground: {
      main: '#EEF0F899',
      light: '#EEF0F833',
      avatar: '#E9EAEF'
    },
    change: {
      positive: green[500],
      negative: red[500]
    },
    card: {
      background: 'rgb(255,255,255)',
      border: 'rgb(220,220,220)',
      borderHigh: 'rgb(64,64,80)',
      shadow: 'rgba(64,64,80,.15)',
      shadow2: 'rgba(64,64,80,.65)'

    },
    gallery2: '#F0F0F0',
    seashell: '#F1F1F1',
    saffron: '#F5B639',
    yellowOrange: '#FFC043',
    gold: '#FFC452',
    lightGreen: '#6BCFB5',
    lightGrey: '#EFEFEF',
    darkGrey: '#8E8E8E',
    shuttleGrey: '#5F6578',
    darkBlue: '#171c47',
    lavanda: '#98b3dc',
    coral: '#e77a7c',
    lightCoral: '#F5DEE2',
    selected: '#5b92f4',
    dimmed: '#e1e1e1',
    cloudBurst: '#212945',
    summitBlue: '#212945',
    mirage: '#171C30',
    white: '#FFF',
    black: '#000',
    lighterGrey: '#ccc',
    lightBlack: '#212121',
    silver: '#C4C4C4',
    silver50: 'rgba(196, 196, 196, 0.5)',
    concrete: '#F2F2F2',
    porcelain: '#F3F4F5',
    emerald: '#52D869',
    wildSand: '#F6F6F6',
    gallery: '#EEEEEE',
    ghost: '#C8CCDA',
    cadetBlue: '#A1A7BE',
    seaFoam: '#E9EAEC',
    seaFoam50: 'rgba(233, 234, 236, 0.5)',
    grandis: '#FFD88A',
    jordyBlue: '#9ABCF8',
    indigoBlue: '#5b91f4',
    whiteLilac: '#EEF0F8',
    hardcoded: '#A4A4A4',
    dustyGray: '#999999',
    doveGray: '#666666',
    summitGrey1: '#E0E0E0',
    summitGrey2: '#CCCCCC',
    dividerGrey: '#AAAAAA',
    mediumSeaGreen: '#3AA76D',
    blackCoral: '#5A5E6D',
    ghostWhite: '#F9F9F9',
    tableShadow: {
      main: '#EAF0F6',
      dark: '#CAE0E6'
    },
    danger: {
      main: '#D44333',
      light: '#FADBD7',
      hover: 'rgba(250, 219, 215, 0.5)',
      disabled: 'rgba(250, 219, 215, 0.5)',
      contrast: '#fff'
    },
    info: {
      main: grey[700]
    },
    primary: {
      main: '#212945',
      hover: 'rgba(33, 41, 69, 0.75)',
      disabled: '#898D9B',
      contrast: '#fff'
    },
    secondary: {
      main: '#EEF0F8',
      hover: 'rgba(137, 141, 155, 0.5)',
      disabled: 'rgba(238, 240, 248, 0.5)',
      contrast: '#141929'
    },
    success: {
      main: '#14ac7e',
      A50: green['50'],
      A500: green['500'],
      A700: green['700']
    },
    error: {
      main: red.A700,
      A50: red['50'],
      A100: red['100'],
      A500: red['500'],
      primary: '#D44333',
      dark: '#A93528'
    },
    muiBlue: {
      A100: blue['100'],
      A200: blue['200'],
      A50: blue['50'],
      A300: blue['300']
    },
    laser: '#85682d',
    grayDone: '#21294566',
    darkJungle: '#141929',
    romanSilver: '#898D9B',
    dimGray: '#676B77',
    culture: '#F5F6F8',
    green: {
      sheen: '#73C496',
      seaFoam: '#AEDDC2',
      light: '#DAF0E3'
    },
    red: {
      light: '#EC7E7E'
    },
    platinum: '#E2E2E2',
    silverFoil: '#AFAFAF',
    navajo: '#FFE3AC',
    palePink: '#FADBD7',
    melon: '#F4AFA7'
  },
  typography: {
    fontSizes: {
      tiny: '9px',
      xs: '9px',
      small: '10px',
      sm: '10px',
      medium: '11px',
      md: '11px',
      large: '12px',
      lg: '12px',
      xl: '14px',
      xxl: '16px',
      h1: '2rem',
      h2: '1.75rem',
      h3: '1.5rem',
      h4: '1.25rem',
      h5: '1rem',
      h6: '.75rem'
    },
    fontFamily: [
      'GothamPro',
      'Gotham-Book',
      '"Open Sans"',
      'Tahoma',
      '"Segoe UI"',
      'sans-serif'
    ].join(','),
    fontSize: 14,
    fontWeight: 400,
    color: '#212945',
    root: {
      color: '#f12945'
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.4,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 600
    },
    body3: {
      fontSize: '0.9rem',
      fontWeight: 400,
      lineHeight: 1.4
    },
    h1: {
      fontFamily: [
        'GothamPro-Bold',
        'Gotham-Book',
        '"Open Sans"',
        'Tahoma',
        '"Segoe UI"',
        'sans-serif'
      ].join(','),
      fontSize: 24
      // fontWeight: 'bolder'
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bolder'
    },
    h3: {
      fontSize: 16,
      fontWeight: 'bolder'
    },
    h4: {
      fontSize: 14
    },
    h5: {
      fontSize: '2.5rem'
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: grey[800]
    },
    subtitle2: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 'bolder'
    },
    overline: {
      fontFamily: [
        'GothamPro-Bold',
        'Gotham-Book',
        '"Open Sans"',
        'Tahoma',
        '"Segoe UI"',
        'sans-serif'
      ].join(','),
      lineHeight: '1.0625rem',
      fontSize: '0.875rem',
      marginBottom: '.25rem',
      fontWeight: 350,
      textTransform: 'none',
      display: 'block'
    },
    weights: {
      light: 400,
      medium: 600,
      semibold: 800,
      bold: 1000
    },
    families: {
      normal: 'GothamPro,Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif',
      bold: 'GothamPro-Bold,Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif',
      tabular: 'Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif'
    }
  },
  layout: {
    shadow: {
      none: 'unset',
      light: '0px 7px 7px -4px rgba(0,0,0,0.1)',
      normal: '0px 8px 8px -4px rgba(0,0,0,0.15)',
      deep: '0px 10px 10px -5px rgba(0,0,0,0.5)'
    },
    padding: {
      none: '0',
      tiny: '2px',
      dense: '5px',
      thin: '5px',
      medium: '10px',
      loose: '20px',
      thick: '20px'
    },
    margin: {
      none: '0',
      thin: '5px',
      medium: '10px',
      thick: '20px'
    },
    gap: {
      none: '0',
      dense: '5px',
      medium: '15px',
      loose: '20px',
      sparse: '30px',
      diluted: '50px'
    },
    radius: {
      none: '0',
      tight: '4px',
      loose: '8px',
      round: '12px',
      circle: '200px'
    },
    appBar: {
      paddingTop: '5px',
      paddingBottom: '0',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      height: MAIN_HEADER_HEIGHT,
      width: '100%',
      transition: 'all .2s ease-in-out'
    },
    appBarScroll: {
      paddingTop: '5px',
      backgroundColor: '#ffffff',
      boxShadow: '0px 8px 8px -7px rgba(0,0,0,0.15)',
      height: MAIN_HEADER_HEIGHT + 10,
      width: '100%',
      transition: 'all .2s ease-in-out'
    },
    appToolBar: {
      height: MAIN_HEADER_HEIGHT,
      minHeight: MAIN_HEADER_HEIGHT,
      maxHeight: MAIN_HEADER_HEIGHT
    },
    headerFamily: {
      position: 'absolute',
      marginTop: '5px',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: MAIN_HEADER_HEIGHT,
      zIndex: 1201,
      '@media (max-width: 600px)': {
        marginTop: '0',
        position: 'relative',
        left: 'auto',
        top: 'auto',
        transform: 'none',
        justifyContent: 'center',
        zIndex: 1001
      }
    },
    headerFamilyTitle: {
      '@media (max-width: 600px)': {
        fontSize: '0.9rem !important'
      }
    },
    viewTitle: {
      '@media (max-width: 600px)': {
        fontSize: '0.9rem'
      }
    },
    wheelInfo: {}
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: 'Gotham-Book',
        paddingTop: 0,
        fontSize: '1rem',
        color: '#212945'
      }
    },
    MuiSelect: {
      root: {
        fontFamily: 'Gotham-Book',
        fontSize: '1rem'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiSlider: {
      thumb: {
        background: 'radial-gradient(circle at center, white 25%, black 25%)'
      },
      track: {
        color: 'black'
      },
      rail: {
        color: grey[600]
      }
    }
  }
}

const theme = createTheme(themeConfiguration)

export default theme
