import { useMemo } from 'react'
import { useCheckPolicy } from '../../../../hooks'
import { WEALTH_JOURNEY_POLICIES } from '../../../../policies/wealthJourney'
import entryType, { ACTIONS } from './entryType'

const getPolicyName = (selectedEntryType, action) => {
  switch (selectedEntryType) {
    case entryType.MILESTONES:
      if (action === ACTIONS.EDIT) {
        return WEALTH_JOURNEY_POLICIES.wj_edit_milestones
      }
      return WEALTH_JOURNEY_POLICIES.wj_view_milestones
    case entryType.ACTIVITIES:
      if (action === ACTIONS.EDIT) {
        return WEALTH_JOURNEY_POLICIES.wj_edit_activities
      }
      return WEALTH_JOURNEY_POLICIES.wj_view_activities
    case entryType.MEETINGS:
      if (action === ACTIONS.EDIT) {
        return WEALTH_JOURNEY_POLICIES.wj_edit_mtgnotes
      }
      return WEALTH_JOURNEY_POLICIES.wj_view_mtgnotes
    default:
      return ''
  }
}

export const useWealthJourneyPermissions = (selectedEntryType, action) => {
  const allowed = useCheckPolicy(getPolicyName(selectedEntryType, action))
  return useMemo(() => {
    return allowed
  }, [allowed])
}
