import React, { useCallback } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import useExpandibleTable from '../../hooks/useExpandibleTable'
import { updateRowByIndex } from '../../utils'
import { CollapsibleTableColumnsPropTypes, CollapsibleTableRowsPropTypes } from '../../prop-types/tables'
import CollapsibleTable from './CollapsibleTable'

const AssetClassificationTypesTable = ({ rows: _rows, columns: _columns, disableOnRowClick }) => {
  const { rows, columns, setRows } = useExpandibleTable({
    rows: _rows,
    columns: _columns
  })

  const updateRow = useCallback((rowIndexes, isExpanded) => {
    const rowsModified = updateRowByIndex(rows, rowIndexes, { isExpanded })
    setRows(rowsModified)
  }, [rows, setRows])

  const onExpandRow = useCallback(({ rowIndexes, isExpanded }) =>
    updateRow(rowIndexes, isExpanded), [updateRow])

  const onRowClick = useCallback((_, __, { rowIndexes, isExpanded }) =>
    disableOnRowClick ? noop : updateRow(rowIndexes, isExpanded), [updateRow, disableOnRowClick])

  return (
    <CollapsibleTable
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      onExpandRow={onExpandRow}
    />
  )
}

AssetClassificationTypesTable.propTypes = {
  rows: CollapsibleTableRowsPropTypes,
  columns: CollapsibleTableColumnsPropTypes,
  disableOnRowClick: PropTypes.bool
}

AssetClassificationTypesTable.defaultProps = {
  rows: [],
  columns: [],
  disableOnRowClick: false
}

export default AssetClassificationTypesTable
