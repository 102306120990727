import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useProjectedIncome } from '../../../api/coreData'
import {
  useAppContext,
  useAvailableDates
} from '../../../redux/slices/appContext'
import GroupedCoreBarChart from '../GroupedCoreBarChart'
import { mapMaturityValues } from './helpers'

const MaturityLadderBarChart = ({
  color,
  theme,
  startYear,
  chartProps,
  defaultQuery,
  tooltipFormat,
  axisLeftFormat,
  groupAfterYear,
  defaultFilters,
  emptySectionLabel
}) => {
  const { clientId } = useAppContext()
  const [availableDates, loadingAvailableDates] = useAvailableDates()

  const query = useMemo(() => {
    if (loadingAvailableDates) return null
    return {
      filters: {
        ...defaultFilters,
        clientId
      },
      levelTypes: ['client', 'asset'],
      ...defaultQuery,
      dateRange: {
        startDate: availableDates.mainDate,
        endDate: availableDates.mainDate
      }
    }
  }, [
    clientId,
    defaultQuery,
    defaultFilters,
    availableDates,
    loadingAvailableDates
  ])

  const { data = [], isLoading } = useProjectedIncome(query)

  const dataSeries = useMemo(() => {
    return mapMaturityValues({
      data,
      color,
      startYear,
      groupAfterYear
    })
  }, [data, startYear, groupAfterYear, color])

  return (
    <GroupedCoreBarChart.Chart
      theme={theme}
      color={color}
      data={dataSeries}
      isLoading={isLoading}
      chartProps={chartProps}
      tooltipFormat={tooltipFormat}
      axisLeftFormat={axisLeftFormat}
      emptySectionLabel={emptySectionLabel}
    />
  )
}

export const maturityLadderBarChartPropTypes = {
  color: PropTypes.string,
  startYear: PropTypes.number,
  groupAfterYear: PropTypes.number,
  defaultFilters: PropTypes.object,
  defaultQuery: PropTypes.object,
  chartProps: PropTypes.object,
  axisLeftFormat: PropTypes.string,
  tooltipFormat: PropTypes.string,
  emptySectionLabel: PropTypes.string,
  theme: PropTypes.object
}

export const MaturityLadderBarChartDefaultProps = {
  color: '#4472C4',
  startYear: dayjs().year(),
  endYear: null,
  groupAfterYear: null,
  defaultFilters: {},
  defaultQuery: {},
  chartProps: {},
  axisLeftFormat: 'marketValue',
  tooltipFormat: 'human',
  emptySectionLabel: 'No data found',
  theme: {
    fontFamily: 'Gotham-Book',
    fontSize: '12px'
  }
}

MaturityLadderBarChart.propTypes = maturityLadderBarChartPropTypes
MaturityLadderBarChart.defaultProps = MaturityLadderBarChartDefaultProps

export default MaturityLadderBarChart
