import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  trailingSpace: {
    minHeight: '100px'
  }
}))
function PersonalSpace () {
  const classes = useStyles()
  return (
    <div className={classes.trailingSpace} />
  )
}

export default PersonalSpace
