import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import { useFilterSubscription } from '../../../contexts/FilterSubscriptionContext/useFilterSubscription'
import WidgetWrapperTitle from './WidgetWrapperTitle'

const useStyles = makeStyles(() => ({
  widgetWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}))

const WidgetContext = createContext({
  subscribedFilters: [],
  publishFilters: noop
})

export const useWidgetContext = () => {
  const context = useContext(WidgetContext)
  return context
}

const WidgetWrapper = ({
  id,
  style,
  children,
  filterSubscription,
  ...containerProps
}) => {
  const classes = useStyles()

  const filterSubscriptionOptions = useMemo(() => {
    const { publish, subscribe } = filterSubscription || {}
    return {
      filterKey: id,
      publishKeys: publish,
      subscribeKeys: subscribe,
      enabled: !isEmpty(filterSubscription)
    }
  }, [id, filterSubscription])

  const { subscribedFilters, publishFilters } = useFilterSubscription(
    filterSubscriptionOptions
  )

  return (
    <WidgetContext.Provider value={{ subscribedFilters, publishFilters }}>
      <Box className={classes.widgetWrapper} style={style} {...containerProps}>
        {children}
      </Box>
    </WidgetContext.Provider>
  )
}

WidgetWrapper.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  filterSubscription: PropTypes.shape({
    publish: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object
    ]),
    subscribe: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object
    ])
  })
}

export const WidgetTitle = WidgetWrapperTitle
export const WidgetContainer = WidgetWrapper

export default WidgetWrapper
