import { Grid } from '@material-ui/core'
import React from 'react'
import ClientWelcome from './ClientWelcome'
import ClientWealthMission from './ClientWealthMission'
import WealthMissionSection from './WealthMissionSection'
import ClientTeamMembers from './ClientTeamMembers'
import ClientValues from './ClientValues'
import ClientHouseHoldFamilyTree from './ClientHouseHoldFamilyTree'
import WealthMissionViewContainer from './WealthMissionViewContainer'

const WealthMissionView = () => {
  return (
    <WealthMissionViewContainer>
      <Grid container spacing={3}>
        <Grid item md={6} lg={4} xs={12}>
          <ClientWelcome />
          <WealthMissionSection title='Your Mission'>
            <ClientWealthMission />
          </WealthMissionSection>
          <WealthMissionSection title='Your Family'>
            <ClientHouseHoldFamilyTree />
          </WealthMissionSection>
          <WealthMissionSection title='Your Team'>
            <ClientTeamMembers />
          </WealthMissionSection>
        </Grid>
        <Grid item md={6} lg={8} xs={12}>
          <WealthMissionSection title='Family Values'>
            <ClientValues />
          </WealthMissionSection>
        </Grid>
      </Grid>
    </WealthMissionViewContainer>
  )
}

export const WealthMissionViewComponents = {
  WM_ClientWelcome: ClientWelcome,
  WM_Section: WealthMissionSection,
  WM_ClientMission: ClientWealthMission,
  WM_ClientHouseHoldFamilyTree: ClientHouseHoldFamilyTree,
  WM_ClientTeamMembers: ClientTeamMembers,
  WM_ClientValues: ClientValues,
  WM_Container: WealthMissionViewContainer
}

export default WealthMissionView
