
import { useMemo } from 'react'
import concat from 'lodash/concat'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useSearchUsers, useGetUserGeneralInfo } from '../../../../api/users'
import { userTypeName } from './helpers'

dayjs.extend(utc)

export const useUsersCSVData = ({ data, columns }) => {
  return useMemo(() => {
    if (!data) return []
    const labels = columns.map(({ Header }) => Header)
    const rows = data.map(({ firstName, lastName, email, userTypeId, internal }) => {
      return [
        firstName || '',
        lastName || '',
        email || '',
        userTypeName(userTypeId, internal)
      ]
    })
    return concat([labels], rows)
  }, [data, columns])
}

export const useUserWithRole = ({
  userId,
  onSuccess
}) => {
  const query = useMemo(() => ({
    filters: {
      externalUserId: [userId]
    },
    includes: {
      role: true
    }
  }), [userId])

  const { data = [], isLoading, refetch } = useSearchUsers(query, onSuccess)

  return {
    data: isLoading ? {} : data[0],
    isLoading,
    refetch
  }
}

export const useUserGeneralInfo = ({
  userId,
  onSuccess
}) => {
  const query = useMemo(() => (userId), [userId])

  const { data = {}, isLoading, refetch } = useGetUserGeneralInfo(query, onSuccess)

  return {
    data: data,
    isLoading,
    refetch
  }
}
