import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import OperationalTable from '../../../organisms/OperationalTable'
import { columnsConfig } from './columns'

const useStyles = makeStyles(() => ({
  tableWrapper: {
    width: '100%'
  },
  tableHeader: {
    zIndex: 1
  },
  addUser: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function AssociatedClientsTable ({
  onSortingChange,
  onPagingChange,
  onTableModeChange,
  defaultPageSize,
  loading,
  clients,
  columns
}) {
  const classes = useStyles()

  const { defaultSort } = columnsConfig

  return (
    <OperationalTable.Wrapper className={classes.tableWrapper}>
      <OperationalTable
        mode='server'
        columns={columns}
        data={clients || []}
        defaultPageSize={defaultPageSize}
        defaultSort={defaultSort}
        itemName='Associated Clients'
        loading={loading}
        total={0}
        onSortingChange={onSortingChange}
        onPagingChange={onPagingChange}
        onTableModeChange={onTableModeChange}
      />
    </OperationalTable.Wrapper>
  )
}

AssociatedClientsTable.propTypes = {
  onSortingChange: PropTypes.func,
  onPagingChange: PropTypes.func,
  onTableModeChange: PropTypes.func,
  defaultPageSize: PropTypes.number,
  clients: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
}

export default AssociatedClientsTable
