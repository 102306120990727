import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  Box,
  makeStyles
} from '@material-ui/core'
import {
  useForm
} from 'react-hook-form'
import { useCheckPolicy } from '../../../../hooks'
import {
  ICON_NAMES,
  ERROR_ALERT,
  SUCCESS_ALERT
} from '../../../../constants'
import {
  editUser
} from '../../../../service'
import { ADVISOR } from '../../../../policies/admin'
import { useOperationalTable } from '../../../organisms/OperationalTable'
import { useInvalidateClientsQueries } from '../../../../api/clients'
import Icon from '../../../atoms/Icon'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import { useClients } from '../Clients/ClientListTab/hooks'
import TitleWithEditButton from '../AccountDetails/TitleWithEditButton'
import { columnsConfig } from './columns'
import ClientsTable from './ClientsTable'
import AssociatedClientsTable from './AssociatedClientsTable'
import Remove from './Remove'
import SubmitButtons from './SubmitButtons'

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '2%',
    right: 0,
    left: 0,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'column-reverse'
    },
    zIndex: 3
  }
}))

const CLIENTS_PAGE_SIZE = 50

function ClientsTableForm ({
  user,
  disableEdit,
  setDisableEdit
}) {
  const classes = useStyles()
  const canViewEdit = useCheckPolicy(ADVISOR.editAdvisor)
  const [editMode, setEditMode] = useState(false)
  const [associatedClients, setAssociatedClients] = useState([])
  const [addAssociations, setAddAssociations] = useState([])
  const [removeAssociations, setRemoveAssociations] = useState([])
  const [selectedAssociatedClients, setSelectedAssociatedClients] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [alert, setAlert] = useState({})
  const { userId } = useParams()

  const isNew = !userId

  const resetClientsQueries = useInvalidateClientsQueries()

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {}
  })

  const { handleSubmit, reset } = formMethods

  function onSuccesUsers ({ data }) {
    setAssociatedClients(data)
    setSelectedAssociatedClients(data?.map((item) => item.clientId))
  }

  const { defaultSort, columns } = columnsConfig

  // Clients table
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable({ defaultSort, defaultPageSize: CLIENTS_PAGE_SIZE })

  // Asssociated clients table
  const {
    defaultPageSize: aDefaultPageSize,
    pageIndex: aPageIndex,
    pageSize: aPageSize,
    sort: aSort,
    onPagingChange: aOnPagingChange,
    onSortingChange: aOnSortingChange,
    onTableModeChange: aOnTableModeChange,
    tableMode: aTableMode
  } = useOperationalTable({ defaultSort, defaultPageSize: CLIENTS_PAGE_SIZE })

  // Get clients data
  const { data: clients, loading: isLoadingClients } = useClients({
    pageIndex,
    pageSize,
    sort,
    searchText,
    tableMode
  })

  // Get associated clients to the user
  const {
    loading: loadingAssociated,
    refetch: refetchAssociated
  } = useClients({
    aPageIndex,
    aPageSize,
    aSort,
    aTableMode,
    filters: {
      assignToUserIds: { op: 'in', value: user.internalId }
    },
    onSuccess: onSuccesUsers
  })

  const onRemoveAssociation = (clientId) => () => {
    setAssociatedClients(prev => [...prev].filter((item) => item.clientId !== clientId))
    setRemoveAssociations(prev => [...prev, clientId])
  }

  const onAddAssociation = (clientId) => () => {
    const client = clients.find(item => item.clientId === clientId)

    if (client) {
      setAssociatedClients([...associatedClients, client])
      setAddAssociations([...addAssociations, client])
    }
  }

  const handleEditUser = useCallback(async () => {
    setIsSubmitting(true)

    try {
      const removeItems = [...new Set(removeAssociations)]
      const addItems = [...new Set(addAssociations)].map((client) => client.clientId)

      const filteredRemove = removeItems.filter((item) => !addItems.includes(item))
      const filteredAdd = addItems.filter((item) => !filteredRemove.includes(item))

      const addedItems = filteredAdd.filter((item) => !selectedAssociatedClients.includes(item))

      const payload = {
        userId: user.internalId,
        firstName: user.firstName,
        lastName: user.lastName,
        avatarMediaId: user.avatarMediaId || null,
        removeAssociations: filteredRemove,
        addAssociations: addedItems
      }

      await editUser(user.userId, payload)
      setDisableEdit.off()
      setEditMode(false)
      setAlert({ ...SUCCESS_ALERT, alertMessage: 'User edited successfully' })
      refetchAssociated()
    } catch (error) {
      setAlert(ERROR_ALERT)
    } finally {
      setIsSubmitting(false)
    }
  }, [
    selectedAssociatedClients,
    removeAssociations,
    addAssociations,
    user,
    refetchAssociated,
    setDisableEdit
  ])

  const onSubmitHandler = useCallback((values, event) => {
    if (!associatedClients.length && !values.allAccountsAccess) {
      setAlert({ ...ERROR_ALERT, alertMessage: 'Choose at least one client to associate' })
      return
    }
    return handleEditUser(values)
  }, [handleEditUser, associatedClients])

  const clientsColumns = [...columns, {
    Header: 'Actions',
    accessor: (row) => {
      const associatedClient = associatedClients.find(({ clientId }) => clientId === row.clientId)

      return (
        associatedClient ? (
          <Remove onClick={onRemoveAssociation(row.clientId)}>
            <Remove.NoHover>
              <Icon name={ICON_NAMES.circleMinus} />
            </Remove.NoHover>
            <Remove.Hover>
              <Icon name={ICON_NAMES.circleMinusHover} />
            </Remove.Hover>
          </Remove>
        ) : (
          <Box onClick={onAddAssociation(row.clientId)} className={classes.addUser}>
            <Icon
              name={ICON_NAMES.addCircle}
              customSize='1rem'
            />
          </Box>
        )
      )
    },
    id: 'actions',
    disableSortBy: true
  }]

  const clientsProps = {
    onSearchTextChange,
    onSortingChange,
    onPagingChange,
    onTableModeChange,
    defaultPageSize,
    searchText,
    loading: isLoadingClients,
    columns: clientsColumns,
    clients
  }

  const associatedClientsProps = {
    onSortingChange: aOnSortingChange,
    onPagingChange: aOnPagingChange,
    onTableModeChange: aOnTableModeChange,
    defaultPageSize: aDefaultPageSize,
    loading: loadingAssociated,
    clients: associatedClients,
    columns
  }

  const resetForm = useCallback(() => {
    refetchAssociated()
    reset()
  }, [reset, refetchAssociated])

  const onCancel = useCallback(() => {
    resetForm()
    setDisableEdit.off()
    setEditMode(false)
  }, [resetForm, setEditMode, setDisableEdit])

  const handleEditMode = useCallback(() => {
    setEditMode((prev) => !prev)
    setDisableEdit.toggle()
  }, [setEditMode, setDisableEdit])

  useEffect(() => {
    return () => {
      resetClientsQueries()
    }
  }, [resetClientsQueries])

  useEffect(() => {
    return () => setDisableEdit.off()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitleWithEditButton
        text='Assigned Clients'
        onClick={handleEditMode}
        editMode={editMode}
        disabled={(!editMode && disableEdit) || !canViewEdit || isNew}
      />
      {
        editMode ? (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <ClientsTable {...clientsProps} />
            {editMode && (
              <SubmitButtons
                editMode={editMode}
                onCancel={onCancel}
                isSubmitting={isSubmitting}
              />
            )}
          </form>
        ) : (
          <AssociatedClientsTable {...associatedClientsProps} />
        )
      }
      <SnackAlert alert={alert} />
    </>
  )
}

ClientsTableForm.propTypes = {
  user: PropTypes.shape({
    internalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarMediaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  disableEdit: PropTypes.bool,
  setDisableEdit: PropTypes.any
}

export default ClientsTableForm
