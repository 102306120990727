import { useMemo } from 'react'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import PerformanceCellPicker from '../../organisms/PerformanceTableV2/PerformancePresentationTable/Cells'

const defaultColumnConfig = {
  columns: [
    {
      id: 'benchmarkName',
      accessor: 'benchmarkName',
      Header: 'Benchmark',
      minWidth: 400
    },
    {
      id: 'QTD',
      accessor: 'QTD_benchmarkReturn',
      Header: 'Quarter to Date',
      format: 'returns',
      align: 'right',
      component: 'dateRangeSelector'
    },
    {
      id: 'YTD',
      accessor: 'YTD_benchmarkReturn',
      Header: 'Year to Date',
      format: 'returns',
      align: 'right',
      component: 'dateRangeSelector'
    }
  ],
  defaultSort: [{ id: 'benchmarkName', desc: false }]
}

export const useColumns = ({
  format,
  columnsConfig = defaultColumnConfig,
  canChangeDateRange = false
}) => {
  const { formatter } = useFormattingContext(format)

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column, index) => {
      return {
        ...column,
        ...(canChangeDateRange && column.component === 'dateRangeSelector'
          ? {
            Header: PerformanceCellPicker,
            cellType: 'dateRangeSelector',
            index
          }
          : {}),
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter, canChangeDateRange])
}
