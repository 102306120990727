import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  makeStyles,
  useTheme
} from '@material-ui/core'

import Text from '../../../atoms/Text'
import OperationalTable from '../../../organisms/OperationalTable'

import { useSearchClients } from '../../../../api/clients'

import { columnsConfig } from './columns'

const useStyles = makeStyles(() => ({
  tableWrapper: {
    width: '100%'
  },
  tableHeader: {
    zIndex: 1
  }
}))

const totalsQuery = { resultType: 'total' }

function ClientsTable ({
  onSearchTextChange,
  onSortingChange,
  onPagingChange,
  onTableModeChange,
  defaultPageSize,
  searchText,
  loading,
  clients,
  columns
}) {
  const classes = useStyles()
  const theme = useTheme()

  const { defaultSort } = columnsConfig
  const { data: clientsTotal } = useSearchClients(totalsQuery)

  return (
    <>
      <Box marginBottom='1.10rem'>
        <Text
          customFontSize='0.875rem'
          color={theme.palette.dimGray}
        >
          Use the <b>search bar</b> to add clients. Remove clients by clicking the <b>minus</b> button from the assigned list below.
        </Text>
      </Box>
      <OperationalTable.Wrapper className={classes.tableWrapper}>
        <OperationalTable.SuperHeader className={classes.tableHeader}>
          <OperationalTable.Search
            onChange={onSearchTextChange}
            placeholder='Search by client name'
            delay={1000}
          />
        </OperationalTable.SuperHeader>
        <OperationalTable
          mode='server'
          columns={columns}
          data={clients || []}
          defaultPageSize={defaultPageSize}
          defaultSort={defaultSort}
          itemName='Clients'
          loading={loading}
          total={clientsTotal?.total}
          searchText={searchText}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
          onTableModeChange={onTableModeChange}
        />
      </OperationalTable.Wrapper>
    </>
  )
}

ClientsTable.propTypes = {
  onSearchTextChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  onPagingChange: PropTypes.func,
  onTableModeChange: PropTypes.func,
  defaultPageSize: PropTypes.number,
  searchText: PropTypes.string,
  clients: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
}

export default ClientsTable
