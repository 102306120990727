import { useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  fetchUser,
  fetchUsers,
  deleteUser,
  resetUser,
  postNamedQuery,
  getTeamMember,
  getRoleTypes,
  postNamedCommand
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useInvalidateUsersQueries = () => {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEYS.searchUser])
    queryClient.invalidateQueries([QUERY_KEYS.getUsers])
  }, [queryClient])
}

export const useRemoveUserQueries = () => {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient.removeQueries([QUERY_KEYS.searchUser])
    queryClient.removeQueries([QUERY_KEYS.currentUser])
  }, [queryClient])
}

export const useCurrentUser = (onSuccess) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.currentUser, userId],
    queryFn: async () => {
      const { data } = await fetchUser(userId)
      return data
    },
    onSuccess
  })
}

// userId is the externaluserId in userSecurity
export const useGetUser = (userId = '', onSuccess, skip) => {
  return useQuery({
    queryKey: [QUERY_KEYS.getUser, userId],
    queryFn: async () => {
      if (skip) return null
      const { data } = await fetchUser(userId)
      return data
    },
    enabled: !!userId,
    onSuccess
  })
}

export const useGetUsers = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getUsers, userId, queryParams],
    queryFn: async () => {
      const { data } = await fetchUsers(queryParams)
      const { users, count } = data
      return { users, count }
    },
    enabled: !!userId
  })
}

export const useRemoveUser = (id, onSuccess, onSettled) => {
  return useMutation({
    mutationFn: async () => {
      await deleteUser(id)
    },
    onSuccess,
    onSettled
  })
}

export const useResetUser = (payload, onSuccess, onSettled) => {
  return useMutation({
    mutationFn: async () => {
      await resetUser(payload)
    },
    onSuccess,
    onSettled
  })
}

export const useSearchUsers = (queryParams, onSuccess) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchUsers, userId, queryParams],
    queryFn: async () => {
      const { data: result } = await postNamedQuery('users', 'search-users', queryParams)
      return result
    },
    enabled: !!userId,
    onSuccess
  })
}

export const useSearchRoles = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchRoles, userId, queryParams],
    queryFn: async () => {
      const { data: result } = await postNamedQuery('users', 'searchRoles', queryParams)
      return result
    },
    enabled: !!userId
  })
}

export const useGetRoleById = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchRole, userId, queryParams],
    queryFn: async () => {
      if (!queryParams.roleId) return {}
      const { data: result } = await postNamedQuery('users', 'getRoleById', queryParams)
      return result
    },
    enabled: !!userId
  })
}

// in queryParams must be a userId to loog for.
export const useSearchUser = (queryParams, onSuccess) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchUser, userId, queryParams],
    queryFn: async () => {
      const { data: result } = await postNamedQuery('users', 'getUserById', queryParams)
      return result
    },
    enabled: !!userId,
    onSuccess
  })
}

export const useSearchPermissionGroups = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchRoles, userId, queryParams],
    queryFn: async () => {
      const { data: result } = await postNamedQuery('users', 'searchPermissionGroups', queryParams)
      return result
    },
    enabled: !!userId
  })
}

export const useSearchPermissions = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.searchPermissions, userId, queryParams],
    queryFn: async () => {
      const { data: result } = await postNamedQuery('users', 'searchPermissions', queryParams)
      return result
    },
    enabled: !!userId
  })
}

export const useGetUserGeneralInfo = (userExternalId, onSuccess) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.teamMember, userId, userExternalId],
    queryFn: async () => {
      const { data } = await getTeamMember(userExternalId)
      return data
    },
    enabled: !!userId && !!userExternalId,
    onSuccess
  })
}

export const useRoleTypes = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.roleTypes, userId],
    queryFn: async () => {
      const { data } = await getRoleTypes()
      return data
    },
    enabled: !!userId
  })
}

export const useCreateWealthOwnerMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('users', 'create-wealth-owner', command)

      return data
    }
  })
}

export const useAssignRoleToWealthOwnerMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('users', 'assign-role-to-wealth-owner', command)

      return data
    }
  })
}

export const useSearchWealthOwners = (query, options = {}, onSuccess) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchWealthOwners, userId, query],
    queryFn: async () => {
      const res = await postNamedQuery('users', 'search-wealth-owners', query)
      return res.data
    },
    enabled,
    select: mapper
  })
}

export const useListWealthOwnerClients = (targetUserId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.listAssignedWealthOwnerClients, userId, targetUserId],
    queryFn: async () => {
      const res = await postNamedQuery('users', 'list-wealth-owner-clients', {
        userId: targetUserId
      })

      return res.data
    },
    enabled
  })
}

export const useUpdateWealthOwnerMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('users', 'update-wealth-owner', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { user } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listAssignedWealthOwnerClients, caller, user.userId], exact: true }).catch(console.error)
    }
  })
}

export const useWealthOwnerStatusSummary = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getWealthOwnerStatusSummary, caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-wealth-owner-status-summary', {
        userId
      })

      return data
    }
  })
}

export const useAdvisorStatusSummary = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getAdvisorStatusSummary, caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-advisor-status-summary', {
        userId
      })

      return data
    }
  })
}

export const useWealthOwnerStatusReport = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getWealthOwnerStatusDetails, caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-wealth-owner-status-report', {
        userId
      })

      return data
    }
  })
}

export const useAdvisorStatusReport = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getWealthOwnerStatusDetails, caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-advisor-status-report', {
        userId
      })

      return data
    }
  })
}

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async ({ userId }) => {
      const { data } = await postNamedCommand('users', 'inviteUser', {
        userId
      })

      return data
    },
    onSuccess: (data, variables) => {
      const { userId } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getWealthOwnerStatusDetails, caller, userId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getWealthOwnerStatusSummary, caller, userId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['users.get-wealth-owner-invitations', caller, userId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['users.get-advisor-invitations', caller, userId], exact: true }).catch(console.error)
    }
  })
}

export const useResetUserMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async ({ email }) => {
      const { data } = await postNamedCommand('users', 'resetUser', {
        email
      })

      return data
    },
    onSuccess: (data, variables) => {
      const { userId } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getWealthOwnerStatusDetails, caller, userId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getWealthOwnerStatusSummary, caller, userId], exact: true }).catch(console.error)
    }
  })
}

export const useDeactivateUserMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async ({ externalUserId }) => {
      const { data } = await postNamedCommand('users', 'deactivate-wealth-owner', {
        externalUserId
      })

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchUsers, caller], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchUser, caller], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchWealthOwners, caller], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useMyPasswordPolicy = () => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['get-my-password-policy', caller],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-my-password-policy', {})

      return data
    }
  })
}

export const useMyEnrollmentPolicy = () => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['get-my-enrollment-policy', caller],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-my-enrollment-policy', {})

      return data
    }
  })
}

export const useMyUserProfile = () => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['get-my-user-profile', caller],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-my-user-profile', {})

      return data
    }
  })
}

export const useMySecurityQuestions = () => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['get-my-security-questions', caller],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-my-security-questions', {})

      return data
    }
  })
}

export const useChangeMyPasswordMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async ({ oldPassword, newPassword }) => {
      const { data } = await postNamedCommand('users', 'change-my-password', {
        oldPassword,
        newPassword
      })

      return data
    },
    onSuccess: (data, variables) => {
      if (data.success) {
        queryClient.invalidateQueries({
          queryKey: ['get-my-okta-authenticator-enrollments', caller],
          exact: true
        }).catch(console.error)
      }
    }
  })
}

export const useChangeMySecurityQuestionMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async ({ password, questionText, question, answer }) => {
      const { data } = await postNamedCommand('users', 'change-my-security-question', {
        password,
        questionText,
        question,
        answer
      })

      return data
    },
    onSuccess: (data, variables) => {
      if (data.success) {
        queryClient.invalidateQueries({
          queryKey: ['get-my-okta-authenticator-enrollments', caller],
          exact: true
        }).catch(console.error)
      }
    }
  })
}

export const useEditMyProfileMutation = () => {
  const queryClient = useQueryClient()
  const { userId: caller } = useAppContext()
  return useMutation({
    mutationFn: async (profile) => {
      const { data } = await postNamedCommand('users', 'editSelf', {
        user: profile
      })

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get-my-user-profile', caller],
        exact: true
      }).catch(console.error)
    }
  })
}

export const useByAllSessionTokens = () => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['createByAllSessionTokens', caller],
    queryFn: async () => {
      const { data } = await postNamedCommand('users', 'createByAllSessionTokens')

      return data
    }
  })
}

export const useWealthOwnerInvitations = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['users.get-wealth-owner-invitations', caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-wealth-owner-invitations', {
        userId
      })

      return data
    }
  })
}

export const useAdvisorInvitations = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['users.get-advisor-invitations', caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-advisor-invitations', {
        userId
      })

      return data
    }
  })
}

export const useReactivateWealthOwner = () => {
  const { userId: caller } = useAppContext()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (userId) => {
      const { data } = await postNamedCommand('users', 'reactivate-wealth-owner', {
        userId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchWealthOwners, caller],
        refetchType: 'all'
      }).catch(console.error)
    }
  })
}

export const usePermanentlyDeleteWealthOwner = () => {
  const { userId: caller } = useAppContext()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (userId) => {
      const { data } = await postNamedCommand('users', 'delete-wealth-owner', {
        userId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchWealthOwners, caller],
        refetchType: 'all'
      }).catch(console.error)
    }
  })
}
