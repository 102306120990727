import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSaveClientMutation } from '../../../../../api/clients'
import SectionScreen from '../SectionScreen'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import EditButton from '../EditButton'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import ClientInfoForm from './ClientInfoForm'
import ClientInfoDisplay from './ClientInfoDisplay'

const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function ClientInfoSection () {
  const classes = useStyles()
  const { client, editSection, refetchClient } = useClientDetails()
  const editing = useSectionEditing('client_info')
  const formRef = useRef()
  const { mutateAsync: saveClient } = useSaveClientMutation()
  const onSave = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        await saveClient({
          clientId: client.clientId,
          client: {
            shortName: form.shortName || null,
            longName: form.longName || null,
            description: form.description || null,
            clientAbbreviation: form.clientAbbreviation || null
          }
        })
        await refetchClient()
      } catch (err) {
        console.error('error trying to save a client', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, client, saveClient, refetchClient])

  return (
    <SectionScreen sectionName='client_info' className={classes.userInfoSection}>
      <SectionHeader text='Client Information'>
        <div>
          <EditButton
            policy='admin_edit_clientinfo'
            editing={editing}
            onClick={() => editSection({
              section: 'client_info',
              saveDescription: 'Save Client Information',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      {editing ? (
        <ClientInfoForm ref={formRef} client={client} />
      ) : (
        <ClientInfoDisplay client={client} />
      )}
      <PersonalSpace />
    </SectionScreen>
  )
}

export default ClientInfoSection
