import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import clsx from 'clsx'
import { useCheckPolicy } from '../../../hooks'
import Icon from '../../atoms/Icon'
import { ADMIN_ROUTES, TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'
import { BILLING } from '../../../policies/admin'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem'
  },
  content: {
    flexGrow: 1
  },
  paper: {
    height: '100%',
    backgroundColor: theme.palette.ghostWhite,
    '&:hover': {
      backgroundColor: theme.palette.gallery
    }
  },
  card: {
    textDecoration: 'none'
  },
  cardContent: {
    padding: '2.5rem',
    '&:last-child': {
      paddingBottom: '2.5rem'
    }
  },
  largeIcon: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  icon: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  largeTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  cardTitle: {
    marginBottom: '1rem'
  },
  cardDisabled: {
    opacity: '0.4',
    cursor: 'unset !important',
    backgroundColor: theme.palette.gallery,
    '&:hover': {
      backgroundColor: theme.palette.gallery
    },
    '& > a': {
      cursor: 'unset !important'
    }
  },
  header: {
    marginBottom: '1.25rem'
  }
}))

const BillingView = () => {
  const classes = useStyles()
  const theme = useTheme()
  const canViewFeeSchedules = useCheckPolicy(BILLING.viewFeeSchedules)
  const canRunBilling = useCheckPolicy(BILLING.runBilling)
  const clientReview = useCheckPolicy(BILLING.clientReview)

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const largeIconProps = useMemo(() => ({
    additionalClasses: clsx(classes.largeIcon, classes.icon),
    roundedBackground: '#EEE',
    customSize: mdDown ? (smDown ? '25px' : '35px') : '45px'
  }), [mdDown, smDown, classes.icon, classes.largeIcon])

  const largeTitleProps = useMemo(() => ({
    variant: mdDown ? TEXT_VARIANTS.h2 : TEXT_VARIANTS.h1,
    className: clsx(classes.cardTitle, classes.largeTitle)
  }), [mdDown, classes.cardTitle, classes.largeTitle])

  const CardLayout = ({ children, classes, to, disabled }) => useMemo(() => (
    <Paper elevation={0} className={clsx(classes.paper, { [classes.cardDisabled]: disabled })}>
      <Card elevation={0} className={classes.card} component={Link} to={to || '#'}>
        <CardContent className={classes.cardContent}>
          {children}
        </CardContent>
      </Card>
    </Paper>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [classes.paper, classes.card, classes.cardContent])

  const GridItem = ({ to, name, title }) => useMemo(() => (
    <Grid item xs={12} md={3}>
      <CardLayout to={to} classes={classes}>
        <Icon name={name} {...largeIconProps} />
        <Typography {...largeTitleProps}>{title}</Typography>
      </CardLayout>
    </Grid>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [classes])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Text text='Billing' variant='h1' className={classes.header} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          {canViewFeeSchedules && <GridItem to={ADMIN_ROUTES.BILLING_FEE_SCHEDULES} name='document' title='Fee Schedules' />}
          {canRunBilling && <GridItem to={ADMIN_ROUTES.BILLING_RUN} name='currency' title='Run Billing' />}
          {clientReview && <GridItem to={ADMIN_ROUTES.BILLING_CLIENT_REVIEW} name='accountCircle' title='Client Review' />}
        </Grid>
      </div>
    </Container>
  )
}

export default BillingView
