import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: theme.typography.fontSizes.h4
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    margin: '15px 0'
  }
}))

function SectionHeader ({ children, text }) {
  const classes = useStyles()

  return (
    <div className={classes.sectionHeader}>
      <div className={classes.headerText}>{text}</div>
      <>
        {children}
      </>
    </div>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node
}

export default SectionHeader
