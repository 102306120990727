/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useColumnOrder, useExpanded, usePagination, useSortBy, useTable } from 'react-table'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { LinearProgress } from '@material-ui/core'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import { useTablePositioningContext } from '../StickySuperHeader/TablePositioningContext'
import { useDeepCompareMemo, useHorizontalOverflow } from '../../../hooks'
import { useTableStyles } from './tableStyles'
import PagingTotals from './PagingTotals'
import PagingButtons from './PagingButtons'
import { operationalTablePropTypes } from './propTypes'
import { useAutoOperationalTableContext } from './AutoOperationalTableContext'

const ServerOperationalTable = ({
  columns,
  data,
  defaultPageSize,
  defaultSort,
  expandable,
  itemName,
  loading,
  total,
  onPagingChange,
  onSortingChange,
  onRowClick,
  hideFooter,
  hiddenColumns: _hiddenColumns,
  showTotalItemsLabel,
  autoSticky,
  variant
}) => {
  const hasOnRowClick = typeof onRowClick === 'function' && onRowClick.name !== 'noop'
  const { superHeaderRect } = useTablePositioningContext()
  const { setTableProps } = useAutoOperationalTableContext()
  const { hasHorizontalOverflow, containerRefCallback } = useHorizontalOverflow(autoSticky && !isEmpty(data))

  const classes = useTableStyles({
    superHeaderRect,
    loading,
    hasOnRowClick,
    hasHorizontalOverflow,
    variant
  })

  const hiddenColumns = useMemo(() => {
    if (!isEmpty(_hiddenColumns)) return _hiddenColumns
    return columns.filter(({ hidden }) => hidden).map((col) => col.id)
  }, [columns, _hiddenColumns])

  const {
    state: {
      pageIndex,
      pageSize,
      sortBy
    },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    flatHeaders,
    flatRows,
    allColumns,
    setColumnOrder,
    setHiddenColumns
  } = useTable({
    columns,
    data,
    manualPagination: true,
    manualSortBy: true,
    disableSortRemove: true,
    pageCount: -1,
    autoResetPage: false,
    initialState: {
      pageSize: defaultPageSize,
      pageIndex: 0,
      sortBy: defaultSort,
      hiddenColumns
    }
  }, useSortBy, useExpanded, usePagination, useColumnOrder)

  const rowClickHandler = useCallback(row => () => {
    if (expandable) {
      row.toggleRowExpanded()
    }
    if (onRowClick) {
      onRowClick(row)
    }
  }, [expandable, onRowClick])

  const tableProps = useDeepCompareMemo(
    () => ({
      flatRows,
      allColumns,
      flatHeaders,
      setColumnOrder,
      setHiddenColumns
    }),
    [
      flatRows,
      allColumns,
      flatHeaders,
      setColumnOrder,
      setHiddenColumns
    ]
  )

  useEffect(() => {
    if (!loading) {
      setTableProps(tableProps)
    }
  }, [setTableProps, tableProps, loading])

  useEffect(() => {
    onPagingChange({ pageIndex, pageSize })
  }, [pageIndex, pageSize, onPagingChange])

  useEffect(() => {
    onSortingChange(sortBy)
  }, [sortBy, onSortingChange])

  return (
    <div ref={containerRefCallback} className={classes.wrapper}>
      <div className={classes.spark}>
        <LinearProgress
          classes={{
            root: classes.sparkRoot
          }}
          style={{ opacity: loading ? 1 : 0 }}
          variant='indeterminate'
        />
      </div>
      <table {...getTableProps()} className={classes[variant]}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={clsx({
                    __right: column.alignRight
                  })}
                >
                  <div className='__thContainer'>
                    <span>{column.render('Header')}</span>
                    <span className='__sort-icon'>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <Icon name={ICON_NAMES.down} />
                          : <Icon name={ICON_NAMES.up} />
                        : ''}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={rowClickHandler(row)}
                className={clsx({
                  __child: (row.depth > 0),
                  __expanded: (row.isExpanded),
                  __expandable: expandable
                }, `__depth_${row.depth}`)}
              >
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className={clsx({
                      __right: cell.column.alignRight ?? false
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {!hideFooter && (
        <div className={classes.footer}>
          <PagingTotals
            pageSize={pageSize}
            itemName={itemName}
            totalItems={total}
            onPageSizeChanged={setPageSize}
            showTotalItemsLabel={showTotalItemsLabel}
          />
          <PagingButtons
            hasNext={canNextPage}
            hasPrev={canPreviousPage}
            onNext={nextPage}
            onPrev={previousPage}
          />
        </div>
      )}
    </div>
  )
}

ServerOperationalTable.propTypes = {
  ...operationalTablePropTypes,
  showTotalItemsLabel: PropTypes.bool,
  autoSticky: PropTypes.bool
}

ServerOperationalTable.defaultProps = {
  defaultPageSize: 100,
  loading: false,
  itemName: 'Items',
  onChange: noop,
  onClickRow: noop,
  hideFooter: false,
  hiddenColumns: [],
  showTotalItemsLabel: true,
  autoSticky: false,
  variant: 'v1'
}

export default React.memo(ServerOperationalTable)
