import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import ErrorBoundary from '../../../molecules/ErrorBoundary'

const useStyles = makeStyles((theme) => ({
  clientDetailTabs: {},
  viewTabs: {
  }
}))

const tabOptions = {
  general: {
    value: 'general',
    label: 'General Info'
  },
  clients: {
    value: 'clients',
    label: 'Clients'
  },
  security: {
    value: 'security',
    label: 'Security'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_advisor_users) {
      result.general = tabOptions.general
    }
    if (policies.admin_view_advisor_users) {
      result.clients = tabOptions.clients
    }
    if (policies.admin_view_advisor_users) {
      result.security = tabOptions.security
    }
    return result
  }, [policies])
}

function AdvisorDetailTabs ({ onTabChange, disabled, generalTab, clientsTab, securityTab }) {
  const classes = useStyles()
  const tabs = useTabOptions()

  return (
    <div className={classes.clientDetailTabs}>
      <ViewTabs.Group tabOptions={tabs} onChange={onTabChange}>
        <div className={classes.viewTabs}>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_advisor_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <ErrorBoundary name='advisor_general'>
              {generalTab}
            </ErrorBoundary>
          </ViewTabs.Tab>
        ) : null}
        {tabs.clients ? (
          <ViewTabs.Tab value='clients'>
            <ErrorBoundary name='advisor_clients'>
              {clientsTab}
            </ErrorBoundary>
          </ViewTabs.Tab>
        ) : null}
        {tabs.security ? (
          <ViewTabs.Tab value='security'>
            <ErrorBoundary name='advisor_security'>
              {securityTab}
            </ErrorBoundary>
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

AdvisorDetailTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool,
  generalTab: PropTypes.node,
  clientsTab: PropTypes.node,
  securityTab: PropTypes.node
}

AdvisorDetailTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default AdvisorDetailTabs
