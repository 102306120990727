import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSearchAccounts } from '../api/accounts'
import { DEFAULT_SELECT_SEARCH_PAGINATION, MILLISECONDS_BEFORE_SEARCH } from '../constants'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['longNumber', 'longName', 'masterCode', 'displayName', 'accountNumber']

const mapAccountOptions = ({ data: accounts = [] }) => {
  return accounts.map((account) => {
    const {
      accountId,
      displayName,
      longName,
      shortName,
      shortNumber,
      custodian,
      accountNumber
    } = account

    const name = displayName || longName || shortName || accountNumber || 'N/A'

    return {
      label: name,
      value: accountId,
      payload: {
        id: accountId,
        name,
        description: shortNumber,
        imageUrl: custodian ? custodian[0]?.imageUrl : '',
        account
      }
    }
  })
}

const getQueryFilters = ({ query, excludeLevelIds, assignedToClientIds, defaultAccountIds }) => {
  const filters = {}
  const levelFilters = {}
  let textSearch

  if (!isEmpty(excludeLevelIds)) {
    filters.accountId = [{ op: 'notIn', value: excludeLevelIds }]
  }
  if (!isEmpty(defaultAccountIds)) {
    filters.clientId = [{ op: 'in', value: defaultAccountIds }]
  }
  if (!isEmpty(assignedToClientIds)) {
    filters.assignedToClientIds = assignedToClientIds
  }
  if (query) {
    textSearch = getSearchQuery(SEARCH_FIELDS, query)
  }
  return { filters, levelFilters, textSearch }
}

const useSearchAccountsDebounced = ({
  query = {},
  queryOptions = {},
  defaultAccountIds = [],
  assignedToClientIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
} = {}) => {
  const [queryText, setQueryText] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })

    return {
      queryParams: {
        ...DEFAULT_SELECT_SEARCH_PAGINATION,
        ...queryParams,
        ...query
      },
      queryParamOptions: {
        mapper: mapAccountOptions,
        ...queryOptions
      }
    }
  }, [query, queryOptions, debouncedQuery])

  const { data, isLoading } = useSearchAccounts(queryParams, queryParamOptions)

  const { query: defaultsQuery, queryOptions: defaultQueryOptions } =
    useMemo(() => {
      return {
        query: {
          ...queryParams,
          filters: {
            ...queryParams.filters,
            accountId: defaultAccountIds,
            clientId: assignedToClientIds
          },
          take: defaultAccountIds?.length ?? 10
        },
        queryOptions: {
          mapper: mapAccountOptions,
          enabled: !isEmpty(defaultAccountIds),
          ...queryOptions
        }
      }
    }, [queryParams, defaultAccountIds, assignedToClientIds, queryOptions])

  const {
    data: defaultOptions,
    isLoading: isLoadingDefaults
  } = useSearchAccounts(defaultsQuery, defaultQueryOptions)

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: data,
    onChangeQuery: setQueryText,
    isSearchLoading: isTransitioningOptions,
    isLoading: isLoading || (isLoadingDefaults && !isEmpty(defaultAccountIds)),
    shouldFetchDefaultOptions: defaultQueryOptions.enabled,
    defaultOptions
  }
}

export default useSearchAccountsDebounced
