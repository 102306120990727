import React from 'react'
import PropTypes from 'prop-types'

const ClientDashboardItem = ({ children }) => {
  return (
    <div>{children}</div>
  )
}

ClientDashboardItem.propTypes = {
  children: PropTypes.func
}

export default ClientDashboardItem
