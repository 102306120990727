import React, { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useSearchAccountsDebounced } from '../../../../../hooks'
import SydSelect from '../../../../commonDesign/SydSelect'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import SydAutocomplete from '../../../../commonDesign/SydAutocomplete'
import SydInput from '../../../../commonDesign/SydInput'
import SydButton from '../../../../commonDesign/Button'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  readOnly: {
    fontSize: '14px',
    fontWeight: 400
  },
  removeButton: {
    backgroundColor: theme.palette.background.default,
    outline: `1.5px solid ${theme.palette.danger.main}`,
    color: theme.palette.danger.main,
    stroke: theme.palette.danger.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '21px',
    width: '21px',
    borderRadius: '12px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.danger.main,
      color: theme.palette.danger.contrast,
      stroke: theme.palette.danger.contrast
    },
    '&:disabled': {
      color: theme.palette.danger.disabled,
      stroke: theme.palette.danger.disabled
    }
  }
}))

const billingAccountTypes = [
  { value: 'summit', label: 'Account from Summit' },
  { value: 'external', label: 'External Billing Account' },
  { value: 'invoice', label: 'Paid by Invoice' }
]

const billingDistributionTypes = [
  { value: 'PERCENTAGE', label: 'Percentage' },
  { value: 'FIXED', label: 'Fixed Amount' },
  { value: 'REMAINING', label: 'Remaining Amount' }
]

const AccountBillingAccountRow = ({ index, isEditing, showDetails, formMethods, onAddBillingAccount, onRemoveBillingAccount }) => {
  const classes = useStyles()
  const currentValue = formMethods.watch('billingAccounts')[index]
  const originalValue = formMethods.formState.defaultValues.billingAccounts[index] ?? {}

  const {
    options: accounts = [],
    isLoading: isLoadingAccounts,
    isSearchLoading: isSearchAccountsLoading,
    onChangeQuery: onChangeAccountsQuery,
    defaultOptions: defaultAccounts
  } = useSearchAccountsDebounced({
    query: {
      limit: 10
    },
    queryOptions: {
      enabled: currentValue.billingAccountType === 'summit'
    },
    ...(originalValue.billingAccountId && { defaultAccountIds: [originalValue.billingAccountId] })
  })

  const isLoadingAccountOptions = useMemo(() => isLoadingAccounts || isSearchAccountsLoading, [isLoadingAccounts, isSearchAccountsLoading])
  const showSplitToggle = useMemo(() => isEditing && !showDetails && index === 0, [isEditing, showDetails, index])
  const distributionTypeLabel = useMemo(() => {
    const distributionType = billingDistributionTypes.find(option => option.value === currentValue.distributionType)
    return distributionType?.label ?? ''
  }, [currentValue.distributionType])

  const billingAccountTypeLabel = useMemo(() => {
    const billingAccountType = billingAccountTypes.find(option => option.value === currentValue.billingAccountType)
    return billingAccountType?.label ?? ''
  }, [currentValue.billingAccountType])

  const selectedBillingAccount = useMemo(() => {
    if (!currentValue.billingAccountId) {
      return null
    }
    return [...accounts, ...(defaultAccounts ?? [])].find(option => option.value === currentValue.billingAccountId)
  }, [currentValue.billingAccountId, accounts, defaultAccounts])

  if (!isEditing) {
    return (
      <Box display='flex' alignItems='center' gridGap='16px' flexWrap='wrap'>
        <div>
          <SydReadOnly className={classes.readOnly}>
            {billingAccountTypeLabel}
          </SydReadOnly>
        </div>
        <div>
          <SydReadOnly className={classes.readOnly} disabled={currentValue.billingAccountType === 'invoice'}>
            {
              currentValue.billingAccountType === 'summit'
                ? currentValue.accountName
                : currentValue.billingAccountType === 'external'
                  ? currentValue.externalBillingAccount
                  : '--'
            }
          </SydReadOnly>
        </div>
        {showDetails && (
          <>
            <Box display='flex' alignItems='center' gridGap='8px' flexBasis='100px'>
              <div>
                <SydReadOnly
                  className={classes.readOnly}
                  disabled={currentValue.distributionType === 'REMAINING'}
                  minWidth='82px'
                >
                  {
                    currentValue.distributionType === 'REMAINING'
                      ? '--'
                      : currentValue.distributionValue
                  }
                </SydReadOnly>
              </div>
              <div style={{ width: '10px' }}>
                {currentValue.distributionType === 'PERCENTAGE' ? '%' : ' '}
              </div>
            </Box>
            <div>
              <SydReadOnly className={classes.readOnly}>
                {distributionTypeLabel}
              </SydReadOnly>
            </div>
          </>
        )}
        {showSplitToggle && (
          <SydButton variant='outline' size='sm' onClick={onAddBillingAccount}>
            Split Billing
          </SydButton>
        )}
      </Box>
    )
  }

  return (
    <div>
      <Box display='flex' alignItems='center' gridGap='16px' flexWrap='wrap'>
        <Controller
          name={`billingAccounts.${index}.billingAccountType`}
          control={formMethods.control}
          render={({ field }) => (
            <SydSelect
              {...field}
              value={currentValue.billingAccountType}
              options={billingAccountTypes}
            />
          )}
        />
        {currentValue.billingAccountType === 'summit' ? (
          <Controller
            name={`billingAccounts.${index}.billingAccountId`}
            control={formMethods.control}
            render={({ field }) => (
              <SydAutocomplete
                {...field}
                value={selectedBillingAccount}
                defaultValue={selectedBillingAccount}
                onChange={(e, value) => field.onChange(value?.value ?? null)}
                onInputChange={(e, val) => onChangeAccountsQuery(val)}
                options={accounts.map(option => ({
                  ...option,
                  subLabel: option.payload.account?.accountNumber
                }))}
                defaultOptions={defaultAccounts?.map(option => ({
                  ...option,
                  subLabel: option.payload.account?.accountNumber
                }))}
                isLoading={isLoadingAccountOptions}
              />
            )}
          />
        ) : currentValue.billingAccountType === 'external' ? (
          <Controller
            name={`billingAccounts.${index}.externalBillingAccount`}
            control={formMethods.control}
            render={({ field }) => (
              <div>
                <SydInput
                  {...field}
                  value={currentValue.externalBillingAccount}
                />
              </div>
            )}
          />
        ) : currentValue.billingAccountType === 'invoice' && (
          <div>
            <SydReadOnly
              className={classes.readOnly}
              disabled
            >
              --
            </SydReadOnly>
          </div>
        )}
        {showDetails && (
          <>
            <Box display='flex' alignItems='center' gridGap='8px' flexBasis='100px'>
              <Controller
                name={`billingAccounts.${index}.distributionValue`}
                control={formMethods.control}
                render={({ field }) => (
                  <SydInput
                    {...field}
                    disabled={currentValue.distributionType === 'REMAINING'}
                    value={currentValue.distributionType === 'REMAINING' ? '--' : (currentValue.distributionValue ?? '')}
                    minWidth='80px'
                  />
                )}
              />
              <div style={{ width: '10px' }}>
                {currentValue.distributionType === 'PERCENTAGE' ? '%' : ' '}
              </div>
            </Box>
            <Controller
              name={`billingAccounts.${index}.distributionType`}
              control={formMethods.control}
              render={({ field }) => (
                <>
                  <SydSelect
                    {...field}
                    value={currentValue.distributionType}
                    options={billingDistributionTypes}
                  />
                </>
              )}
            />
          </>
        )}
        {showSplitToggle && (
          <SydButton variant='outline' size='sm' onClick={onAddBillingAccount}>
            Split Billing
          </SydButton>
        )}
        {isEditing && (
          <button
            className={classes.removeButton}
            type='button'
            onClick={() => onRemoveBillingAccount(index)}
          >
            <Icon name={ICON_NAMES.remove} margin='0 -16px' />
          </button>
        )}
      </Box>
    </div>
  )
}

AccountBillingAccountRow.propTypes = {
  index: PropTypes.number,
  isEditing: PropTypes.bool,
  showDetails: PropTypes.bool,
  onAddBillingAccount: PropTypes.func,
  onRemoveBillingAccount: PropTypes.func,
  formMethods: PropTypes.object
}

export default AccountBillingAccountRow
