import { Button, CircularProgress, FormLabel, MenuItem, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import Text from '../atoms/Text'
import { messageToUse, namesInputRules, numberInputRules, useStyles } from '../pages/admin/common'
import InputColorPicker from '../molecules/InputColorPicker'
import { CLASSIFICATION_TYPES_TITLES, TEXT_VARIANTS } from '../../constants'

function AssetClassificationForm ({
  onCancel,
  onSubmit,
  submitText,
  isSubmitLoading,
  classificationTypeOptions,
  item: classificationTagType
}) {
  const theme = useTheme()
  const classes = useStyles()
  const isInEditMode = !isEmpty(classificationTagType)

  const defaultValues = isInEditMode
    ? {
      id: classificationTagType?.id || null,
      name: classificationTagType.tagName,
      color: classificationTagType.color,
      ordinal: classificationTagType.ordinal,
      classificationType: classificationTypeOptions
        ?.find(({ value }) => value === classificationTagType.classificationTagTypeId)
        ?.value || 'none'
    }
    : { classificationType: 'none' }

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({ defaultValues })

  const handleOnSubmit = useCallback(({ id, name, ordinal, color, classificationType }) => {
    const tagType = classificationTypeOptions.find(({ value }) => value === classificationType)
    const [classificationTagTypeName] = Object.entries(CLASSIFICATION_TYPES_TITLES)
      .find(([_, value]) => value === tagType.label)

    const body = {
      id,
      ordinal,
      name: name,
      colorField: color,
      classificationType: classificationTagTypeName,
      classificationTagTypeId: tagType.value
    }
    onSubmit(body)
  }, [onSubmit, classificationTypeOptions])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={classes.form}>
      {classificationTagType?.id && <input type='hidden' {...register('id')} />}
      <div className={classes.container}>
        <div>
          <div className={classes.field}>
            <FormLabel htmlFor='classificationType' className={classes.label}>
              Classification Type
            </FormLabel>
            <Controller
              name='classificationType'
              defaultValue={defaultValues?.classificationType}
              render={({ field: { onChange } }) => (
                <TextField
                  error={Boolean(errors.userType)}
                  select
                  disabled={isSubmitLoading || isInEditMode}
                  defaultValue={defaultValues?.classificationType}
                  id='classificationType'
                  placeholder='Select Type'
                  onChange={onChange}
                  inputProps={{
                    placeholder: 'Select Type'
                  }}
                  SelectProps={{
                    classes: {
                      root: clsx({ [classes.placeholderText]: watch('classificationType') === 'none' })
                    }
                  }}
                  fullWidth
                >
                  <MenuItem value='none' disabled>
                    Select Type
                  </MenuItem>
                  {classificationTypeOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>)}
              control={control}
              rules={{
                required: true,
                validate: { menuDefaultOption: value => value !== 'none' }
              }}
            />
            {errors.classificationType && <Text text={messageToUse(errors.classificationType)} color={theme.palette.error.main} />}
          </div>
          <div className={classes.field}>
            <FormLabel htmlFor='name' className={classes.label}>
              Name
            </FormLabel>
            <TextField
              error={Boolean(errors.firstName)}
              id='name'
              placeholder='Add Name'
              inputProps={register('name', namesInputRules)}
              fullWidth
              disabled={isSubmitLoading}
            />
            {errors.name && <Text text={messageToUse(errors.name)} color={theme.palette.error.main} />}
          </div>
          <div className={classes.field}>
            <FormLabel htmlFor='color' className={classes.label}>Color</FormLabel>
            <InputColorPicker
              id='color-picker'
              fullWidth
              error={Boolean(errors.lastName)}
              inputProps={register('color', namesInputRules)}
              defaultValue={classificationTagType?.color || '#000'}
              disabled={isSubmitLoading}
            />
            {errors.color && <Text text={messageToUse(errors.color)} color={theme.palette.error.main} />}
          </div>
          <div className={classes.field}>
            <FormLabel htmlFor='ordinal' className={classes.label}>
              Ordinal
            </FormLabel>
            <TextField
              error={Boolean(errors.ordinal)}
              inputProps={{ ...register('ordinal', numberInputRules), step: 1 }}
              id='ordinal'
              placeholder='100'
              type='number'
              fullWidth
              disabled={isSubmitLoading}
            />
            {errors.ordinal && <Text text={messageToUse(errors.ordinal, numberInputRules)} color={theme.palette.error.main} />}
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            onClick={onCancel}
            disabled={isSubmitting || isSubmitLoading}
            className={clsx(classes.button, classes.cancelButton)}
          >
            <Text text='Cancel' variant={TEXT_VARIANTS.h3} />
          </Button>
          <Button
            type='submit'
            disabled={isSubmitting || isSubmitLoading}
            className={clsx(classes.button, classes.submitButton)}
          >
            {!isSubmitting && !isSubmitLoading && <Text text={submitText} variant={TEXT_VARIANTS.h3} />}
            {(isSubmitting || isSubmitLoading) && <CircularProgress color='secondary' size={18} />}
          </Button>
        </div>
      </div>
    </form>
  )
}

AssetClassificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitText: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    ordinal: PropTypes.number,
    tagName: PropTypes.string,
    classificationTagTypeId: PropTypes.number
  }),
  classificationTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  isSubmitLoading: PropTypes.bool
}

AssetClassificationForm.defaultProps = {
  submitText: 'Add',
  item: undefined,
  isSubmitLoading: false,
  classificationTypeOptions: [],
  onSubmit: noop,
  onCancel: noop
}

export default AssetClassificationForm
