import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useAddClientOverrideMutation,
  useClientBillingDetails,
  useFeeSchedule
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import Loading from '../../../../../../molecules/Loading'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import SelectBox from '../../shared/SelectBox'
import FeeScheduleTiers from '../../FeeScheduleTiers'
import { useClassificationTags } from '../../../../../../../api/coreData'

const useSubmitter = (form, onComplete, client) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: addAccountOverride } = useAddClientOverrideMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      assetClassId: +formData.assetClassId,
      feeScheduleId: +formData.feeScheduleId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await addAccountOverride(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [addAccountOverride, setProcessing, onComplete, client, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

const labelAccessor = 'longName'
const query = {
  filters: {
    classificationTagTypeId: 51
  },
  take: 500
}

function AddClassOverrideForm ({ client, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      assetClassId: '',
      feeScheduleId: ''
    }
  })

  const { data: assetClasses, isLoading: classesLoading } = useClassificationTags(query)
  const { data: billingDetails, isLoading: billingDetailsLoading } = useClientBillingDetails(client.clientId)
  const { data: feeSchedules, isLoading: feeSchedulesLoading } = useFeeSchedule()
  const { submitter, processing, error } = useSubmitter(form, onComplete, client)

  const _classes = useMemo(() => {
    const overrides = billingDetails.body?.overrides?.classOverrides || []
    return (assetClasses || []).map(a => ({
      ...a,
      disabled: overrides.some(x => x.classificationTagId === a.classificationTagId)
    }))
  }, [assetClasses, billingDetails])

  const feeScheduleId = form.watch('feeScheduleId')
  const selectedFeeSchedule = useMemo(() => {
    return (feeSchedules || []).find(x => x.feeScheduleId === feeScheduleId)
  }, [feeScheduleId, feeSchedules])

  if (billingDetailsLoading || feeSchedulesLoading || classesLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='assetClassId' control={form.control}
            render={(f => (
              <SydLabel label='Select Class' style={{ width: '50%', marginRight: '10px' }}>
                <SelectBox
                  options={_classes}
                  valueAccessor='classificationTagId'
                  labelAccessor={labelAccessor}
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
          <Controller
            name='feeScheduleId' control={form.control}
            render={(f => (
              <SydLabel label='Select Fee Schedule' style={{ width: '50%', paddingRight: '20px' }}>
                <SelectBox
                  options={feeSchedules}
                  valueAccessor='feeScheduleId'
                  labelAccessor='longName'
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
        </div>
      </div>
      {selectedFeeSchedule ? (
        <div className={classes.additionalInfo}>
          <div>{selectedFeeSchedule.longName}</div>
          <FeeScheduleTiers feeSchedule={selectedFeeSchedule} />
        </div>
      ) : <div className={classes.additionalInfo} />}
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

AddClassOverrideForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddClassOverrideForm
