import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { useAssetSearch } from '../../../../../../api/coreData'
import { assetItem, modelItem, useDialogStyles } from './common'

function EditAssetForm ({ onComplete, onCancel, item }) {
  const classes = useDialogStyles()

  const [searchText, setSearchText] = useState()
  const query = useMemo(() => {
    return {
      textSearch: {
        assetName: [{ op: 'contains', value: searchText }],
        assetIdentifier: [{ op: 'contains', value: searchText }]
      },
      take: 20
    }
  }, [searchText])
  const { data, isFetching } = useAssetSearch(query)
  const weightRef = useRef()

  return (
    <>
      <div className={classes.form}>
        <div className={classes.info}>
          <div>Current Asset</div>
          <div><span>{item.name}</span></div>
        </div>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput
              onChange={setSearchText} placeholder='Search Name / Identifier'
              style={{ height: 'unset', minHeight: '54px' }}
            />
          </SydLabel>
          <SydLabel label='Weight'>
            <SydInput ref={weightRef} type='number' minWidth='0' defaultValue={item.weight.toString()} />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : data?.length ? (
            <div className={classes.list}>
              <table className={classes.formTable}>
                <thead>
                  <tr>
                    <th>Identifier</th>
                    <th>Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(x => (
                    <tr key={x.assetId}>
                      <td>{x.assetIdentifier}</td>
                      <td>{x.assetName}</td>
                      <td>
                        <SydButton
                          size='xs' variant='primary' onClick={() => {
                            onComplete(assetItem(x, weightRef.current.value))
                          }}
                        >Add
                        </SydButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={() => {
            onComplete(modelItem(item, weightRef.current.value))
          }}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditAssetForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object
}

export default EditAssetForm
