import { noop } from 'lodash'
import { createContext, useContext, useState } from 'react'

export const AdvisorHomeContext = createContext({
  scope: null,
  setScope: noop
})

export const useAdvisorHome = () => {
  const contextValue = useContext(AdvisorHomeContext)

  return contextValue
}

export const useAdvisorHomeValues = ({ scope: initialScope }) => {
  const [scope, setScope] = useState(initialScope)
  return { scope, setScope }
}
