import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Text from '../../atoms/Text'
import AssetClassificationTypesTable from '../../molecules/AssetClassificationTypesTable'
import { useCheckPolicy, useToggle } from '../../../hooks'
import { createAssetClassificationTag, editAssetClassificationTag, getAssetClassificationTagTypes } from '../../../service'
import TableSkeleton from '../../atoms/TableSkeleton'
import RoundedButton from '../../atoms/RoundedButton'
import {
  CLASSIFICATION_TYPES_TITLES,
  MAIN_HEADER_HEIGHT,
  CSV_CELL_TYPES,
  BUTTON_SIZES,
  TEXT_ALIGNMENTS,
  TEXT_VARIANTS
} from '../../../constants'
import { ASSET_TAGS } from '../../../policies/admin'
import RightPanel from '../../organisms/RightPanel'
import AssetClassificationForm from '../../organisms/AssetClassificationForm'
import { successAlert, errorAlert, initialAlertState } from './common'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    height: '100%'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    width: '100%'
  },
  editButton: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    marginRight: '0.5rem',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  optionsContainer: {
    textAlign: 'left'
  },
  sidePanelContent: {
    padding: '0 1.875rem',
    height: `calc(100% - ${MAIN_HEADER_HEIGHT}px)`
  }
}))

const columns = [
  {
    name: 'Tag Types',
    width: '80%',
    variant: TEXT_VARIANTS.h3,
    alignment: TEXT_ALIGNMENTS.left
  },
  {
    name: 'Actions',
    width: '20%',
    variant: TEXT_VARIANTS.h3,
    alignment: TEXT_ALIGNMENTS.left
  }
]

const getAlertMessage = (entityName, isSave = true, hasError = false) =>
  !hasError
    ? `Asset classification "${entityName}" ${isSave ? 'created' : 'edited'} successfully.`
    : `There was an error trying to ${isSave ? 'save' : 'modify'} the "${entityName}" asset classification.`

const getClassificationTypeTitle = (classificationName) =>
  CLASSIFICATION_TYPES_TITLES[classificationName] || CLASSIFICATION_TYPES_TITLES.toBeClassified

function AssetClassificationSetupView () {
  const classes = useStyles()
  const canEditAssetTags = useCheckPolicy(ASSET_TAGS.editAssetTagTypes)
  const [alertState, setAlertState] = useState(initialAlertState)
  const { alertMessage, alertSeverity, openAlert } = alertState
  const [isLoading, toggleIsLoading] = useToggle()
  const [isSubmitLoading, toggleIsSubmitLoading, , toggleIsSubmitLoadingOff] = useToggle()
  const [classificationTag, setClassificationTag] = useState()
  const [shouldRefreshData, setShouldRefreshData] = useState(true)
  const [assetClassificationTagTypes, setAssetClassificationTagTypes] = useState([])
  const [isAddClassificationSideNavOpen, toggleAddClassificationSideNavOpen] = useToggle()

  useEffect(() => {
    if (!isAddClassificationSideNavOpen) {
      setClassificationTag(undefined)
    }
  }, [isAddClassificationSideNavOpen])

  useEffect(() => {
    async function fetchAssetClassificationTagTypes () {
      try {
        toggleIsLoading()
        const { data } = await getAssetClassificationTagTypes()
        setAssetClassificationTagTypes(data)
        setShouldRefreshData(false)
      } catch (err) {
        console.error(err)
      } finally {
        toggleIsLoading()
      }
    }

    if (shouldRefreshData) {
      fetchAssetClassificationTagTypes()
    }
  }, [shouldRefreshData, toggleIsLoading])

  const cellOptions = useCallback((classificationType) => {
    if (!canEditAssetTags) return null
    return (
      <span className={classes.optionsContainer}>
        <RoundedButton
          size={BUTTON_SIZES.extraSmall}
          className={classes.editButton}
          onClick={() => {
            setClassificationTag(classificationType)
            toggleAddClassificationSideNavOpen()
          }}
        >
          Edit
        </RoundedButton>
      </span>
    )
  }, [
    toggleAddClassificationSideNavOpen,
    classes.editButton,
    classes.optionsContainer,
    canEditAssetTags
  ])

  const mapClassificationTypes = useCallback((classificationTypes) => {
    return classificationTypes.reduce((acc, { name: classificationName, classificationTags }) => {
      const childrenRows = classificationTags.map((classificationTag) => [
        {
          value: classificationTag.tagName,
          alignment: TEXT_ALIGNMENTS.left,
          width: '80%',
          csvData: { type: CSV_CELL_TYPES.string }
        },
        {
          value: cellOptions(classificationTag),
          alignment: TEXT_ALIGNMENTS.left,
          width: '20%',
          csvData: { type: CSV_CELL_TYPES.string }
        }
      ])
      acc.push([
        {
          value: getClassificationTypeTitle(classificationName),
          alignment: TEXT_ALIGNMENTS.left,
          variant: TEXT_VARIANTS.subtitle2,
          colSpan: 2,
          extraStyles: { paddingTop: '3rem' },
          csvData: { type: CSV_CELL_TYPES.string }
        },
        {
          rows: childrenRows,
          isExpanded: true,
          isExpandible: true,
          hideChevron: true
        }
      ])
      return acc
    }, [])
  }, [cellOptions])

  const rows = useMemo(() =>
    mapClassificationTypes(assetClassificationTagTypes),
  [assetClassificationTagTypes, mapClassificationTypes])

  const classificationTypeOptions = useMemo(() =>
    assetClassificationTagTypes.map(({ id, name }) => ({
      label: getClassificationTypeTitle(name),
      value: id
    })), [assetClassificationTagTypes])

  const onSaveAssetClassificationTag = useCallback(async ({ id, ...body }) => {
    const isItNew = !id
    if (!canEditAssetTags) return null
    try {
      toggleIsSubmitLoading()
      await (isItNew ? createAssetClassificationTag(body) : editAssetClassificationTag(id, body))
      toggleAddClassificationSideNavOpen()
      setShouldRefreshData(true)
      setAlertState({
        ...successAlert,
        alertMessage: getAlertMessage(body.name, isItNew)
      })
    } catch (err) {
      console.error(err)
      setAlertState({
        ...errorAlert,
        alertMessage: getAlertMessage(body.name, isItNew, true)
      })
    } finally {
      toggleIsSubmitLoadingOff()
    }
  }, [toggleIsSubmitLoading, toggleIsSubmitLoadingOff, toggleAddClassificationSideNavOpen, canEditAssetTags])

  const onCloseAlertHandler = useCallback((_, reason) => {
    if (reason === 'clickaway') return
    setAlertState(initialAlertState)
  }, [])

  return (
    <div className={classes.container}>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={onCloseAlertHandler}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseAlertHandler} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <RightPanel
        showCloseButton
        title={<Text text={`${classificationTag ? 'Edit' : 'Add'} Classification`} variant={TEXT_VARIANTS.h2} />}
        open={isAddClassificationSideNavOpen}
        toggleOpen={toggleAddClassificationSideNavOpen}
        width='400px'
        height='100%'
      >
        <div className={classes.sidePanelContent}>
          <AssetClassificationForm
            item={classificationTag}
            isSubmitLoading={isSubmitLoading}
            onSubmit={onSaveAssetClassificationTag}
            onCancel={toggleAddClassificationSideNavOpen}
            submitText={classificationTag ? 'Edit' : 'Add'}
            classificationTypeOptions={classificationTypeOptions}
          />
        </div>
      </RightPanel>
      <div className={classes.titleContainer}>
        <Text text='Asset Tags' variant='h1' />
        {canEditAssetTags && (
          <RoundedButton primary size={BUTTON_SIZES.small} disabled={isLoading} onClick={toggleAddClassificationSideNavOpen}>
            Add Tag
          </RoundedButton>
        )}
      </div>
      {!isLoading && !isEmpty(rows) && !isEmpty(columns)
        ? <AssetClassificationTypesTable rows={rows} columns={columns} disableOnRowClick />
        : <TableSkeleton rows={3} columns={2} />}
    </div>
  )
}

export default AssetClassificationSetupView
