import React, { useCallback, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { useFieldArray, useForm } from 'react-hook-form'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SydButton from '../../../../commonDesign/Button'
import { useTeamMembers } from '../../../../../api/clients'
import TeamMember from './TeamMember'
import AddTeamMemberDialog from './AddTeamMemberDialog'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.dense,
    alignItems: 'center',
    height: '100%'
  }
}))

const ClientTeamForm = React.forwardRef(function ClientTeamForm ({ teamMembers }, ref) {
  const classes = useStyles()
  useTeamMembers()
  const addTeamMember = useRef()
  const { handleSubmit, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      teamMembers
    }
  })

  const { fields: teamMemberFields, move, remove, append } = useFieldArray({
    control: form.control,
    name: 'teamMembers'
  })

  useImperativeHandle(ref, () => ({
    handleSubmit
  }), [handleSubmit])

  const itemFns = useCallback((index, length) => {
    return {
      down: () => {
        const next = index + 1 >= length ? 0 : index + 1
        return move(index, next)
      },
      up: () => {
        const next = index - 1 < 0 ? length - 1 : index - 1
        return move(index, next)
      },
      top: () => move(index, 0),
      bottom: () => move(index, length - 1),
      remove: () => remove(index)
    }
  }, [move, remove])

  return (
    <>
      <Grid container spacing={1}>
        {(teamMemberFields).map((ctm, i) => {
          const fns = itemFns(i, teamMembers.length)
          return (
            <React.Fragment key={ctm.teamMemberId}>
              <Grid item xs={6}>
                <TeamMember teamMember={ctm} />
              </Grid>
              <Grid item xs={6}>
                <FadeIn className={classes.actions}>
                  <SydButton size='xs' variant='outline' onClick={fns.up}>Up</SydButton>
                  <SydButton size='xs' variant='outline' onClick={fns.down}>Down</SydButton>
                  <SydButton size='xs' priority='warning' icon='delete' onClick={fns.remove}>Remove</SydButton>
                </FadeIn>
              </Grid>
            </React.Fragment>
          )
        })}
        <Grid item xs={12}>
          <FadeIn>
            <SydButton size='xs' icon='add' variant='primary' onClick={() => addTeamMember.current.open()}>Add Team Member</SydButton>
          </FadeIn>
        </Grid>
      </Grid>
      <AddTeamMemberDialog ref={addTeamMember} onAdd={(teamMember) => append(teamMember)} />
    </>
  )
})

ClientTeamForm.propTypes = {
  teamMembers: PropTypes.array
}

export default ClientTeamForm
