import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Menu from '../../../../molecules/Menu'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import { updateBillingRun, updateBillingRunReport } from '../../../../../service'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import { BILLING_RUNS_CONTEXT_KEY, REPORT_TYPES, RUN_STATUS, RUN_STATUS_LABELS } from './helpers'

const useStyles = makeStyles(() => ({
  iconButton: {
    cursor: 'pointer'
  }
}))

const CellSettingsMenu = ({ row: { original } }) => {
  const classes = useStyles()
  const [{ refetchBillingRuns }] = useContextContainer(BILLING_RUNS_CONTEXT_KEY)

  const onChangeRunStatus = useCallback(async ({ value: { billingRunId } }, status) => {
    try {
      await updateBillingRun(billingRunId, { status })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const onUpdateBillingRunReport = useCallback(
    async ({ value: { billingRunId } }) => {
      try {
        await updateBillingRunReport(
          billingRunId,
          REPORT_TYPES.CASH_AVAILABLE_REPORT
        )
        refetchBillingRuns()
      } catch (err) {
        console.error(err)
      }
    },
    [refetchBillingRuns]
  )

  const menuOptions = useMemo(() => {
    return [
      {
        iconName: ICON_NAMES.archive,
        label: RUN_STATUS_LABELS[RUN_STATUS.ARCHIVED],
        onClick: (option) => onChangeRunStatus(option, RUN_STATUS.ARCHIVED),
        value: original
      },
      {
        iconName: ICON_NAMES.check,
        label: RUN_STATUS_LABELS[RUN_STATUS.MARK_AS_FINAL],
        onClick: (option) => onChangeRunStatus(option, RUN_STATUS.MARK_AS_FINAL),
        value: original
      },
      {
        iconName: ICON_NAMES.update,
        label: 'Update Available Cash',
        onClick: (option) => onUpdateBillingRunReport(option),
        value: original
      }
    ]
  }, [original, onChangeRunStatus, onUpdateBillingRunReport])

  return (
    <Menu options={menuOptions}>
      {({ setAnchorEl }) => (
        <div className={classes.iconButton} onClick={setAnchorEl}>
          <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' color='black' />
        </div>
      )}
    </Menu>
  )
}

CellSettingsMenu.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  })
}

export default CellSettingsMenu
