import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import {
  fetchBookmarks,
  fetchGroup,
  getWealthMissionValueDefaults,
  getWealthMissionValues,
  postNamedCommand,
  postNamedQuery
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useGroup = (groupId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['group', userId, groupId],
    queryFn: async () => {
      const { data } = await fetchGroup(groupId)

      return data
    }
  })
}

export const useAdvisor = (advisorId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['advisor', userId, advisorId],
    queryFn: async () => {
      const { data } = await fetchGroup(advisorId)
      return {
        ...data,
        profile: data?.extras ? JSON.parse(data.extras)?.profileData : null
      }
    }
  })
}

export const useGroupSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null, enabled } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchGroups, userId, query],
    queryFn: async () => {
      if (!query) {
        return null
      }
      const { data } = await postNamedQuery('levels', 'searchGroups', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useGroupTypeSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchGroupTypes, userId, query],
    queryFn: async () => {
      if (!query) {
        return null
      }
      const { data } = await postNamedQuery(
        'levels',
        'searchGroupTypes',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useBookmarksMultiple = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options

  const queryMap = useMemo(() => {
    return queries.map((query) => {
      return {
        queryKey: [QUERY_KEYS.bookmarksMultiple, userId, query.levelType, query],
        queryFn: async () => {
          const { data } = await fetchBookmarks(query)
          return data
        },
        select: mapper,
        enabled
      }
    })
  }, [enabled, mapper, queries, userId])

  const results = useQueries({
    queries: queryMap
  })

  /** React Query returns a new array every render, this helps determine us memoize the result array */
  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  const [safeResult, setSafeResult] = useState(results)
  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  return safeResult
}

export const useBookmarks = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.bookmarks, query.levelType, userId, query],
    queryFn: async () => {
      if (!query) return null
      const { data } = await fetchBookmarks(query)
      return data
    },
    select: mapper
  })
}

export const useLeadAdvisors = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.leadAdvisors, userId, query],
    queryFn: async () => {
      if (!query) return null
      const { data } = await postNamedQuery(
        'levels',
        'searchLeadAdvisors',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useLatestClientStatusReport = (clientId, format = 'tree') => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.latestClientStatusReport, userId, clientId, format],
    enabled: !!clientId,
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-latest-client-status-report', {
        clientId,
        format
      })

      return data
    }
  })
}

export const useModifyStatusReportItemStatusMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-status-report-item-status', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.latestClientStatusReport], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useWealthMissionValueDefaults = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.wealthMissionValueDefaults, userId, query],
    queryFn: async () => {
      const { data } = await getWealthMissionValueDefaults(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useWealthMissionValues = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.wealthMissionValues, userId, query],
    queryFn: async () => {
      const { data } = await getWealthMissionValues(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchStatusTemplates = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchStatusTemplates, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'search-status-templates', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useListClientStatusTemplateAssignments = (statusTemplateId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.listClientStatusTemplateAssignments, userId, statusTemplateId],
    enabled: !!statusTemplateId,
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-client-status-template-assignments', {
        statusTemplateId
      })

      return data
    }
  })
}

export const useStatusTemplate = (statusTemplateId, format = 'list') => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId, format],
    enabled: !!statusTemplateId,
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-status-template', {
        statusTemplateId,
        format
      })

      return data
    }
  })
}

export const useModifyStatusTemplateItemMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-status-template-item', command)

      return data
    },
    onSuccess: (_, command) => {
      const statusTemplateId = command.item.statusTemplateId
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useDescribeStatusTemplateItemMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'describe-status-template-item', command)

      return data
    },
    onSuccess: (_, command) => {
      const statusTemplateId = command.item.statusTemplateId
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateStatusTemplateItemMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'create-status-template-item', command)

      return data
    },
    onSuccess: (_, command) => {
      const statusTemplateId = command.item.statusTemplateId
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useRemoveStatusTemplateItemMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'remove-status-template-item', command)

      return data
    },
    onSuccess: (_, command) => {
      const statusTemplateId = command.statusTemplateId
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyStatusTemplateDetailsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-status-template-details', command)

      return data
    },
    onSuccess: (_, command) => {
      const statusTemplateId = command.statusTemplateId
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.statusTemplate, userId, statusTemplateId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateStatusTemplateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'create-status-template', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchStatusTemplates], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useRunClientStatusReportMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'run-client-status-report', command)

      return data
    }
  })
}

export const useListClientKeyDatapoints = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.listClientKeyDatapoints, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-client-key-datapoints', {
        clientId
      })
      return data
    },
    enabled
  })
}

export const useGetClientDatapointSchema = (queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getClientDatapointSchema, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-client-datapoint-schema', {
        options: {
          includeHidden: true,
          includeAutomated: true
        }
      })
      return data
    },
    enabled
  })
}

export const useGetClientDatapointValues = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getClientDatapointValues, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-client-datapoint-values', {
        clientId,
        options: {
          includeHidden: true,
          includeAutomated: true
        }
      })
      return data
    },
    enabled
  })
}

export const useModifyClientKeyDatapointValues = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-key-datapoint-values', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listClientKeyDatapoints, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientDatapointValues, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

/* Account Key Datapoints */
export const useListAccountKeyDatapoints = (accountId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.listAccountKeyDatapoints, userId, accountId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-account-key-datapoints', {
        accountId
      })
      return data
    },
    enabled
  })
}

export const useGetAccountDatapointSchema = (queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getAccountDatapointSchema, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-account-datapoint-schema', {
        options: {
          includeHidden: true,
          includeAutomated: true
        }
      })
      return data
    },
    enabled
  })
}

export const useGetAccountDatapointValues = (accountId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getAccountDatapointValues, userId, accountId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-account-datapoint-values', {
        accountId,
        options: {
          includeHidden: true,
          includeAutomated: true
        }
      })
      return data
    },
    enabled
  })
}

export const useModifyAccountKeyDatapointValues = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-account-key-datapoint-values', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listAccountKeyDatapoints, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getAccountDatapointValues, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useClientStatusTemplateAssignment = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getClientStatusTemplateAssignment, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-client-status-template-assignment', {
        clientId
      })

      return data
    },
    enabled
  })
}

export const useAssignClientStatusTemplate = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'assign-client-status-template', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchStatusTemplates, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientStatusTemplateAssignment, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyClientAccountOrdinals = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-account-ordinals', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccountsMultiple, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useListClientAccountGroupSchema = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.listClientAccountGroupSchema, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-client-account-group-schema', {
        clientId
      })
      return data
    },
    enabled
  })
}

export const useGetClientAccountGroupHierarchy = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getClientAccountGroupHierarchy, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-client-account-group-hierarchy', {
        clientId
      })
      return data
    },
    enabled
  })
}

export const useCreateClientAccountGroup = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'create-client-account-group', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientAccountGroupHierarchy, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listClientAccountGroupSchema, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useAssignClientAccountGroup = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'assign-client-account-group', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientAccountGroupHierarchy, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listClientAccountGroupSchema, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useAssignClientAccountGroupAccounts = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'assign-client-account-group-accounts', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientAccountGroupHierarchy, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listClientAccountGroupSchema, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useRenameClientAccountGroup = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'rename-client-account-group', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getClientAccountGroupHierarchy, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listClientAccountGroupSchema, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useAssignClientGroupMembership = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'assign-client-group-membership', command)

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups, userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroupTypes, userId], refetchType: 'all' }).catch(console.error)
    }
  })
}
