import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Text from '../Text'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  none: {
    textTransform: 'none'
  }
}))

function TitleSubtitle ({
  title,
  subtitle,
  titleColor,
  subtitleColor,
  titleSize,
  subtitleSize,
  fontFamily,
  fontWeight,
  capitalize
}) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Text
        text={title}
        color={titleColor}
        customFontSize={titleSize}
        customFontFamily={fontFamily}
        customFontWeight={fontWeight}
        className={classes.capitalize}
      />
      <Text
        text={subtitle}
        color={subtitleColor}
        customFontSize={subtitleSize}
        customFontFamily={fontFamily}
        customFontWeight={fontWeight}
        className={capitalize ? classes.capitalize : classes.none}
      />
    </div>
  )
}

TitleSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  titleSize: PropTypes.string,
  subtitleSize: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  capitalize: PropTypes.bool
}

TitleSubtitle.defaultProps = {
  title: '',
  subtitle: '',
  titleColor: '#141929',
  subtitleColor: '#212945',
  titleSize: '0.875rem',
  subtitleSize: '1.375rem',
  fontFamily: 'GothamPro',
  fontWeight: 'bold',
  capitalize: true
}

export default TitleSubtitle
