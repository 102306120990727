import { createContext, useContext, useMemo } from 'react'
import numeral from 'numeral'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { isString } from 'lodash'
import { formatScientificNotation } from '../../../utils/tableHelper'
import { defaultFormattingConfiguration } from './formattingConfiguration'

dayjs.extend(advancedFormat)

const nullish = [undefined, null]

const datePrefixes = ['@', '&']

const formatValue = (formattingConfiguration, value, formatString, otherOptions = null) => {
  const { options } = formattingConfiguration || {}
  if ((otherOptions?.dashEmptyString ?? options?.dashEmptyString) === true && isString(value) && value?.trim() === '') {
    return options?.dash ?? '--'
  }
  if ((otherOptions?.dashNulls ?? options?.dashNulls) === true && nullish.includes(value)) {
    return options?.dash ?? '--'
  }
  if ((otherOptions?.dashZero ?? options?.dashZero) === true && value === 0) {
    return options?.dash ?? '--'
  }
  if (formatString) {
    if (formatString === 'bool') {
      return nullish.includes(value)
        ? options?.dash
        : value ? 'Y' : 'N'
    }
    if (formatString.startsWith('@')) {
      return dayjs.utc(value).format(formatString.slice(1))
    }
    if (formatString.startsWith('&')) {
      return dayjs(value).format(formatString.slice(1))
    }
    if (formatString.startsWith('ß')) {
      return dayjs(value).fromNow(formatString.slice(1))
    }
    if (value?.toString()?.includes('e')) {
      value = formatScientificNotation({ number: value, digits: 4 })
    }
    // we should consider adding a locale to numeral to handle a 'multiplier' format
    if (formatString.endsWith('x')) {
      const rawFormatString = formatString.slice(0, -1)
      return `${numeral(value).format(rawFormatString)}x`
    }
    // fixes currency rounding issue in numeral js that tries to round small negative numbers to -0
    if (numeral(value).format(formatString).startsWith('0$')) {
      return numeral(0).format(formatString)
    }
    return numeral(value).format(formatString)
  }
  return value
}

const resolveFormatStringAlias = (formattingConfiguration, formatString) => formatString && (formatString in formattingConfiguration)
  ? formattingConfiguration[formatString]
  : formatString

const interceptFormatString = formatString => {
  if (!formatString?.length) return formatString

  if (datePrefixes.includes(formatString.at(0))) return formatString

  return formatString.includes('A')
    ? formatString.replaceAll('A', 'a')
    : formatString
}

const interceptResult = (result, formatString) => formatString?.includes('A')
  ? result.toLocaleUpperCase()
  : result

/** Creates a formatting function for use in child contexts */
const createFormatter = (formattingConfiguration) => (value, formatString, options) => {
  const fs = resolveFormatStringAlias(formattingConfiguration, formatString)
  /* We want the choice to use lowercase abbreviations or uppercase abbreviations, so we need to intercept the
     format string and do some manipulating */
  const appliedFormatString = interceptFormatString(fs)
  const result = formatValue(formattingConfiguration, value, appliedFormatString, options)
  /* If we intercepted, then manipulate the result further to capitalize */
  return interceptResult(result, fs)
}

export const FormattingContext = createContext({
  formatter: createFormatter(defaultFormattingConfiguration),
  configuration: defaultFormattingConfiguration
})

export const useFormattingContext = (overrideConfiguration) => {
  const contextValue = useContext(FormattingContext)
  return useMemo(() => {
    if (!overrideConfiguration) {
      return contextValue
    }
    // eslint-disable-next-line no-debugger
    const configuration = {
      ...(contextValue?.configuration || {}),
      ...overrideConfiguration
    }
    return {
      formatter: createFormatter(configuration),
      configuration
    }
  }, [contextValue, overrideConfiguration])
}
