import React, { useMemo } from 'react'
import { makeStyles, Chip, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import Icon from '../../atoms/Icon'
import PanelTrigger from './PanelTrigger'

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  chip: {
    marginLeft: '10px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: '20px'
  },
  description: {
    fontSize: theme.typography.fontSizes.lg
  },
  descriptionWrap: {
    '&.MuiTooltip-popper': {
      '& .MuiTooltip-tooltipPlacementTop': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .MuiTooltip-tooltipPlacementBottom': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      }
    }
  }
}))

function DisplayName ({ className, item, parent }) {
  const { formatter } = useFormattingContext()
  const hasChildren = useMemo(() => item.items?.length > 0, [item])
  const itemFactor = useMemo(() => item.factors ? formatter(item.factors.value, item.factors.format) : null, [item, formatter])

  const classes = useStyles()

  if (item.threadKey) {
    return (
      <PanelTrigger
        className={clsx(className)}
        item={item}
        parent={parent}
        disabled={hasChildren}
      >
        {item.displayName}
        {item.description ? (
          <Tooltip
            classes={{
              popper: classes.descriptionWrap
            }}
            placement='top' title={(
              <>
                <ReactMarkdown className={classes.description} children={item.description} remarkPlugins={[remarkGfm]} />
              </>
            )}
          >
            <span>
              <Icon name='info' customSize={20} />
            </span>
          </Tooltip>
        ) : null}
        {itemFactor ? (
          <Chip className={classes.chip} size='small' label={itemFactor} />
        ) : null}
      </PanelTrigger>
    )
  }

  return (
    <div className={clsx(className)}>
      {item.displayName}
      {item.description ? (
        <Tooltip
          classes={{
            popper: classes.descriptionWrap
          }}
          placement='top' title={(
            <>
              <ReactMarkdown className={classes.description} children={item.description} remarkPlugins={[remarkGfm]} />
            </>
          )}
        >
          <span>
            <Icon name='info' customSize={20} />
          </span>
        </Tooltip>
      ) : null}
      {itemFactor ? (
        <Chip className={classes.chip} size='small' label={itemFactor} />
      ) : null}
    </div>
  )
}

DisplayName.propTypes = {
  className: PropTypes.string,
  item: PropTypes.any,
  parent: PropTypes.any
}

export default React.memo(DisplayName)
