import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly'
import CloseIcon from '@material-ui/icons/Close'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CropLandscapeIcon from '@material-ui/icons/CropLandscape'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DescriptionIcon from '@material-ui/icons/Description'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import EditIcon from '@material-ui/icons/Edit'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FilterListIcon from '@material-ui/icons/FilterList'
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import HomeIcon from '@material-ui/icons/Home'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import HouseIcon from '@material-ui/icons/House'
import HistoryIcon from '@material-ui/icons/History'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NearMeIcon from '@material-ui/icons/NearMe'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'
import CallOutlinedIcon from '@material-ui/icons/CallOutlined'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import RemoveIcon from '@material-ui/icons/Remove'
import SearchIcon from '@material-ui/icons/Search'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import StopSharpIcon from '@material-ui/icons/StopSharp'
import StorageIcon from '@material-ui/icons/Storage'
import TravelExploreIcon from '@material-ui/icons/CardTravel'
import TrendingDown from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import WorkOffIcon from '@material-ui/icons/WorkOff'
import AddIcon from '@material-ui/icons/Add'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Info from '@material-ui/icons/Info'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import LanguageIcon from '@material-ui/icons/Language'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CheckIcon from '@material-ui/icons/Check'
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded'
import ArchiveIcon from '@material-ui/icons/Archive'
import UpdateIcon from '@material-ui/icons/Update'
import Security from '@material-ui/icons/Security'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Share from '@material-ui/icons/Share'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SyncIcon from '@material-ui/icons/Sync'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import BlockIcon from '@material-ui/icons/Block'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ImageIcon from '@material-ui/icons/Image'
import LinkIcon from '@material-ui/icons/Link'
import Warning from '@material-ui/icons/Warning'

// Custom Icons
import { ReactComponent as AlignLeftIcon } from '../../assets/customIcons/align-left.svg'
import { ReactComponent as CollapseIcon } from '../../assets/customIcons/collapse.svg'
import { ReactComponent as CubeIcon } from '../../assets/customIcons/cube.svg'
import { ReactComponent as BookmarkIcon } from '../../assets/customIcons/bookmark.svg'
import { ReactComponent as DatabaseIcon } from '../../assets/customIcons/database.svg'
import { ReactComponent as ExpandIcon } from '../../assets/customIcons/expand.svg'
import { ReactComponent as FeatherIcon } from '../../assets/customIcons/feather.svg'
import { ReactComponent as FolderPlusIcon } from '../../assets/customIcons/folderPlus.svg'
import { ReactComponent as HomeUserIcon } from '../../assets/customIcons/homeUser.svg'
import { ReactComponent as LayerIcon } from '../../assets/customIcons/layer.svg'
import { ReactComponent as LayersAdminIcon } from '../../assets/customIcons/layers.svg'
import { ReactComponent as PieChartIcon } from '../../assets/customIcons/pie-chart.svg'
import { ReactComponent as SuitcaseIcon } from '../../assets/customIcons/suitcase.svg'
import { ReactComponent as UserIcon } from '../../assets/customIcons/user.svg'
import { ReactComponent as UsersAdminIcon } from '../../assets/customIcons/usersAdmin.svg'
import { ReactComponent as WalletIcon } from '../../assets/customIcons/wallet.svg'
import { ReactComponent as BookIcon } from '../../assets/customIcons/book-open.svg'
import { ReactComponent as SalesforceSmallIcon } from '../../assets/customIcons/salesforceSmall.svg'
import { ReactComponent as Wealthbox } from '../../assets/customIcons/wealthbox.svg'
import { ReactComponent as WealthboxSmall } from '../../assets/customIcons/wealthbox-small.svg'
import { ReactComponent as RedtailSmall } from '../../assets/customIcons/redtail-small.svg'
import { ReactComponent as SalesforceIcon } from '../../assets/customIcons/salesforce.svg'
import { ReactComponent as FidelityIcon } from '../../assets/customIcons/integrationsFidelity.svg'
import { ReactComponent as MoneyGuideProIcon } from '../../assets/customIcons/integrationsMoneyGuidePro.svg'
import { ReactComponent as NitrogenIcon } from '../../assets/customIcons/integrationsNitrogen.svg'
import { ReactComponent as RightCapitalIcon } from '../../assets/customIcons/integrationsRightCapital.svg'
import { ReactComponent as SchwabIcon } from '../../assets/customIcons/integrationsSchwab.svg'
import { ReactComponent as RiscalyzeIcon } from '../../assets/customIcons/integrationsRiskalyze.svg'
import { ReactComponent as IRebalIcon } from '../../assets/customIcons/integrationsiRebal.svg'
import { ReactComponent as Redtail } from '../../assets/customIcons/redtail.svg'
import { ReactComponent as DSTIcon } from '../../assets/customIcons/integrationsDST.svg'
import { ReactComponent as EMoneyIcon } from '../../assets/customIcons/integrationsEMoney.svg'
import { ReactComponent as GlobalRelayIcon } from '../../assets/customIcons/integrationsGlobalRelay.svg'
import { ReactComponent as JunxureIcon } from '../../assets/customIcons/integrationsJunxure2.svg'
import { ReactComponent as MorningstarIcon } from '../../assets/customIcons/integrationsMorningstar.svg'
import { ReactComponent as SmarshIcon } from '../../assets/customIcons/integrationsSmarsh.svg'
import { ReactComponent as RIAIcon } from '../../assets/customIcons/integrationsRIA.svg'
import { ReactComponent as NoInfoIcon } from '../../assets/customIcons/no-results.svg'
import { ReactComponent as CurrencyIcon } from '../../assets/customIcons/currency.svg'
import { ReactComponent as DraftDollarIcon } from '../../assets/customIcons/draft-dollar.svg'
import { ReactComponent as DownloadIcon } from '../../assets/customIcons/download.svg'
import { ReactComponent as CustoCheckIcon } from '../../assets/customIcons/check.svg'
import { ReactComponent as LockIcon } from '../../assets/customIcons/lock.svg'
import { ReactComponent as ExportIcon } from '../../assets/customIcons/export.svg'
import { ReactComponent as ViewListConfigIcon } from '../../assets/customIcons/view_list.svg'
import { ReactComponent as DragIndicatorIcon } from '../../assets/customIcons/drag_indicator.svg'
import { ReactComponent as FileDownload } from '../../assets/customIcons/file-download.svg'
import { ReactComponent as VbsTreeView } from '../../assets/customIcons/vbs-tree-view.svg'
import { ReactComponent as VbsListView } from '../../assets/customIcons/vbs-list-view.svg'
import { ReactComponent as VbsTreeColored } from '../../assets/customIcons/vbs-tree-colored.svg'
import { ReactComponent as CircleMinus } from '../../assets/customIcons/circleMinus.svg'
import { ReactComponent as CircleMinusHover } from '../../assets/customIcons/circleMinusHover.svg'
import { ReactComponent as ResetUser } from '../../assets/customIcons/reset-user.svg'
import { ReactComponent as DeactivateUser } from '../../assets/customIcons/deactivate-user.svg'
import { ReactComponent as PaperGlassMagnifier } from '../../assets/customIcons/paperGlassMagnifier.svg'
import { ReactComponent as PaperCalendar } from '../../assets/customIcons/paperCalendar.svg'
import { ReactComponent as PaperMilestones } from '../../assets/customIcons/paperMilestones.svg'
import { ReactComponent as PaperTasks } from '../../assets/customIcons/paperTasks.svg'
import { ReactComponent as PaperBookmarkClient } from '../../assets/customIcons/paperBookmarkClient.svg'
import { ReactComponent as PaperMessageBubbles } from '../../assets/customIcons/paperMessages.svg'
import { ReactComponent as CalendarMonth } from '../../assets/customIcons/calendar_month.svg'
import { ReactComponent as BenchmarksIcon } from '../../assets/customIcons/benchmarks.svg'
import { ReactComponent as AccountsIcon } from '../../assets/customIcons/accounts.svg'
import { ReactComponent as ClientsIcon } from '../../assets/customIcons/clients.svg'
import { ReactComponent as PersonsIcon } from '../../assets/customIcons/persons.svg'
import { ReactComponent as FoldersIcon } from '../../assets/customIcons/folders.svg'
import { ReactComponent as BillIcon } from '../../assets/customIcons/bill.svg'
import { ReactComponent as DataHomeIcon } from '../../assets/customIcons/dataHome.svg'
import { ReactComponent as GoToDocument } from '../../assets/customIcons/goToDocument.svg'
import { ReactComponent as Party } from '../../assets/customIcons/party.svg'
import { ReactComponent as ChampagneGlass } from '../../assets/customIcons/champagne-glass.svg'
import { ReactComponent as WealthJourneyMilestones } from '../../assets/customIcons/wealth_journey_milestones.svg'
import { ReactComponent as BeTheChange } from '../../assets/customIcons/beTheChange.svg'
import { ReactComponent as Education } from '../../assets/customIcons/education.svg'
import { ReactComponent as LovedOnes } from '../../assets/customIcons/lovedOnes.svg'
import { ReactComponent as Travel } from '../../assets/customIcons/travel.svg'
import { ReactComponent as Vacations } from '../../assets/customIcons/vacations.svg'
import { ReactComponent as Campaign } from '../../assets/customIcons/campaign.svg'
import { ReactComponent as QueryStats } from '../../assets/customIcons/query_stats.svg'
import { ReactComponent as QuickReference } from '../../assets/customIcons/quick_reference.svg'
import { ReactComponent as Target } from '../../assets/customIcons/target.svg'
import { ReactComponent as PyramidIcon } from '../../assets/customIcons/dynamic-planning-pyramid.svg'
import { ReactComponent as RadioChecked } from '../../assets/customIcons/radio_checked.svg'

import { ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import { paletteToThemeColorStrings } from '../../utils'
import { themeConfiguration } from '../../theme'

const IconByName = {
  [ICON_NAMES.accountBalanceWallet]: WalletIcon,
  [ICON_NAMES.add]: AddIcon,
  [ICON_NAMES.addCircle]: AddCircleOutlineIcon,
  [ICON_NAMES.accountCircle]: AccountCircleIcon,
  [ICON_NAMES.alignTextLeft]: AlignLeftIcon,
  [ICON_NAMES.archive]: ArchiveIcon,
  [ICON_NAMES.arrowBack]: ArrowBackIosIcon,
  [ICON_NAMES.arrowForward]: ArrowForwardIosIcon,
  [ICON_NAMES.at]: AlternateEmailOutlinedIcon,
  [ICON_NAMES.beTheChange]: BeTheChange,
  [ICON_NAMES.bookmark]: BookmarkBorderIcon,
  [ICON_NAMES.back]: ArrowBackIcon,
  [ICON_NAMES.bank]: AccountBalanceIcon,
  [ICON_NAMES.birth]: ChildFriendlyIcon,
  [ICON_NAMES.bookmark]: BookmarkIcon,
  [ICON_NAMES.bell]: NotificationsNoneOutlinedIcon,
  [ICON_NAMES.bolt]: OfflineBoltOutlinedIcon,
  [ICON_NAMES.block]: BlockIcon,
  [ICON_NAMES.calendar]: CalendarToday,
  [ICON_NAMES.camera]: CameraAltOutlinedIcon,
  [ICON_NAMES.campaign]: Campaign,
  [ICON_NAMES.champagneGlass]: ChampagneGlass,
  [ICON_NAMES.chart]: ShowChartIcon,
  [ICON_NAMES.chat]: ChatBubbleOutlineOutlinedIcon,
  [ICON_NAMES.check]: CheckIcon,
  [ICON_NAMES.checkCircle]: CheckCircleIcon,
  [ICON_NAMES.chevronDown]: KeyboardArrowDown,
  [ICON_NAMES.chevronUp]: KeyboardArrowUpIcon,
  [ICON_NAMES.chevronRight]: KeyboardArrowRight,
  [ICON_NAMES.chevronLeft]: ChevronLeftIcon,
  [ICON_NAMES.circle]: FiberManualRecordIcon,
  [ICON_NAMES.clock]: QueryBuilderOutlinedIcon,
  [ICON_NAMES.close]: CloseIcon,
  [ICON_NAMES.closeCircle]: HighlightOffIcon,
  [ICON_NAMES.cloud]: CloudQueueIcon,
  [ICON_NAMES.cloudUpload]: CloudUploadIcon,
  [ICON_NAMES.collapse]: CollapseIcon,
  [ICON_NAMES.cropLandscape]: CropLandscapeIcon,
  [ICON_NAMES.cube]: CubeIcon,
  [ICON_NAMES.database]: DatabaseIcon,
  [ICON_NAMES.delete]: DeleteOutlineIcon,
  [ICON_NAMES.document]: DescriptionIcon,
  [ICON_NAMES.dollar]: AttachMoneyIcon,
  [ICON_NAMES.down]: ArrowDownwardIcon,
  [ICON_NAMES.download]: GetAppIcon,
  [ICON_NAMES.draftDollar]: DraftDollarIcon,
  [ICON_NAMES.dragIndicator]: DragIndicatorIcon,
  [ICON_NAMES.edit]: EditIcon,
  [ICON_NAMES.education]: Education,
  [ICON_NAMES.expand]: ExpandIcon,
  [ICON_NAMES.feather]: FeatherIcon,
  [ICON_NAMES.file]: DescriptionOutlinedIcon,
  [ICON_NAMES.fileDownload]: FileDownload,
  [ICON_NAMES.filters]: FilterListIcon,
  [ICON_NAMES.folder]: FolderOutlinedIcon,
  [ICON_NAMES.folderPlus]: FolderPlusIcon,
  [ICON_NAMES.gift]: CardGiftcardIcon,
  [ICON_NAMES.group]: GroupOutlinedIcon,
  [ICON_NAMES.globalNetwork]: LanguageIcon,
  [ICON_NAMES.goToDocument]: GoToDocument,
  [ICON_NAMES.homeIcon]: HomeIcon,
  [ICON_NAMES.home]: HouseIcon,
  [ICON_NAMES.homeOutlined]: HomeOutlinedIcon,
  [ICON_NAMES.homeUser]: HomeUserIcon,
  [ICON_NAMES.history]: HistoryIcon,
  [ICON_NAMES.image]: ImageIcon,
  [ICON_NAMES.info]: Info,
  [ICON_NAMES.infoOutlined]: InfoOutlined,
  [ICON_NAMES.layer]: LayerIcon,
  [ICON_NAMES.layers]: LayersAdminIcon,
  [ICON_NAMES.link]: LinkIcon,
  [ICON_NAMES.list]: FormatListBulletedIcon,
  [ICON_NAMES.listAlt]: ListAltIcon,
  [ICON_NAMES.lovedOnes]: LovedOnes,
  [ICON_NAMES.mail]: MailOutlineIcon,
  [ICON_NAMES.moon]: Brightness2OutlinedIcon,
  [ICON_NAMES.navigateNext]: NavigateNextIcon,
  [ICON_NAMES.navigatePrevious]: NavigateBeforeIcon,
  [ICON_NAMES.near]: NearMeIcon,
  [ICON_NAMES.party]: Party,
  [ICON_NAMES.pieChart]: PieChartIcon,
  [ICON_NAMES.phone]: CallOutlinedIcon,
  [ICON_NAMES.queryStats]: QueryStats,
  [ICON_NAMES.quickReference]: QuickReference,
  [ICON_NAMES.radioUnchecked]: RadioButtonUncheckedIcon,
  [ICON_NAMES.remove]: RemoveIcon,
  [ICON_NAMES.removeCircle]: RemoveCircleOutlineIcon,
  [ICON_NAMES.savings]: AttachMoneyIcon,
  [ICON_NAMES.search]: SearchIcon,
  [ICON_NAMES.smiley]: InsertEmoticonIcon,
  [ICON_NAMES.square]: StopSharpIcon,
  [ICON_NAMES.storage]: StorageIcon,
  [ICON_NAMES.suitcase]: SuitcaseIcon,
  [ICON_NAMES.sync]: SyncIcon,
  [ICON_NAMES.target]: Target,
  [ICON_NAMES.travelExplore]: TravelExploreIcon,
  [ICON_NAMES.threeDots]: MoreHorizIcon,
  [ICON_NAMES.trendingDown]: TrendingDown,
  [ICON_NAMES.trendingFlat]: TrendingFlatIcon,
  [ICON_NAMES.trendingUp]: TrendingUpIcon,
  [ICON_NAMES.user]: UserIcon,
  [ICON_NAMES.usersAdmin]: UsersAdminIcon,
  [ICON_NAMES.unfoldLess]: UnfoldLessIcon,
  [ICON_NAMES.unfoldMore]: UnfoldMoreIcon,
  [ICON_NAMES.up]: ArrowUpwardIcon,
  [ICON_NAMES.update]: UpdateIcon,
  [ICON_NAMES.vertDots]: MoreVertIcon,
  [ICON_NAMES.viewListConfig]: ViewListConfigIcon,
  [ICON_NAMES.work]: WorkOutlineIcon,
  [ICON_NAMES.workOff]: WorkOffIcon,
  [ICON_NAMES.book]: BookIcon,
  [ICON_NAMES.salesforceSmall]: SalesforceSmallIcon,
  [ICON_NAMES.salesforce]: SalesforceIcon,
  [ICON_NAMES.wealthbox]: Wealthbox,
  [ICON_NAMES.wealthboxSmall]: WealthboxSmall,
  [ICON_NAMES.wealthJourneyMilestones]: WealthJourneyMilestones,
  [ICON_NAMES.redtailSmall]: RedtailSmall,
  [ICON_NAMES.redtail]: Redtail,
  [ICON_NAMES.wealthbox]: Wealthbox,
  [ICON_NAMES.schwab]: SchwabIcon,
  [ICON_NAMES.fidelity]: FidelityIcon,
  [ICON_NAMES.moneyGuidePro]: MoneyGuideProIcon,
  [ICON_NAMES.rightCapital]: RightCapitalIcon,
  [ICON_NAMES.nitrogen]: NitrogenIcon,
  [ICON_NAMES.riskalyze]: RiscalyzeIcon,
  [ICON_NAMES.dst]: DSTIcon,
  [ICON_NAMES.eMoney]: EMoneyIcon,
  [ICON_NAMES.globalRelay]: GlobalRelayIcon,
  [ICON_NAMES.junxure]: JunxureIcon,
  [ICON_NAMES.morningstar]: MorningstarIcon,
  [ICON_NAMES.smarsh]: SmarshIcon,
  [ICON_NAMES.iRebal]: IRebalIcon,
  [ICON_NAMES.ria]: RIAIcon,
  [ICON_NAMES.accountTreeRounded]: AccountTreeRoundedIcon,
  [ICON_NAMES.noInfo]: NoInfoIcon,
  [ICON_NAMES.currency]: CurrencyIcon,
  [ICON_NAMES.downloadView]: DownloadIcon,
  [ICON_NAMES.customCheck]: CustoCheckIcon,
  [ICON_NAMES.location]: MyLocationIcon,
  [ICON_NAMES.lock]: LockIcon,
  [ICON_NAMES.arrowDown]: ArrowDropDownIcon,
  [ICON_NAMES.logout]: ExitToAppOutlined,
  [ICON_NAMES.settings]: SettingsIcon,
  [ICON_NAMES.security]: Security,
  [ICON_NAMES.export]: ExportIcon,
  [ICON_NAMES.fileCopy]: FileCopyIcon,
  [ICON_NAMES.share]: Share,
  [ICON_NAMES.vacations]: Vacations,
  [ICON_NAMES.vbsTreeView]: VbsTreeView,
  [ICON_NAMES.vbsListView]: VbsListView,
  [ICON_NAMES.vbsTreeColored]: VbsTreeColored,
  [ICON_NAMES.visibility]: VisibilityIcon,
  [ICON_NAMES.circleMinus]: CircleMinus,
  [ICON_NAMES.circleMinusHover]: CircleMinusHover,
  [ICON_NAMES.deactivateUser]: DeactivateUser,
  [ICON_NAMES.resetUser]: ResetUser,
  [ICON_NAMES.calendarMonth]: CalendarMonth,
  [ICON_NAMES.paperGlassMagnifier]: PaperGlassMagnifier,
  [ICON_NAMES.paperCalendar]: PaperCalendar,
  [ICON_NAMES.paperMilestones]: PaperMilestones,
  [ICON_NAMES.paperTasks]: PaperTasks,
  [ICON_NAMES.paperBookmarkClient]: PaperBookmarkClient,
  [ICON_NAMES.paperMessageBubbles]: PaperMessageBubbles,
  [ICON_NAMES.accounts]: AccountsIcon,
  [ICON_NAMES.clients]: ClientsIcon,
  [ICON_NAMES.persons]: PersonsIcon,
  [ICON_NAMES.tag]: LocalOfferOutlinedIcon,
  [ICON_NAMES.travel]: Travel,
  [ICON_NAMES.folders]: FoldersIcon,
  [ICON_NAMES.bill]: BillIcon,
  [ICON_NAMES.dataHome]: DataHomeIcon,
  [ICON_NAMES.benchmarks]: BenchmarksIcon,
  [ICON_NAMES.pyramid]: PyramidIcon,
  [ICON_NAMES.radioChecked]: RadioChecked,
  [ICON_NAMES.warning]: Warning
}

const useStyles = makeStyles((theme) => ({
  icon: ({
    color,
    themeColor,
    variant,
    roundedBackground,
    customSize,
    customHeight,
    padding,
    margin,
    customColor
  }) => {
    const fontSize = customSize || (variant ? theme.typography[variant]?.fontSize : 'unset')
    return {
      ...(color && { color }),
      ...(themeColor && { color: get(theme.palette, themeColor) }),
      fontSize: fontSize || 'unset',
      width: fontSize || 'unset',
      maxHeight: fontSize || 'unset',
      height: customHeight || fontSize,
      margin: margin,
      overflow: 'visible',
      ...(roundedBackground && {
        background: roundedBackground,
        boxSizing: 'content-box',
        borderRadius: '50%',
        padding: padding || '5px'
      }),
      '& > path': {
        fill: get(theme.palette, customColor)
      }
    }
  }
}))

function Icon ({
  additionalClasses,
  color,
  customSize,
  customHeight,
  margin,
  name,
  padding,
  roundedBackground,
  themeColor,
  variant,
  customColor
}) {
  const classes = useStyles({
    color,
    variant,
    roundedBackground,
    themeColor,
    customSize,
    customHeight,
    margin,
    padding,
    customColor
  })
  const IconComponent = useMemo(() => IconByName[name], [name])
  if (!IconComponent) return null
  return <IconComponent className={clsx(classes.icon, additionalClasses)} />
}

Icon.propTypes = {
  additionalClasses: PropTypes.string,
  color: PropTypes.string,
  customSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roundedBackground: PropTypes.string,
  themeColor: PropTypes.oneOf(paletteToThemeColorStrings(themeConfiguration.palette)),
  variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  name: PropTypes.oneOf(Object.values(ICON_NAMES)).isRequired,
  padding: PropTypes.string,
  customColor: PropTypes.oneOf(paletteToThemeColorStrings(themeConfiguration.palette))
}

Icon.defaultProps = {
  additionalClasses: '',
  color: '',
  customSize: '',
  customHeight: undefined,
  margin: '',
  roundedBackground: undefined,
  themeColor: undefined,
  variant: TEXT_VARIANTS.body1,
  padding: undefined,
  customColor: undefined
}

export default Icon
