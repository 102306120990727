import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { Box } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Text from '../atoms/Text'
import {
  INTERNAL_DATE_FORMAT,
  DATE_FORMAT,
  DAYJS_UNIT_TYPES
} from '../../constants'
import { disableWeekends } from '../../utils'

const defaultMinDate = dayjs().format(DATE_FORMAT)
const defaultMaxDate = dayjs()
  .add(1, DAYJS_UNIT_TYPES.MONTH)
  .format(DATE_FORMAT)

function DateRangePicker ({
  datePickerWidth,
  minDate,
  maxDate,
  defaultStartDate,
  defaultEndDate,
  onChange,
  format,
  fullWidth,
  clearable,
  startDateLabel,
  endDateLabel,
  InputProps,
  divider,
  className,
  shouldDisableWeekends,
  ...props
}) {
  const [startDateValue, setStartDateValue] = useState(defaultStartDate)
  const [endDateValue, setEndDateValue] = useState(defaultEndDate)

  const onDateChange = useCallback(
    (startValue, endValue) => {
      const startDate = dayjs(startValue)
      const endDate = dayjs(endValue)

      const startDateFormatted = startDate.format(INTERNAL_DATE_FORMAT)
      const endDateFormatted = endDate.format(INTERNAL_DATE_FORMAT)

      setStartDateValue(startDateFormatted)
      setEndDateValue(endDateFormatted)

      if (startDate.isValid() && endDate.isValid()) {
        onChange(startDateFormatted, endDateFormatted)
      }
    },
    [onChange]
  )

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='right'
      alignItems='baseline'
      position='relative'
    >
      <Box width={datePickerWidth || '10rem'}>
        <KeyboardDatePicker
          InputProps={InputProps}
          fullWidth={fullWidth}
          clearable={clearable}
          className={className}
          format={format}
          label={startDateLabel}
          value={startDateValue}
          defaultValue={defaultStartDate}
          placeholder={format}
          minDate={dayjs(minDate).valueOf()}
          maxDate={dayjs(endDateValue || maxDate).valueOf()}
          onChange={(date) => onDateChange(date, endDateValue)}
          {...(shouldDisableWeekends
            ? { shouldDisableDate: disableWeekends }
            : {})}
        />
      </Box>
      {divider || (
        <Box mx='1rem'>
          <Text text='to' customFontSize='0.875rem' />
        </Box>
      )}
      <Box width={datePickerWidth || '10rem'}>
        <KeyboardDatePicker
          InputProps={InputProps}
          fullWidth={fullWidth}
          clearable={clearable}
          className={className}
          format={format}
          value={endDateValue}
          defaultValue={defaultEndDate}
          label={endDateLabel}
          placeholder={format}
          minDate={dayjs(startDateValue || minDate).valueOf()}
          maxDate={dayjs(maxDate).valueOf()}
          onChange={(date) => onDateChange(startDateValue, date)}
          {...(shouldDisableWeekends
            ? { shouldDisableDate: disableWeekends }
            : {})}
          {...props}
        />
      </Box>
    </Box>
  )
}

DateRangePicker.propTypes = {
  datePickerWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  fullWidth: PropTypes.bool,
  clearable: PropTypes.bool,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.string,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  shouldDisableWeekends: PropTypes.bool,
  InputProps: PropTypes.object,
  divider: PropTypes.node,
  className: PropTypes.string
}

DateRangePicker.defaultProps = {
  datePickerWidth: undefined,
  minDate: defaultMinDate,
  maxDate: defaultMaxDate,
  fullWidth: false,
  clearable: false,
  defaultStartDate: undefined,
  defaultEndDate: undefined,
  onChange: noop,
  format: DATE_FORMAT,
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  shouldDisableWeekends: false,
  InputProps: undefined,
  divider: undefined,
  className: undefined
}

export default DateRangePicker
