import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import EmptySection from '../../../../atoms/EmptySection'
import AccountsContext from './AccountsContext'
import AccountsTable from './table'
import AccountsTableWrapper from './tableWrapper'
import { ADMIN_ACCOUNTS_PATH, defaultColumnConfig } from './helpers'
import AccountsHeader from './header'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem'
  }
}))

const AccountsView = () => {
  const history = useHistory()
  const classes = useStyles()
  const canViewAccounts = useCheckPolicy(ACCOUNTS.viewAccounts)

  const onClickRow = useCallback(
    (row) => {
      if (row.original) {
        history.push(`${ADMIN_ACCOUNTS_PATH}/${row.original.accountId}`)
      }
    },
    [history]
  )

  if (!canViewAccounts) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <div className={classes.container}>
      <AccountsContext defaultColumnConfig={defaultColumnConfig}>
        <AccountsTableWrapper>
          <AccountsTable header={<AccountsHeader />} onClickRow={onClickRow} />
        </AccountsTableWrapper>
      </AccountsContext>
    </div>
  )
}

AccountsView.propTypes = {}

export default AccountsView
