import { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useAppContext } from '../../../redux/slices/appContext'
import { useAllocationVsTargetQueryMultiple } from '../../../api/rebalancer'

/**
 * Transforms a list of groupings ['client', 'accountObjective', 'account']
 * into a list of groupings, grouped by depth - used for requesting to core data
 * example output: [['client'], ['client', 'accountObjective'], ['client', 'accountObjective', 'account']
 * @param {[]} groupings
 * @param {number} except - except for the first n groupings
 */
const extractLevelGroupings = (groupings, except = 0) => {
  const result = []
  for (let i = except; i < groupings.length; ++i) {
    const inner = []
    for (let j = 0; j <= i; ++j) {
      inner.push(groupings[j])
    }
    result.push(inner)
  }

  return result
}

export const updateTreeItemByIndexPath = (tree, initialNodeIndexPath = [], value = {}) => {
  const nodeIndexPath = [...initialNodeIndexPath]
  const nodeIndex = nodeIndexPath.shift()
  if (nodeIndex === undefined) return tree
  if (isEmpty(tree)) return tree

  const node = tree[nodeIndex] || [{}]
  return [
    ...tree.slice(0, nodeIndex),
    {
      ...node,
      subRows: updateTreeItemByIndexPath(
        node.subRows,
        [...nodeIndexPath],
        value
      ),
      ...(nodeIndexPath.length === 0 ? { ...value } : {})
    },
    ...tree.slice(nodeIndex + 1)
  ]
}

/** Recursively searches for an item at a particular depth in a tree */
const findTreeItem = (tree, { depth, uniqueId }, itemIndexPath = []) => {
  const maybeItem = tree?.find(x => uniqueId.startsWith(x.uniqueId))
  if (!maybeItem) return null
  itemIndexPath.push(tree.indexOf(maybeItem))
  if (maybeItem.depth === depth) return { item: maybeItem, itemIndexPath }
  return findTreeItem(maybeItem.subRows, { depth, uniqueId }, itemIndexPath)
}

const findAndUpdateTreeItem = (prevTree, item, itemOverrides = {}) => {
  // unlike performance table, items here might not have children
  const findResult = findTreeItem(prevTree, item)
  if (!findResult) {
    item._next = null
    return prevTree
  }
  const { itemIndexPath } = findResult
  const prevTreeModified = updateTreeItemByIndexPath(prevTree, itemIndexPath, itemOverrides)
  return prevTreeModified
}

const traverseAndUpdateTree = (tree, item) => {
  const treeModified = tree.map(node => ({
    ...node,
    ...item,
    subRows: node.subRows ? traverseAndUpdateTree(node.subRows, item) : []
  }))
  return treeModified
}

/**
 * Recursively maps a flattened list of items into a tree-like structure
 */
const mapSubRows = ({
  parent,
  items,
  allItems,
  depth = 1,
  createRequestForGrouping,
  levelGroupings
}) => {
  if (depth > 10) return []
  return items.reduce((prev, cur) => {
    const nextDepth = depth + 1
    const children = allItems.filter(x => x.depth === (depth + 1) && x.uniqueId.startsWith(cur.uniqueId))

    const maxDepth = levelGroupings.length
    const additionalFilters = {
      ...(parent?._next?.filters || {})
    }
    const _next = nextDepth >= maxDepth ? null : createRequestForGrouping(levelGroupings[nextDepth], {
      ...additionalFilters,
      [`${cur.levelType}Id`]: [cur.levelId]
    })

    cur._next = _next
    const subRows = mapSubRows({
      parent: cur,
      items: children,
      allItems,
      depth: nextDepth,
      createRequestForGrouping,
      levelGroupings
    })

    if (subRows?.length === 0) {
      cur._next = null
    }
    prev.push({
      ...cur,
      subRows,
      _subRowsFetched: !!subRows?.length
    })
    return prev
  }, [])
}

const compareDepth = (a, b) => b.depth - a.depth

export const useTargetAllocationTreeData = ({
  defaultFilter,
  grouping,
  compareTo,
  allocationGrouping,
  allocator,
  initialDepth = 3,
  initialExpandDepth = 3
}) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const levelGroupings = useMemo(() => extractLevelGroupings(grouping), [grouping])

  const createRequestForGrouping = useCallback((levelGrouping, additionalFilters = undefined) => {
    return {
      levelTypes: levelGrouping,
      allocator,
      allocationGrouping,
      compareTo,
      filters: {
        clientId,
        ...(defaultFilter || {}),
        ...(additionalFilters || {})
      },
      dateRange: {
        startDate: availableDates.mainDate,
        endDate: availableDates.mainDate
      }
    }
  }, [allocator, allocationGrouping, compareTo, clientId, availableDates, defaultFilter])

  /**
   * Create requests for up to [initialDepth] levels for the performance table
   */
  const baseQueries = useMemo(() => {
    if (loadingAvailableDates) return null

    // Get the groupings for the first two levels at most. This should be safe if there is only one level
    const requestBase = levelGroupings.slice(0, initialDepth)
    return requestBase.map(levelGrouping => createRequestForGrouping(levelGrouping))
  }, [levelGroupings, loadingAvailableDates, createRequestForGrouping, initialDepth])

  /** Get the results for all the queries */
  const { results, fetchMore } = useAllocationVsTargetQueryMultiple(baseQueries, {
    enabled: !!baseQueries
  })

  const isAnyLevelLoading = useMemo(() =>
    results.reduce((aggregate, { isLoading }) => aggregate || isLoading, false),
  [results])

  /** Link the initial query results into a tree shape */
  const treeLinkedData = useMemo(() => {
    if (isAnyLevelLoading) return []

    const maxDepth = levelGroupings.length - 1
    // flatten the results, applying the depth to the items
    const flattenedResults = results.map(x => x.data).reduceRight((previousValue, currentValue, currentIndex) => {
      const mapped = currentValue.allocation.map(item => {
        const additionalFilters = {
          [`${item.levelType}Id`]: [item.levelId]
        }
        const _next = currentIndex >= maxDepth ? null : createRequestForGrouping(levelGroupings[currentIndex + 1], additionalFilters)
        return ({
          ...item,
          depth: currentIndex,
          expanded: currentIndex < initialExpandDepth - 1,
          _next
        })
      })
      return [...previousValue, ...mapped]
    }, []).sort(compareDepth)

    // this algorithm probably sucks
    const linkedResults = flattenedResults.reduce((previousValue, currentValue, currentIndex, array) => {
      if (currentValue.depth > 0) return previousValue

      const children = array.filter(x => x.uniqueId.startsWith(currentValue.uniqueId))
      const nextLevel = children.filter(x => x.depth === 1)
      currentValue.subRows = mapSubRows({
        parent: currentValue,
        items: nextLevel,
        allItems: children,
        depth: 1,
        createRequestForGrouping,
        levelGroupings,
        maxDepth
      })

      currentValue._subRowsFetched = !!currentValue.subRows?.length
      previousValue.push(currentValue)
      return previousValue
    }, [])

    return linkedResults
  }, [results, isAnyLevelLoading, createRequestForGrouping, levelGroupings, initialExpandDepth])

  /** We are storing the initial tree results in state, and will be modifying the tree on callbacks */
  const [statefulResult, setStatefulResult] = useState([])
  useEffect(() => {
    setStatefulResult(treeLinkedData)
  }, [setStatefulResult, treeLinkedData])

  /** Callback for expanding the table or fetching more data */
  const onLevelExpand = useCallback(async ({ original: item }) => {
    const nextQuery = item?._next
    // There is no next level to expand to in this case
    if (!nextQuery) return
    if (item?._subRowsFetched) {
      setStatefulResult(prevState => {
        return findAndUpdateTreeItem(prevState, item, {
          expanded: !item.expanded
        })
      })
      return
    }

    // Set loading state
    setStatefulResult(prevState => {
      return findAndUpdateTreeItem(prevState, item, {
        _subRowsFetching: true
      })
    })

    try {
      // Request more data from core data
      const moreData = await fetchMore(nextQuery)
      const subRows = moreData?.allocation
      const nextDepth = item.depth + 1
      const maxDepth = levelGroupings.length - 1
      const mappedDetails = (subRows || []).map(subDetail => {
        let _next = nextDepth === maxDepth ? null : createRequestForGrouping(levelGroupings[nextDepth + 1], {
          [`${subDetail.levelType}Id`]: [subDetail.levelId]
        })

        if (_next) {
          // aggregate any previous filter that we may be missing
          _next = {
            ..._next,
            filters: {
              ...item._next.filters,
              ..._next.filters
            }
          }
        }

        return {
          ...subDetail,
          depth: nextDepth,
          expanded: false,
          _next
        }
      })

      // Set final state
      setStatefulResult(prevState => {
        const prevStateModified = findAndUpdateTreeItem(prevState, item, {
          _subRowsFetching: false,
          _subRowsFetched: true,
          expanded: true,
          subRows: mappedDetails || [],
          _next: mappedDetails?.length > 0 || null
        })
        return [...prevStateModified]
      })
    } catch (error) {
      console.error(error)
      setStatefulResult(prevState => {
        return findAndUpdateTreeItem(prevState, item, {
          _subRowsFetching: false
        }) // do not need to apply allocation as state has not really changed
      })
    }
  }, [setStatefulResult, fetchMore, levelGroupings, createRequestForGrouping])

  const onExpandLevels = useCallback((expanded) => {
    setStatefulResult((prevState) => {
      return traverseAndUpdateTree(prevState, { expanded: Boolean(expanded) })
    })
  }, [])

  return {
    data: statefulResult ?? [],
    isLoading: isAnyLevelLoading,
    onLevelExpand,
    onExpandLevels
  }
}
