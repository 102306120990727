import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Button } from '@material-ui/core'
import PillButtonGroup from '../../../molecules/PillButtonGroup'
import RelativeDateSelect, { useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import AvailableDatePicker from '../../../molecules/AvailableDatePicker'
import { useDates } from '../../../../hooks/useDates'
import Select from '../../../molecules/Select'
import { TAX_STATUS_TYPES } from '../../../../constants'
import CostBasisTable from './CostBasisTable'
import CostBasisSummary from './CostBasisSummary'

const useCostBasisFilter = (defaultCostBasisType) => {
  const [costBasisType, setCostBasisType] = useState(defaultCostBasisType)
  const onSelectCostBasisType = useCallback(cbType => {
    setCostBasisType(cbType)
  }, [setCostBasisType])

  return {
    costBasisType,
    onSelectCostBasisType
  }
}

const useSelectedDateRange = (defaultDateRange, availableDates) => {
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange)
  const { dateRange } = useRelativeDateRange(selectedDateRange, availableDates)
  return {
    selectedDateRange,
    setSelectedDateRange,
    dateRange
  }
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    zIndex: 1
  },
  costBasisView: {
    padding: 0,
    position: 'relative'
  },
  costBasisViewHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem'
  },
  footer: {
    minHeight: '80px'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *:first-child': {
      flex: '1 1 auto'
    },
    '& > *:not(:last-child)': {
      marginRight: '1rem'
    }
  },
  topHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    placeItems: 'center'
  },
  dateControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& input': {
      paddingBottom: 0
    },
    '& > *': {
      animation: '$stepIn .25s linear'
    }
  },
  '@keyframes stepIn': {
    '0%': {
      width: 0,
      opacity: 0,
      transform: 'scale(0, 1)',
      transformOrigin: 'left'
    },
    '100%': {
      width: 'auto',
      opacity: 1,
      transform: 'scale(1, 1)',
      transformOrigin: 'left'
    }
  },
  clearButton: {
    color: '#D44333',
    width: '100%',
    fontWeight: 'bold',
    padding: '0.75rem 1rem',
    justifyContent: 'flex-start',
    textTransform: 'capitalize'
  }
}))

const DEFAULT_FORMAT_VALUE = {
  default: 'marketValue',
  percentGainLoss: 'percentage',
  levelName: 'title'
}

const costBasisTypeMap = {
  unrealized: 'Unrealized',
  realized: 'Realized',
  combined: 'Combined'
}

const normalizeDefaultTaxStatus = (defaultTaxStatus) => {
  if (!defaultTaxStatus) return null
  return defaultTaxStatus?.toString()
}

const CostBasisView = ({
  defaultCostBasisType,
  defaultDateRange,
  defaultFilter,
  defaultTaxStatus,
  tableFormat,
  costBasisTypes,
  allowUnrealized,
  allowRealized,
  allowCombined,
  allowedTaxStatuses,
  summaryTilesConfig,
  dateRangeOptions
}) => {
  const {
    availableDates,
    loadingAvailableDates,
    onAvailableDateChange
  } = useDates()
  const { costBasisType, onSelectCostBasisType } = useCostBasisFilter(defaultCostBasisType)
  const { selectedDateRange, setSelectedDateRange, dateRange } = useSelectedDateRange(defaultDateRange, availableDates)
  const [taxStatusId, setTaxStatusId] = useState(
    normalizeDefaultTaxStatus(defaultTaxStatus)
  )
  const classes = useStyles()

  const onTaxFilterChange = useCallback((selectedValue) => {
    setTaxStatusId(selectedValue)
  }, [])

  const renderOptionsFooter = useMemo(() => {
    return (
      <Button
        className={classes.clearButton}
        onClick={() => setTaxStatusId(null)}
      >
        Clear
      </Button>
    )
  }, [classes.clearButton])

  const taxStatusOptions = useMemo(() => {
    return Object.entries(TAX_STATUS_TYPES)
      .filter(([key, value]) => {
        if (!allowedTaxStatuses) {
          return true
        }
        return (
          allowedTaxStatuses.includes(key) || allowedTaxStatuses.includes(value)
        )
      })
      .map(([key, value]) => ({
        label: value,
        value: key
      }))
  }, [allowedTaxStatuses])

  return (
    <div className={classes.costBasisView}>
      <CostBasisTable.Wrapper>
        <CostBasisTable.SuperHeader className={classes.tableHeader}>
          <div className={classes.topHeader}>
            <div className={classes.dateControls}>
              {costBasisType === 'unrealized' ? null : (
                <RelativeDateSelect
                  options={dateRangeOptions}
                  availableDates={availableDates}
                  selectedValue={selectedDateRange}
                  onDateRangeSelected={setSelectedDateRange}
                />
              )}
              <AvailableDatePicker
                value={availableDates.mainDate}
                onChange={onAvailableDateChange}
                format='[As of] MMM DD, YYYY'
                type='date'
                extraStyles={{
                  fontSize: '0.875rem',
                  cursor: 'pointer',
                  display: 'block'
                }}
                disableFuture
              />
            </div>
            <PillButtonGroup
              selectedValue={costBasisType}
              onSelect={onSelectCostBasisType}
            >
              {(costBasisTypes || []).filter(x => {
                if (x === 'unrealized' && allowUnrealized) return true
                if (x === 'realized' && allowRealized) return true
                if (x === 'combined' && allowCombined) return true
                return false
              }).map(t => (
                <PillButtonGroup.Option key={t} value={t}>
                  {costBasisTypeMap[t]}
                </PillButtonGroup.Option>
              ))}
            </PillButtonGroup>
            <div>&nbsp;</div>
          </div>
        </CostBasisTable.SuperHeader>
        <div className={classes.costBasisViewHeader}>
          <CostBasisSummary
            defaultFilter={defaultFilter}
            costBasisType={costBasisType}
            dateRange={dateRange}
            enabled={!loadingAvailableDates}
            taxStatusId={taxStatusId}
            format={summaryTilesConfig.format}
            tilesVisibility={summaryTilesConfig.visibility}
            summaryFormatConfiguration={summaryTilesConfig.formatConfiguration}
          />
        </div>
        <Box>
          <Select
            placeholder='Tax Status'
            value={taxStatusId}
            options={taxStatusOptions}
            onChange={onTaxFilterChange}
            optionsFooter={renderOptionsFooter}
            showCheckMarOnSelectedItems
          />
        </Box>
        <CostBasisTable
          defaultFilter={defaultFilter}
          costBasisType={costBasisType}
          enabled={!loadingAvailableDates}
          dateRange={dateRange}
          format={tableFormat}
          taxStatusId={taxStatusId}
        />
      </CostBasisTable.Wrapper>
      <div className={classes.footer} />
    </div>
  )
}

CostBasisView.propTypes = {
  defaultCostBasisType: PropTypes.oneOf(['realized', 'unrealized', 'combined']),
  defaultDateRange: PropTypes.string,
  defaultFilter: PropTypes.object,
  tableFormat: PropTypes.object,
  costBasisTypes: PropTypes.arrayOf(PropTypes.oneOf(['unrealized', 'realized', 'combined'])),
  allowUnrealized: PropTypes.bool,
  allowRealized: PropTypes.bool,
  allowCombined: PropTypes.bool,
  defaultTaxStatus: PropTypes.oneOf(Object.values(TAX_STATUS_TYPES)),
  allowedTaxStatuses: PropTypes.arrayOf(PropTypes.string),
  summaryTilesConfig: PropTypes.shape({
    format: PropTypes.string,
    formatConfiguration: PropTypes.object,
    visibility: PropTypes.object
  }),
  dateRangeOptions: PropTypes.array
}

CostBasisView.defaultProps = {
  defaultCostBasisType: 'unrealized',
  defaultDateRange: 'YTD',
  defaultFilter: {},
  tableFormat: DEFAULT_FORMAT_VALUE,
  costBasisTypes: ['unrealized', 'realized', 'combined'],
  allowUnrealized: true,
  allowRealized: true,
  allowCombined: true,
  defaultTaxStatus: null,
  allowedTaxStatuses: undefined,
  summaryTilesConfig: {
    format: 'human',
    // format configuration meant to target the summary component
    formatConfiguration: undefined,
    /**
     * example
     * unrealized: {
     *  shortTerm: false,
     *  total: {
     *   gainLoss: false,
     *   gain: true,
     *   loss: true,
     * }
     */
    visibility: {}
  }
}

export default CostBasisView
