import React, { useCallback, useEffect, useRef } from 'react'
import {
  CellMeasurer,
  CellMeasurerCache,
  ColumnSizer,
  Grid,
  InfiniteLoader,
  WindowScroller
} from 'react-virtualized'
import PropTypes from 'prop-types'
import 'react-virtualized/styles.css'
import isEmpty from 'lodash/isEmpty'
import { useInfiniteClients } from '../hooks/useInfiniteClients'
import { mapClients } from '../helpers'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import CardListSkeleton from '../components/CardListSkeleton'
import EmptySection from '../../../atoms/EmptySection'
import ClientCard from './ClientCard'

const ClientCardList = ({
  width,
  columnCount,
  columnWidth,
  rowHeight,
  headerElement,
  useCellMeasurer
}) => {
  const [
    {
      pageSize: defaultPageSize,
      defaultSort,
      queryText = '',
      clientsContainerRef
    },
    setContainerContext
  ] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const realColumnWidth = useRef(null)

  useEffect(() => {
    setContainerContext((prevState) => {
      return {
        ...prevState,
        realColumnWidth: realColumnWidth?.current
      }
    })
  }, [setContainerContext])

  const {
    rowCount,
    clients = [],
    fetchNextPage,
    isRowLoaded,
    isLoading
  } = useInfiniteClients({
    columnCount,
    sort: defaultSort,
    searchText: queryText,
    pageSize: defaultPageSize,
    mapper: mapClients(defaultPageSize, { columnCount })
  })

  const cacheRef = useRef(
    new CellMeasurerCache({
      defaultWidth: columnWidth,
      minWidth: 200,
      fixedWidth: true,
      defaultHeight: rowHeight
    })
  )

  const cellRenderer = useCallback(
    ({ key, columnIndex, parent, rowIndex, style }) => {
      const client = clients?.[rowIndex]?.[columnIndex]

      if (!client) return null

      if (useCellMeasurer) {
        return (
          <CellMeasurer
            key={key}
            cache={cacheRef.current}
            parent={parent}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
          >
            <div style={style}>
              <ClientCard {...client} />
            </div>
          </CellMeasurer>
        )
      }

      return (
        <div key={`${key}-${client.clientId}`} style={style}>
          <ClientCard {...client} />
        </div>
      )
    },
    [clients, useCellMeasurer]
  )

  const onSectionRendered = useCallback(
    ({ rowStartIndex, rowStopIndex }) => {
      if (onRowsRenderedRef.current) {
        onRowsRenderedRef.current({
          startIndex: rowStartIndex,
          stopIndex: rowStopIndex
        })
      }
    },
    []
  )

  const loadMoreRows = useCallback(
    ({ startIndex, stopIndex }) => {
      if (isLoading) return
      fetchNextPage()
    },
    [fetchNextPage, isLoading]
  )

  const gridRef = useRef(null)
  const onRowsRenderedRef = useRef(null)

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={1}
    >
      {({ onRowsRendered, registerChild }) => {
        onRowsRenderedRef.current = onRowsRendered
        return (
          <>
            {headerElement}
            {isLoading && isEmpty(clients) && (
              <CardListSkeleton cardListLength={columnCount} />
            )}
            {!isLoading && isEmpty(clients) && (
              <EmptySection
                title='No clients found'
                description=''
                titleStyles={{
                  fontSize: '2rem !important',
                  fontWeight: 'bold'
                }}
              />
            )}
            <WindowScroller scrollElement={clientsContainerRef}>
              {({ height, scrollTop, onChildScroll }) => {
                return (
                  <ColumnSizer
                    columnMaxWidth={width / columnCount}
                    columnMinWidth={columnWidth}
                    columnCount={columnCount}
                    width={width}
                  >
                    {({ adjustedWidth, getColumnWidth }) => {
                      realColumnWidth.current = getColumnWidth()
                      return (
                        <Grid
                          ref={(grid) => {
                            gridRef.current = grid
                            registerChild(grid)
                          }}
                          autoHeight
                          height={height || 0}
                          width={adjustedWidth}
                          scrollTop={scrollTop}
                          columnWidth={realColumnWidth.current}
                          columnCount={columnCount}
                          onScroll={onChildScroll}
                          rowHeight={rowHeight}
                          cellRenderer={cellRenderer}
                          rowCount={Math.ceil(rowCount / columnCount)}
                          onSectionRendered={onSectionRendered}
                          {...(useCellMeasurer
                            ? { rowHeight: cacheRef.current.rowHeight }
                            : {})}
                        />
                      )
                    }}
                  </ColumnSizer>
                )
              }}
            </WindowScroller>
          </>
        )
      }}
    </InfiniteLoader>
  )
}

ClientCardList.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  columnCount: PropTypes.number,
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  useCellMeasurer: PropTypes.bool,
  containerRef: PropTypes.node,
  headerElement: PropTypes.node
}

ClientCardList.defaultProps = {
  columnCount: 4,
  rowHeight: 287,
  columnWidth: 262,
  useCellMeasurer: false
}

export default ClientCardList
