import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.layout.padding.thick
  }
}))

function Loading () {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  )
}

export default Loading
