export const localStorageHelper = {
  load (key) {
    if ([undefined, null].includes(key)) {
      throw new Error('[Local storage helper]: key field is required')
    }
    const stored = localStorage.getItem(key)
    try {
      return stored === null ? null : JSON.parse(stored)
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  tryLoad (key) {
    if ([undefined, null].includes(key)) {
      return null
    }
    const stored = localStorage.getItem(key)
    try {
      return stored === null ? null : JSON.parse(stored)
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  maybeLoadRaw (key) {
    if ([undefined, null].includes(key)) {
      return null
    }
    const stored = localStorage.getItem(key)
    try {
      return stored
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  store (key, value) {
    if (typeof value === 'undefined') return

    localStorage.setItem(key, JSON.stringify(value))
  },
  storeMany (keyValuePair) {
    Object.entries(keyValuePair).forEach(([key, value]) =>
      localStorage.setItem(key, JSON.stringify(value))
    )
  },
  modify (key, fn) {
    this.store(key, fn(this.load(key)))
  },
  removeItems (keys) {
    keys.forEach((key) => localStorage.removeItem(key))
  },
  clear () {
    localStorage.clear()
  }
}
