import React, { useCallback, useState } from 'react'
import { Button, makeStyles, Snackbar, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { useForm } from 'react-hook-form'
import { postCloseAccount } from '../../../../../service'
import { useBoolean } from '../../../../../hooks'
import Modal from '../../../../molecules/Modal'
import Text from '../../../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import theme from '../../../../../theme'
import SydButton from '../../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  modal: {
    borderRadius: '.5rem',
    padding: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.gray.main}`
  },
  content: {
    padding: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1rem',
    borderTop: `1px solid ${theme.palette.gray.main}`
  },
  textField: {
    backgroundColor: theme.palette.gray.light,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem'
  }
}))

const AccountCloseButton = ({ disabled, account, onClosedAccount }) => {
  const classes = useStyles()

  const [showAlert, setShowAlert] = useBoolean(false)
  const [showModal, setShowModal] = useBoolean(false)
  const [alertSeverity, setAlertSeverity] = useState('warning')
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useBoolean(false)

  const closeAccount = async () => {
    setIsLoading.on()
    await postCloseAccount(account.accountId)
      .then(processSuccess)
      .catch(processError)
    setIsLoading.off()
  }

  const processError = useCallback((err) => {
    const { errorCode, message } = err?.response ??
    {
      errorCode: 'UNKNOWN_ERROR',
      message: 'Issue closing account'
    }

    setIsLoading.off()
    if (!errorCode) {
      return
    }

    switch (errorCode) {
      case 'CLOSE_REASON_REQUIRED':
        setAlertMessage(message)
        setAlertSeverity('warning')
        setShowAlert.off()
        setShowModal.on()
        break
      default:
        setAlertMessage(message)
        setAlertSeverity('warning')
        setShowAlert.on()
        break
    }
  }, [setIsLoading, setShowAlert, setShowModal])

  const processSuccess = useCallback(() => {
    setShowModal.off()
    setShowAlert.off()
    onClosedAccount()
  }, [setShowModal, setShowAlert, onClosedAccount])

  const {
    handleSubmit,
    register,
    clearErrors,
    reset: resetForm,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      closeReason: '',
      accountId: account.accountId
    }
  })

  const onSubmitHandler = useCallback(
    async (data, event) => {
      clearErrors()
      const { accountId, closeReason } = data
      await postCloseAccount(accountId, closeReason)
        .then(processSuccess)
        .catch(processError)
    },
    [clearErrors, processSuccess, processError]
  )

  const handleSubmitWithoutPropagation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    handleSubmit(onSubmitHandler)(event)
  }

  return (
    <>
      {!disabled ? (
        <SydButton
          variant='primary'
          size='sm'
          processing={isLoading}
          onClick={closeAccount}
          disabled={disabled}
        >
          {isLoading ? 'Loading' : 'Close Account'}
        </SydButton>
      ) : '--'}

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert.off()}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowAlert.off()} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Modal
        container={document.body}
        open={showModal}
        onClose={() => setShowModal.off()}
        containerClassName={classes.modal}
        width='40rem'
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <div>
              <Text variant={TEXT_VARIANTS.h2}>Reason for closing: {account.displayName}</Text>
              <Text variant={TEXT_VARIANTS.subtitle}>
                {alertMessage}
              </Text>
            </div>
            <button
              type='button'
              onClick={() => {
                resetForm()
                setShowModal.off()
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                color={theme.palette.summitBlue}
                name={ICON_NAMES.close}
                customSize='1.5rem'
                variant='button'
              />
            </button>
          </div>
          <form onSubmit={handleSubmitWithoutPropagation}>
            <div className={classes.content}>
              <div className={classes.groupItem}>
                <div className={classes.labelWithInfo}>
                  <Text text='Close Reason' variant={TEXT_VARIANTS.h3} />
                </div>
                <TextField
                  error={Boolean(errors.closeReason)}
                  className={classes.textField}
                  fullWidth
                  InputProps={{
                    ...register('closeReason', {
                      minLength: 1,
                      maxLength: 250,
                      required: true
                    }),
                    disableUnderline: true
                  }}
                />
              </div>
            </div>
            <div className={classes.footer}>
              <Button
                color='gray'
                variant='outlined'
                onClick={() => {
                  resetForm()
                  setShowModal.off()
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={isSubmitting}
              >
                Close Account
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

AccountCloseButton.propTypes = {
  disabled: PropTypes.bool,
  onClosedAccount: PropTypes.func,
  account: PropTypes.object.isRequired
}

export default AccountCloseButton
