import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  sectionScreen: {
    backgroundColor: theme.palette.background.default,
    opacity: ({ editing, sectionIsEditing }) => editing && !sectionIsEditing ? 0.4 : 1,
    transition: 'opacity 300ms ease-in-out',
    pointerEvents: ({ editing, sectionIsEditing }) => editing && !sectionIsEditing ? 'none' : 'auto'
  }
}))

function SectionScreen ({ children, className, editing, sectionIsEditing }) {
  const onClick = useCallback((e) => {
    if (!sectionIsEditing) {
      e.preventDefault()
      e.stopPropagation()
    }
  }, [sectionIsEditing])

  const classes = useStyles({ editing, sectionIsEditing })

  return (
    <section className={clsx(classes.sectionScreen, className)} onClick={onClick}>
      {children}
    </section>
  )
}

SectionScreen.propTypes = {
  editing: PropTypes.bool,
  sectionIsEditing: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

export default SectionScreen
