import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import ClientHighlightedList from '../../ClientCardsViewV2/ClientCardView/ClientHighlightedList'
import { useDates } from '../../../../hooks/useDates'
import { useSearchClients } from '../../../../api/clients'
import ClientCardItem from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardItem'
import ClientBalance from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardFooter/ClientBalance'
import useFetchClientBalances from '../../ClientCardsViewV2/hooks/useClientBalances'
import { mapClientBalancesToKeyValue } from '../../ClientCardsViewV2/helpers'
import { CALC_TYPES, ICON_NAMES } from '../../../../constants'
import { useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useSetCurrentClient } from '../../../../redux/slices/appContext'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    gap: '1rem',
    overflowX: 'auto',
    margin: '2rem'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > hr': {
      width: '2px',
      margin: '0 0.25rem',
      minHeight: '2rem'
    }
  },
  skeletonsContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap'
  }
}))

const TopClients = ({ limit, defaultDateRange, emptyStateLabel }) => {
  const classes = useStyles()
  const history = useHistory()
  const setCurrentClient = useSetCurrentClient()
  const { scope } = useAdvisorHome()
  const { availableDates } = useDates()
  const { dateRange } = useRelativeDateRange(defaultDateRange, availableDates)

  const clientBalancesQuery = useMemo(() => {
    const scopeFilters = {}
    if (scope) {
      scopeFilters.advisorIds = scope.values
    }
    return {
      query: {
        take: limit,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        calcType: CALC_TYPES.balance,
        orderBy: 'endingValue DESC',
        ...scopeFilters
      },
      options: {
        mapper: mapClientBalancesToKeyValue,
        enabled: Boolean(dateRange)
      }
    }
  }, [dateRange, limit, scope])

  const { data: clientBalances = {}, isLoading: isLoadingClientBalances } = useFetchClientBalances(
    clientBalancesQuery.query,
    clientBalancesQuery.options
  )

  const clientsQuery = useMemo(() => {
    const clientIds = Object.keys(clientBalances)
    return {
      query: {
        filters: {
          clientId: [{ op: 'in', value: clientIds }],
          ...(!isEmpty(scope?.values)
            ? {
              groupIds: [{ op: 'in', value: scope?.values }]
            }
            : {})
        },
        includes: {
          avatars: true
        }
      },
      queryOptions: {
        enabled: !isLoadingClientBalances,
        mapper: (data) => data?.data || []
      }
    }
  }, [scope, clientBalances, isLoadingClientBalances])

  const { data = [], isLoading } = useSearchClients(
    clientsQuery.query,
    clientsQuery.queryOptions
  )

  const clients = useMemo(() => {
    return data
      .map((client) => ({
        ...client,
        balance: clientBalances?.[client?.clientId] || {}
      }))
      .sort((clientA, clientB) =>
        clientA.balance.endingValue > clientB.balance.endingValue ? -1 : 1
      )
  }, [clientBalances, data])

  const onClickClient = useCallback(
    (client) => () => {
      setCurrentClient(client.clientId)
      history.push('/')
    },
    [history, setCurrentClient]
  )

  if (isEmpty(clients) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        icon={ICON_NAMES.paperGlassMagnifier}
        title='No data available'
        description={emptyStateLabel}
        borderedContent
      />
    )
  }

  return (
    <div className={classes.content}>
      <ClientHighlightedList
        clients={clients}
        isLoading={isLoading}
        skeletonContainerClassName={classes.skeletonsContainer}
        cardComponent={({ loading, ...clientProps }) => {
          return (
            <ClientCardItem
              client={clientProps}
              noPadding
              disableHover
              tooltipTitle='Go to client'
              loading={isLoading}
              onClick={onClickClient({ clientId: clientProps?.clientId })}
              footer={<ClientBalance {...clientProps.balance} />}
            />
          )
        }}
      />
    </div>
  )
}

TopClients.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  defaultDateRange: PropTypes.string,
  redirectTo: PropTypes.string,
  emptyStateLabel: PropTypes.string
}

TopClients.defaultProps = {
  limit: 5,
  title: 'Top Clients',
  defaultDateRange: 'L30D',
  redirectTo: undefined,
  emptyStateLabel: ''
}

export default TopClients
