import { useCallback, useEffect, useState } from 'react'
import { get, isEmpty, isFunction, set } from 'lodash'
import fastDeepEqual from '../../utils/fastDeepEqual'
import { useDeepCompareMemo } from '../../hooks'
import { useFilterSubscriptionContext } from './FilterSubscriptionProvider'

const isObject = val => {
  return val && typeof val === 'object' && !Array.isArray(val)
}

const buildFilterSubscriptionObject = (sourceFilters, filterKeys, filterPath) => {
  return filterKeys.reduce((acc, key) => {
    const filterKey = filterPath ? `${filterPath}.${key}` : key
    const filterValue = get(sourceFilters, filterKey, {})
    set(acc, key, filterValue)
    return acc
  }, {})
}

const extractFilters = (filterSpec, sourceFilters) => {
  if (isObject(filterSpec)) {
    return Object.entries(filterSpec).reduce(
      (acc, [filterTargetKey, filters]) => {
        const targetedFilter = buildFilterSubscriptionObject(
          sourceFilters,
          filters,
          filterTargetKey
        )
        return { ...acc, ...targetedFilter }
      },
      {}
    )
  }
  return buildFilterSubscriptionObject(sourceFilters, filterSpec)
}

export const useFilterSubscription = ({
  filterKey,
  publishKeys = [],
  subscribeKeys = [],
  enabled: _enabled = true
} = {}) => {
  const { filters, setFilters } = useFilterSubscriptionContext()
  const [subscribedFilters, setSubscribedFilters] = useState(undefined)

  const enabled = _enabled && ![null, undefined].includes(filters)

  useEffect(() => {
    if (!enabled || isEmpty(subscribeKeys)) {
      return
    }
    const subscribedFilters = extractFilters(subscribeKeys, filters)
    setSubscribedFilters(subscribedFilters)
  }, [filterKey, enabled, filters, subscribeKeys])

  const publishFilters = useCallback(
    (newFilters) => {
      if (!enabled || isEmpty(publishKeys)) {
        return
      }
      setFilters((prevFilters) => {
        const filtersState = isFunction(newFilters)
          ? newFilters(filterKey ? prevFilters?.[filterKey] : prevFilters)
          : newFilters

        let filtersToPublish = extractFilters(publishKeys, filtersState)
        if (filterKey) {
          filtersToPublish = { [filterKey]: filtersToPublish }
        }
        filtersToPublish = {
          ...prevFilters,
          ...filtersToPublish
        }
        return fastDeepEqual(prevFilters, filtersToPublish)
          ? prevFilters
          : filtersToPublish
      })
    },
    [filterKey, enabled, publishKeys, setFilters]
  )

  const subscribedFiltersMemoized = useDeepCompareMemo(() => {
    return subscribedFilters
  }, [subscribedFilters])

  return {
    subscribedFilters: subscribedFiltersMemoized,
    publishFilters
  }
}
