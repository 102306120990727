import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import FormSelectField from '../AccountDetails/FormSelectField'
import { useSearchRoles } from '../../../../api/users'
import { USER_TYPE } from '../../../../constants'
import { capitalizeFirstLetter } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'

const PermissionSelect = ({
  accessor = '',
  editMode = false,
  value = '',
  inputProps = {}
}) => {
  const { data: roles = [], isLoading: rolesIsLoading } = useSearchRoles({
    filters: {
      assignableToUserType: USER_TYPE.ADVISOR
    }
  })

  const roleOptions = useMemo(() => {
    return roles.reduce((acc, item) => {
      return {
        ...acc,
        [item.roleId]: {
          label: capitalizeFirstLetter(item.name),
          value: item.roleId
        }
      }
    }, {})
  }, [roles])

  const selectLabel = useMemo(() => {
    return roleOptions[value]?.label ?? 'Select a permission'
  }, [value, roleOptions])

  const selectOptions = useMemo(() => {
    return Object.values(roleOptions)
  }, [roleOptions])

  return (
    <>
      {rolesIsLoading
        ? <Skeleton variant='rect' width='100%' height='3rem' />
        : (
          <FormSelectField
            accessor={accessor}
            editMode={editMode}
            value={value}
            options={selectOptions}
            text={selectLabel}
            InputProps={inputProps}
          />
        )}
    </>
  )
}

PermissionSelect.propTypes = {
  accessor: PropTypes.string,
  editMode: PropTypes.bool,
  value: PropTypes.any,
  inputProps: PropTypes.object
}

export default PermissionSelect
