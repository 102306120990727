import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { getMedia, saveMedia, uploadDocument } from '../../../../service'
import ProfilePictureFileInput from '../../../molecules/ProfilePictureFileInput'
import { useAppContext } from '../../../../redux/slices/appContext'

const ClientProfilePicture = ({ clientId, disabled, disabledOpacity, formMediaName, defaultMediaId, uploadLabel }) => {
  const { setValue } = useFormContext()
  const { userId } = useAppContext()

  const [entryImageUploadMeta, setEntryImageUploadMeta] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })
  const [defaultImage, setDefaultImage] = useState(null)
  const [uploadImageRequest, setUploadImageRequest] = useState(null)

  const isImageUploading = useMemo(() => {
    const { uploaded, aborted, hasError } = entryImageUploadMeta
    return !uploaded && !aborted && !hasError
  }, [entryImageUploadMeta])

  useEffect(() => {
    async function fetchDefaultImage (mediaId) {
      if (!mediaId) return
      const {
        data: { downloadUrl }
      } = await getMedia(mediaId)
      setValue(formMediaName || 'imageUrl', mediaId)
      setValue('imagePreview', downloadUrl)
      setDefaultImage(downloadUrl)
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        uploaded: true
      }))
    }
    if (defaultMediaId) {
      fetchDefaultImage(defaultMediaId)
    }
  }, [defaultMediaId, formMediaName, setValue])

  useEffect(() => {
    const xhr = new XMLHttpRequest()
    setUploadImageRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [])

  const _onAbortDocumentUpload = useCallback(
    () =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const _onUploadFileProgress = useCallback(
    (event) =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onUploadProfilePicture = useCallback(
    async (file) => {
      try {
        const { data } = await saveMedia({
          clientId,
          createdBy: userId,
          fileName: file.name
        })
        const {
          mediaId,
          fileName,
          uploadUrl,
          originalFileName
        } = data

        await uploadDocument(
          uploadUrl,
          file,
          _onUploadFileProgress,
          _onAbortDocumentUpload,
          uploadImageRequest
        )
        setEntryImageUploadMeta((prevState) => ({
          ...prevState,
          uploaded: true
        }))
        return {
          mediaId,
          fileName,
          uploadUrl,
          originalFileName
        }
      } catch (err) {
        setEntryImageUploadMeta({
          progress: 0,
          uploaded: false,
          hasError: true
        })
        console.log(err)
      }
    },
    [
      userId,
      clientId,
      uploadImageRequest,
      _onUploadFileProgress,
      _onAbortDocumentUpload
    ]
  )

  const onUploadEntryPicture = useCallback(
    async (file) => {
      if (!file) return
      const { mediaId } = await onUploadProfilePicture(file)
      setValue(formMediaName || 'imageUrl', mediaId, { shouldDirty: true })
      setValue('imagePreview', file?.preview)
    },
    [onUploadProfilePicture, formMediaName, setValue]
  )

  const onRemoveEntryPicture = useCallback(async () => {
    setValue(formMediaName || 'imageUrl', '', { shouldDirty: true })
    setValue('imagePreview', '')
    setDefaultImage(null)
  }, [setValue, formMediaName])

  return (
    <ProfilePictureFileInput
      label={uploadLabel}
      disabled={disabled}
      disabledOpacity={disabledOpacity}
      placeholderSrc={defaultImage}
      isLoading={isImageUploading}
      onDropAccepted={onUploadEntryPicture}
      onRemovePicture={onRemoveEntryPicture}
    />
  )
}

ClientProfilePicture.propTypes = {
  clientId: PropTypes.number,
  disabled: PropTypes.bool,
  formMediaName: PropTypes.string,
  defaultMediaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uploadLabel: PropTypes.string,
  disabledOpacity: PropTypes.number
}

ClientProfilePicture.defaultProps = {
  uploadLabel: 'Upload',
  disabledOpacity: 0.5
}

export default ClientProfilePicture
