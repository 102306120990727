import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core'
import Image from '../../atoms/Image'
import Text from '../../atoms/Text'

const PREVIEW_SIZES = {
  maxHeightPx: 220,
  maxWidthPx: 280
}

const useStyles = makeStyles((theme) => ({
  container: {
    opacity: ({ lowlighted }) => lowlighted ? 0.3 : 1,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    backgroundColor: theme.palette.common.white,
    maxHeight: '24vh',
    maxWidth: `${PREVIEW_SIZES.maxWidthPx}px`,
    width: `${PREVIEW_SIZES.maxWidthPx}px`,
    height: `${PREVIEW_SIZES.maxHeightPx}px`,
    padding: '1rem',
    borderRadius: '8px',
    border: '2px solid rgba(33, 41, 69, 0.3)',
    cursor: 'pointer',
    willChange: 'width, height',
    zIndex: 1099,
    textDecoration: 'none !important',
    top: ({ position }) => (
      `min(calc(8px + 100% - ${PREVIEW_SIZES.maxHeightPx / 2}px), 
       max(calc(8px + ${PREVIEW_SIZES.maxHeightPx / 2}px), 
       calc(50% - ${position[1]}vh)))`
    ),
    left: ({ position }) => `calc(50% + ${position[0]}vw)`,

    transition: ({ lowlighted }) => lowlighted
      ? 'border 0.1s ease-in-out, opacity 0.5s linear 0.1s'
      : 'border 0.1s ease-in-out, opacity .3s ease-in-out',
    '&:hover': {
      borderColor: 'rgba(33, 41, 69, 0.5)'
    },
    '&:hover $smallBackgroundImage': {
      opacity: 0
    },
    '&:hover $mainTitle': {
      opacity: 0
    },
    '&:hover $hoverTitleContainer': {
      opacity: 1,
      textDecoration: 'none'
    },
    '&:hover $hoverChildContainer': {
      opacity: 1
    }
  },
  smallChildContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  mainTitle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '24px',
    color: theme.palette.primary.contrastText,
    padding: '12px 16px 10px',
    fontSize: '16px',
    fontWeight: '400',
    zIndex: '100',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverTitleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverChildContainer: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    padding: '0.5rem',
    position: 'relative',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.dark,
      border: '3px solid white'
    }
  },
  textContainer: {
    display: 'flex',
    flex: '1 1 0%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  smallBackgroundImage: {
    inset: '0',
    margin: '6%',
    opacity: 0.3,
    position: 'absolute',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      position: 'relative',
      maxWidth: '100%',
      width: '100%',
      transform: 'none',
      left: 'inherit !important',
      top: 'inherit !important',
      height: '12rem'
    },
    smallBackgroundImage: {
      display: 'flex'
    },
    smallChildContainer: {
      position: 'absolute'
    }
  }
}))

const PreviewLink = ({ to, title, smallImageSrc, smallTitle, text, view, position, onHoverChange, lowlighted }) => {
  const classes = useStyles({ position, lowlighted })
  const theme = useTheme()

  const linkRef = useRef(null)
  const onMouse = useCallback((e) => {
    onHoverChange(view, e.type === 'mouseenter')
  }, [view, onHoverChange])

  useEffect(() => {
    const node = linkRef.current
    node?.addEventListener('mouseenter', onMouse)
    node?.addEventListener('mouseleave', onMouse)
    return () => {
      node?.removeEventListener('mouseenter', onMouse)
      node?.removeEventListener('mouseleave', onMouse)
      onMouse({ type: 'mouseleave' })
    }
  }, [onMouse, linkRef])

  return (
    <Link to={to} innerRef={linkRef} className={classes.container}>
      {smallTitle && (
        <div className={classes.mainTitle}>
          {smallTitle}
        </div>
      )}
      <div className={classes.smallChildContainer}>
        {smallImageSrc && (
          <div className={classes.smallBackgroundImage}>
            <Image
              src={`${smallImageSrc}`}
              height='auto'
              width='auto'
              extraStyles={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
            />
          </div>
        )}
      </div>
      {title && (
        <div className={classes.hoverTitleContainer}>
          <Text
            text={title}
            customFontSize='13px'
            customFontWeight='600'
            color={theme.palette.primary.main}
          />
        </div>
      )}
      <div className={classes.hoverChildContainer}>
        <div className={classes.textContainer}>
          <Text
            text={text}
            customFontSize='13px'
            customFontWeight='bold'
          />
        </div>
      </div>
    </Link>
  )
}

PreviewLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  smallImageSrc: PropTypes.string,
  smallTitle: PropTypes.string,
  text: PropTypes.string,
  view: PropTypes.string,
  position: PropTypes.array,
  onHoverChange: PropTypes.func,
  lowlighted: PropTypes.bool
}

export default PreviewLink
