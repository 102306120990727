import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, makeStyles, TextField, useTheme, withStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useRoleTypes } from '../../../../api/users'
import { createTeamMember, editTeamMember, postNamedCommand } from '../../../../service'
import { BUTTON_VARIANT, ICON_NAMES } from '../../../../constants'
import { capitalizeFirstLetter } from '../../../../utils'
import { useBoolean, useCheckPolicy } from '../../../../hooks'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import Icon from '../../../atoms/Icon'
import { ADVISOR } from '../../../../policies/admin'
import RoundedButton from '../../../atoms/RoundedButton'
import TitleWithEditButton from '../AccountDetails/TitleWithEditButton'
import FormSelectField from '../AccountDetails/FormSelectField'
import { ADVISOR_FORM_NAMES, CONTACT_INFO_FIELDS } from './helpers'
import { useUserGeneralInfo, useUserWithRole } from './hooks'
import SubmitButtons from './SubmitButtons'
import Remove from './Remove'
import PermissionSelect from './PermissionSelect'

const TextInput = withStyles({
  root: {
    borderRadius: '4px',
    padding: '0.625rem 0px 0.625rem 0.625rem',
    border: '2px solid #EEF0F8',
    background: 'white',
    maxHeight: '3rem',
    '& .Mui-disabled': {
      color: '#141929 !important',
      cursor: 'not-allowed',
      border: '1px solid #EEF0F8',
      background: 'linear-gradient(0deg, #F5F6F8 0%, #F5F6F8 100%), #FFF'
    },
    '& .MuiFormHelperText-root': {
      marginTop: '15px'
    }
  }
})(TextField)

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '3rem'
  },
  title: {
    fontSize: '22px',
    color: '#141929'
  },
  info: {
    marginBottom: '18px'
  },
  itemTextField: {
    borderRadius: '4px',
    padding: '0.625rem 0px 0.625rem 0.625rem',
    border: '2px solid #EEF0F8',
    background: theme.palette.common.white,
    maxHeight: '3rem'
  },
  contactType: {
    minWidth: '100%'
  },
  contactInfo: {
    display: 'flex'
  },
  minus: {
    marginTop: '15px',
    marginLeft: '10px'
  }
}))

const ALL_FIELDS = CONTACT_INFO_FIELDS.map(({ key }) => key)

const getUpdatedValues = (data, visibleFields, teamMember) => {
  return ALL_FIELDS.reduce((acc, key) => {
    if (teamMember[key] && !visibleFields.includes(key)) {
      return {
        ...acc,
        [key]: null
      }
    }
    if (data[key] && data[key] !== '') {
      return {
        ...acc,
        [key]: data[key]
      }
    }
    return acc
  }, {})
}

const getNewValues = (data, fields) => {
  return fields.reduce((acc, key) => {
    return {
      ...acc,
      [key]: data[key] === '' ? null : data[key]
    }
  }, {})
}

const getVisibleValues = (teamMember) => {
  if (!teamMember) return []
  const visibleValues = CONTACT_INFO_FIELDS.reduce((acc, item) => {
    if (teamMember[item.key]) {
      acc.push(item.key)
    }
    return acc
  }, [])
  return visibleValues
}

const mapFormDefaultValues = (teamMember, user) => {
  return {
    [ADVISOR_FORM_NAMES.roleId]: user?.roleId,
    [ADVISOR_FORM_NAMES.title]: teamMember?.title || '',
    [ADVISOR_FORM_NAMES.email]: teamMember?.email || '',
    [ADVISOR_FORM_NAMES.phone1]: teamMember?.phone1 || '',
    [ADVISOR_FORM_NAMES.phone2]: teamMember?.phone2 || '',
    [ADVISOR_FORM_NAMES.roleTypeId]: teamMember?.roleTypeId
  }
}

const AdvisorGeneralInfo = ({
  disableEdit,
  setDisableEdit
}) => {
  const [editMode, setEditMode] = useBoolean()
  const [formLoading, setFormLoading] = useBoolean()
  const [visibleFields, setVisibleFields] = useState([])
  const canViewEdit = useCheckPolicy(ADVISOR.editAdvisor)
  const classes = useStyles({ editMode })
  const theme = useTheme()
  const { userId } = useParams()

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: mapFormDefaultValues()
  })

  const {
    reset,
    handleSubmit,
    watch,
    register,
    getValues,
    setValue,
    formState: { isValid }
  } = formMethods

  const setUser = useCallback((user) => {
    reset(
      {
        ...getValues(),
        [ADVISOR_FORM_NAMES.roleId]: user?.roleId
      }
    )
  }, [reset, getValues])

  const setTeamMember = useCallback((teamMember) => {
    if (teamMember) {
      reset(
        {
          ...getValues(),
          [ADVISOR_FORM_NAMES.title]: teamMember?.title || '',
          [ADVISOR_FORM_NAMES.email]: teamMember?.email || '',
          [ADVISOR_FORM_NAMES.phone1]: teamMember?.phone1 || '',
          [ADVISOR_FORM_NAMES.phone2]: teamMember?.phone2 || '',
          [ADVISOR_FORM_NAMES.roleTypeId]: teamMember?.roleTypeId
        }
      )
      setVisibleFields(getVisibleValues(teamMember))
    }
  }, [reset, getValues])

  const onSuccessUser = useCallback((data) => {
    if (data?.[0]) {
      const user = data[0]
      setUser(user)
    }
  }, [setUser])

  const onSuccessTeamMember = useCallback((teamMember) => {
    setTeamMember(teamMember)
  }, [setTeamMember])

  const { data: user, refetch: refetchUser, isLoading: isLoadingUser } = useUserWithRole({
    userId,
    onSuccess: onSuccessUser
  })
  const { data: teamMember, refetch: refetchTeamMember, isLoading } = useUserGeneralInfo({
    userId: user?.userId,
    onSuccess: onSuccessTeamMember
  })
  const { data: roleTypes = [] } = useRoleTypes()

  const roleTypesOptions = useMemo(() => {
    return roleTypes.reduce((acc, item) => {
      return {
        ...acc,
        [item.roleTypeId]: {
          label: capitalizeFirstLetter(item.longName),
          value: item.roleTypeId
        }
      }
    }, {})
  }, [roleTypes])

  useEffect(() => {
    return () => setDisableEdit.off()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // on mount of component trigger reset so that state is consistent
  useEffect(() => {
    if (Object.keys(user)?.length) {
      setValue(ADVISOR_FORM_NAMES.roleId, user.roleId)
    }
    if (Object.keys(teamMember)?.length) {
      setValue(ADVISOR_FORM_NAMES.title, teamMember?.title || '')
      setValue(ADVISOR_FORM_NAMES.email, teamMember?.email || '')
      setValue(ADVISOR_FORM_NAMES.phone1, teamMember?.phone1 || '')
      setValue(ADVISOR_FORM_NAMES.phone2, teamMember?.phone2 || '')
      setValue(ADVISOR_FORM_NAMES.roleTypeId, teamMember?.roleTypeId || '')
      setVisibleFields(getVisibleValues(teamMember))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = useCallback(
    async (values) => {
      try {
        setFormLoading.on()
        if (values.roleId !== user.roleId) {
          await postNamedCommand('users', 'assignRoleToUser', {
            userId: user.userId,
            roleId: values.roleId
          })
        }
        if (teamMember?.teamMemberId) {
          await editTeamMember(teamMember.teamMemberId, {
            firstName: user.firstName,
            lastName: user.lastName,
            ...getUpdatedValues(values, visibleFields, teamMember)
          })
        } else {
          await createTeamMember({
            firstName: user.firstName,
            lastName: user.lastName,
            userId: user.userId,
            ...getNewValues(values, visibleFields)
          })
        }
        refetchUser()
        refetchTeamMember()
      } catch (err) {
        console.error(err)
      } finally {
        setDisableEdit.off()
        setEditMode.off()
        setFormLoading.off()
      }
    },
    [
      setFormLoading,
      setEditMode,
      setDisableEdit,
      user,
      visibleFields,
      teamMember,
      refetchUser,
      refetchTeamMember
    ]
  )

  const onResetForm = useCallback(() => {
    reset(mapFormDefaultValues(teamMember, user))
  }, [reset, teamMember, user])

  const onCancel = useCallback(() => {
    onResetForm()
    if (teamMember) {
      setVisibleFields(getVisibleValues(teamMember))
    }
    setDisableEdit.off()
    setEditMode.off()
  }, [onResetForm, setEditMode, setDisableEdit, teamMember])

  const handleEditMode = useCallback(() => {
    setEditMode.toggle()
    setDisableEdit.toggle()
  }, [setEditMode, setDisableEdit])

  const handleChangeVisibility = useCallback((value) => {
    setVisibleFields((prevValue) => [
      ...prevValue.filter(item => item !== value.target.name),
      value.target.value
    ])
  }, [])

  const handleRemoveVisibility = useCallback((value) => {
    if (editMode) {
      setVisibleFields((prevValue) => [
        ...prevValue.filter(item => item !== value)
      ])
    }
  }, [editMode])

  const handleAddVisibility = useCallback((value) => {
    const nextField = CONTACT_INFO_FIELDS.reduce((acc, item) => {
      if (!visibleFields.includes(item.key)) {
        acc.push(item.key)
      }
      return acc
    }, [])
    if (!nextField) return
    setVisibleFields((prevValue) => [
      ...prevValue,
      nextField[0]
    ])
  }, [visibleFields])

  if (isLoading || isLoadingUser) {
    return (
      <div className={classes.container}>
        <Skeleton />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <TitleWithEditButton
              text='General Info'
              onClick={handleEditMode}
              editMode={editMode}
              disabled={(!editMode && disableEdit) || !canViewEdit}
            />
            <Grid item xs={12}>
              <Text
                text='Permissions'
                className={classes.title}
              />
            </Grid>
            <Grid item xs={12}>
              <PermissionSelect
                accessor={ADVISOR_FORM_NAMES.roleId}
                editMode={editMode}
                value={watch(ADVISOR_FORM_NAMES.roleId)}
                inputProps={{
                  disableUnderline: true,
                  ...register(ADVISOR_FORM_NAMES.roleId)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                text='Client Facing Contact Information'
                className={classes.title}
              />
              <Text
                customFontSize='0.875rem'
                color={theme.palette.dimGray}
                className={classes.info}
                text='This contact information will be visible to your wealth owner.'
              />
            </Grid>
            {CONTACT_INFO_FIELDS.map(field => {
              const isVisible = visibleFields.includes(field.key)
              if (!isVisible) return null
              return (
                <Grid item xs={12} container key={field.key} className={classes.contactInfo}>
                  <Grid item xs={2}>
                    <FormSelectField
                      className={classes.contactType}
                      accessor={field.key}
                      editMode={editMode}
                      value={field.key}
                      options={CONTACT_INFO_FIELDS}
                      onChange={handleChangeVisibility}
                      text={field.label}
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {field.key === 'roleTypeId' ? (
                      <FormSelectField
                        accessor={ADVISOR_FORM_NAMES.roleTypeId}
                        editMode={editMode}
                        value={watch(ADVISOR_FORM_NAMES.roleTypeId)}
                        options={Object.values(roleTypesOptions)}
                        text={roleTypesOptions[watch(ADVISOR_FORM_NAMES.roleTypeId)]?.label}
                        InputProps={{
                          ...register(ADVISOR_FORM_NAMES.roleTypeId),
                          disableUnderline: true
                        }}
                      />
                    ) : (
                      <Controller
                        name={ADVISOR_FORM_NAMES[field.key]}
                        render={() => (
                          <TextInput
                            fullWidth
                            className={classes.itemTextField}
                            name={ADVISOR_FORM_NAMES[field.key]}
                            helperText='80 max characters'
                            InputProps={{ disableUnderline: true }}
                            {...register(ADVISOR_FORM_NAMES[field.key], { minLength: 1, maxLength: 80 })}
                          />)}
                      />
                    )}
                  </Grid>
                  {editMode && (
                    <Grid item xs={2} className={classes.minus}>
                      <Remove onClick={() => handleRemoveVisibility(field.key)}>
                        <Remove.NoHover>
                          <Icon name={ICON_NAMES.circleMinus} />
                        </Remove.NoHover>
                        <Remove.Hover>
                          <Icon name={ICON_NAMES.circleMinusHover} />
                        </Remove.Hover>
                      </Remove>
                    </Grid>
                  )}
                </Grid>
              )
            })}
            <Grid item xs={12}>
              {editMode && visibleFields.length < CONTACT_INFO_FIELDS.length && (
                <RoundedButton
                  onClick={handleAddVisibility}
                  disabled={!editMode}
                  variant={BUTTON_VARIANT.outlined}
                  className={classes.button}
                >
                  <Text
                    text='+ Add Info'
                    customFontFamily='18px'
                  />
                </RoundedButton>
              )}
            </Grid>
          </Grid>
          <SubmitButtons
            editMode={editMode}
            onCancel={onCancel}
            isSubmitting={formLoading}
            isFormValid={isValid}
          />
        </form>
      </FormProvider>
    </div>
  )
}

AdvisorGeneralInfo.propTypes = {
  disableEdit: PropTypes.bool,
  setDisableEdit: PropTypes.any
}

export default AdvisorGeneralInfo
