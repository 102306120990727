import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Section from '../shared/Section'
import SectionTitle from '../shared/SectionTitle'

function FamilyRate ({ feeSchedule, familyRates }) {
  const _fr = useMemo(() => {
    if (!familyRates) return null
    return familyRates[feeSchedule.feeScheduleId]
  }, [familyRates, feeSchedule])

  if (!_fr) {
    return (
      <Section>
        <SectionTitle title='Family Rates' />
        <div className='__no-content'>
          Not part of a Family Rate
        </div>
      </Section>
    )
  }

  return (
    <Section>
      <SectionTitle title='Family Rates' />
      <div className='__content'>
        <table className='__table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {_fr.map(fg => (
              <tr key={fg.clientId}>
                <td>{fg.clientId}</td>
                <td>{fg.longName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Section>
  )
}

FamilyRate.propTypes = {
  feeSchedule: PropTypes.object,
  familyRates: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    feeScheduleId: PropTypes.number,
    clientId: PropTypes.number,
    longName: PropTypes.string
  })))
}

export default FamilyRate
