import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFirmReportConfiguration, useFirmReportData, useFirmReportMetrics } from '../../../api/coreData'
import OperationalTable, { useOperationalTable } from '../../organisms/OperationalTable'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import { compareNumericString } from '../../../utils'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import FilterControls from './FilterControls'
import FirmReportExportProvider from './ExportControls/FirmReportExportProvider'
import FirmReportExportButton from './ExportControls/FirmReportExportButton'
import FirmReportTitle from './FirmReportTitle'
import FirmSummaryLink from './FirmSummaryLink'

function mapSortType (value) {
  if (value.type === 'number') return compareNumericString

  return undefined
}

function mapCell (column, formatter) {
  if (column.format) {
    return ({ value }) => formatter(value, column.format)
  }
  return ({ value }) => value
}

function mapAlignRight (column) {
  return column.type === 'number'
}

const useFirmReportColumns = (configuration, columnFilter) => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    if (!configuration) return []

    const columns = Object.entries(configuration.columns).filter(([key]) => columnFilter ? columnFilter.includes(key) : true).map(([key, value]) => {
      const sortType = mapSortType(value)
      const result = {
        id: key,
        accessor: key,
        format: value.format,
        Header: value.title,
        ordinal: value.ordinal,
        alignRight: mapAlignRight(value),
        Cell: mapCell(value, formatter)
      }

      if (sortType) result.sortType = sortType
      return result
    }).sort((a, b) => a.ordinal - b.ordinal)

    const defaultSort = [{ id: configuration.defaultSortField, desc: configuration.defaultSortDir === 'desc' }]

    return {
      columns,
      defaultSort,
      asOfDate: configuration.asOfDate
    }
  }, [configuration, formatter, columnFilter])
}

const useFirmReport = (reportId, defaultFilter, sort, pageIndex, pageSize, columnFilter) => {
  const { data: configuration, isLoading: configurationLoading, error: configurationError } = useFirmReportConfiguration(reportId)
  const { columns, defaultSort, asOfDate } = useFirmReportColumns(configuration, columnFilter)

  const [baseFilter, setBaseFilter] = useState(defaultFilter)
  const [filter, setFilter] = useState({})

  const [dataQuery, exportFilters] = useMemo(() => {
    if (!configuration) return [null, null]
    const query = {
      reportId,
      take: pageSize,
      skip: pageIndex * pageSize,
      filters: filter,
      baseFilter: baseFilter || undefined,
      sortBy: (sort || []).map(s => ({ field: s.id, dir: s.desc ? 'desc' : 'asc' }))
    }
    return [query, {
      ...query,
      take: undefined,
      skip: undefined
    }]
  }, [reportId, configuration, filter, sort, pageIndex, pageSize, baseFilter])

  const metricsQuery = useMemo(() => {
    if (!configuration) return null
    return {
      reportId,
      filters: filter,
      baseFilter: baseFilter || undefined
    }
  }, [reportId, configuration, filter, baseFilter])

  // Report Data Page
  const { data, isFetching, error: dataError } = useFirmReportData(dataQuery, { enabled: !!dataQuery, keepPreviousData: true })

  // Metrics include count
  const { data: metrics, isFetching: metricsFetching } = useFirmReportMetrics(metricsQuery, { enabled: !!metricsQuery, keepPreviousData: true })
  const defaultExportName = useMemo(() => configuration ? `${configuration.report.name.replaceAll(' ', '-')}_${configuration.asOfDate}` : null, [configuration])

  return {
    configuration,
    data: data?.data || [],
    total: metrics?.metrics?.count,
    metricsFetching,
    isLoading: configurationLoading,
    isFetching,
    error: configurationError || dataError,
    columns,
    defaultSort,
    asOfDate,
    setFilter,
    baseFilter,
    setBaseFilter,
    exportFilters,
    defaultExportName
  }
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
  }
}))

const DEFAULT_SORT = []

function FirmReport ({ reportId, defaultFilter, columns, hideExport }) {
  const classes = useStyles()
  const { onPagingChange, onSortingChange, sort, pageIndex, pageSize } = useOperationalTable({
    defaultSort: DEFAULT_SORT,
    defaultPageSize: 250
  })
  const firmReport = useFirmReport(reportId, defaultFilter, sort, pageIndex, pageSize, columns)
  const { formatter } = useFormattingContext()

  if (firmReport.isLoading) return null

  if (firmReport.error) return <div>{JSON.stringify(firmReport.error)}</div>

  return (
    <div className={classes.report}>
      <ErrorBoundary>
        <FirmReportExportProvider currentFilter={firmReport.exportFilters} defaultName={firmReport.defaultExportName}>
          <OperationalTable.Wrapper>
            <div className={classes.header}>
              <FirmReportTitle
                configuration={firmReport.configuration}
                selected={firmReport.baseFilter}
                onSelect={firmReport.setBaseFilter}
              />
              {hideExport ? null : (
                <div className={classes.right}>
                  <div>As of {formatter(firmReport.configuration.asOfDate, '@MM/DD/YYYY')}</div>
                  <FirmSummaryLink report={firmReport} />
                  <FirmReportExportButton report={firmReport} />
                </div>
              )}
            </div>
            <OperationalTable.SuperHeader>
              <div className={classes.filters}>
                <FilterControls
                  schema={firmReport.configuration}
                  onChange={firmReport.setFilter}
                />
              </div>
            </OperationalTable.SuperHeader>
            <OperationalTable
              variant='v2'
              autoLimit={100}
              loading={firmReport.isFetching}
              data={firmReport.data}
              columns={firmReport.columns}
              defaultSort={firmReport.defaultSort}
              total={firmReport.total}
              defaultPageSize={250}
              onPagingChange={onPagingChange}
              onSortingChange={onSortingChange}
            />
          </OperationalTable.Wrapper>
        </FirmReportExportProvider>
      </ErrorBoundary>
    </div>
  )
}

FirmReport.propTypes = {
  reportId: PropTypes.string,
  defaultFilter: PropTypes.string,
  columns: PropTypes.array,
  hideExport: PropTypes.bool
}

export default FirmReport
