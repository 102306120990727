import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import noop from 'lodash/noop'
import {
  Container,
  Grid,
  Button,
  Box,
  Menu,
  MenuItem,
  makeStyles,
  useTheme
} from '@material-ui/core'
import {
  FormProvider,
  useForm
} from 'react-hook-form'
import { ADVISOR } from '../../../../policies/admin'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useBoolean, useCheckPolicy } from '../../../../hooks'
import Icon from '../../../atoms/Icon'
import Text from '../../../atoms/Text'
import RoundedButton from '../../../atoms/RoundedButton'
import PrintViewModestyCurtain from '../../../molecules/PrintViewModestyCurtain'
import Dialog from '../../../molecules/Dialog/Dialog'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'

import {
  ICON_NAMES,
  ADMIN_ROUTES,
  BUTTON_SIZES,
  SUCCESS_ALERT,
  OKTA_USER_STATUS
} from '../../../../constants'
import { useInvalidateClientsQueries } from '../../../../api/clients'
import {
  useRemoveUser,
  useResetUser,
  useInvalidateUsersQueries
} from '../../../../api/users'
import AdvisorGeneralInfo from './AdvisorGeneralInfo'
import UserForm from './UserForm'
import ClientsTableForm from './ClientsTableForm'
import AdvisorDetailTabs from './AdvisorDetailTabs'
import SecurityTab from './SecurityTab'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  pillBtn: {
    border: `1.5px solid ${theme.palette.summitBlue}`,
    color: theme.palette.darkJungle,
    borderRadius: 25,
    padding: '4px 16px 3px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillEdit: {
    padding: '0.5rem 1.625rem'
  },
  pillEditing: {
    backgroundColor: theme.palette.culture,
    color: theme.palette.romanSilver,
    border: `1.5px solid ${theme.palette.culture}`
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center'
  },
  removeBtn: {
    backgroundColor: theme.palette.palePink,
    color: theme.palette.error.primary,
    '&:hover': {
      backgroundColor: theme.palette.error.primary,
      color: theme.palette.palePink
    }
  },
  viewTabs: {
    marginTop: '20px'
  }
}))

function Advisor () {
  const { isAdvisor, isSummitUser } = useAppContext()
  const canEditAdvisor = useCheckPolicy(ADVISOR.editAdvisor)
  const { userId } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const isNew = !userId

  const [editingUser, setEditingUser] = useBoolean(isNew)
  const [alert, setAlert] = useState({})
  const [disableEdit, setDisableEdit] = useBoolean()

  const [openDeactivateUser, setOpenDeactivateUser] = useState(false)
  const [moreEl, setMoreEl] = useState(null)

  const invalidateQueries = useInvalidateUsersQueries()
  const resetClientsQueries = useInvalidateClientsQueries()

  const initialFormState = useMemo(() => ({
    firstName: '',
    lastName: '',
    email: '',
    internalId: '',
    userType: 'advisor',
    allAccountsAccess: false,
    internal: true
  }), [])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: initialFormState
  })

  const { getValues, watch } = formMethods
  const allAccountsAccess = watch('allAccountsAccess')

  function onSuccessRemoveUser () {
    invalidateQueries()
    history.push(ADMIN_ROUTES.ADVISORS)
  }

  function onSuccessResetUser () {
    setAlert({ ...SUCCESS_ALERT, alertMessage: 'Email with instructions has been sent' })
    invalidateQueries()
  }

  const {
    mutateAsync: removeUser,
    isLoading: isRemovingUser
  } = useRemoveUser(getValues('userId'), onSuccessRemoveUser)

  const {
    mutateAsync: resetUser,
    isLoading: isResettingUser
  } = useResetUser({ email: getValues('email') }, onSuccessResetUser)

  const openMoreMenu = useCallback((event) => setMoreEl(event.currentTarget), [])
  const closeMoreMenu = useCallback(() => setMoreEl(null), [])
  const closeDialog = useCallback(() => setOpenDeactivateUser(false), [])
  const openDeactivate = useCallback(() => {
    setOpenDeactivateUser(true)
    closeMoreMenu()
  }, [closeMoreMenu])

  const onClickReset = useCallback(() => {
    resetUser()
    closeMoreMenu()
  }, [resetUser, closeMoreMenu])

  const handleEditMode = useCallback(() => {
    setDisableEdit.toggle()
    setEditingUser.toggle()
  }, [setEditingUser, setDisableEdit])

  const isDisableRemove = (!isAdvisor && !getValues('currentInternal')) || (getValues('currentInternalId') === getValues('internalId'))
  const showResetUser = isSummitUser && !isDisableRemove && getValues('userStatus') === OKTA_USER_STATUS.active

  useEffect(() => {
    return () => {
      resetClientsQueries()
    }
  }, [resetClientsQueries])

  return (
    <>
      <PrintViewModestyCurtain
        text={isRemovingUser ? 'Deleting User' : 'Resetting User'}
        show={isRemovingUser || isResettingUser}
      />
      <Container maxWidth={false} className={classes.container}>
        <FormProvider {...formMethods}>
          {
            !isNew && (
              <Grid
                container
                justifyContent='flex-end'
                className={classes.flexItem}
                spacing={1}
              >
                <Grid item>
                  {canEditAdvisor && (
                    <Button
                      className={clsx([
                        classes.pillBtn,
                        classes.pillEdit
                      ], {
                        [classes.pillEditing]: editingUser
                      })}
                      variant='outlined'
                      onClick={handleEditMode}
                      disabled={(isResettingUser || disableEdit)}
                    >
                      {editingUser ? 'Editing' : 'Edit'}
                    </Button>
                  )}
                </Grid>
                {
                  getValues('internalId') && canEditAdvisor && (
                    <Grid item>
                      <Button aria-controls='more-opts' aria-haspopup='true' onClick={openMoreMenu} disabled={disableEdit}>
                        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
                      </Button>
                      <Menu
                        id='more-opts'
                        anchorEl={moreEl}
                        keepMounted
                        open={Boolean(moreEl)}
                        onClose={closeMoreMenu}
                      >
                        {
                          showResetUser && (
                            <MenuItem onClick={onClickReset}>
                              <Box display='flex' alignItems='center' gridGap='0.75rem'>
                                <Icon
                                  name={ICON_NAMES.resetUser}
                                  customSize='1.5rem'
                                />
                                <Text>Reset User</Text>
                              </Box>
                            </MenuItem>
                          )
                        }
                        <MenuItem onClick={openDeactivate}>
                          <Box display='flex' alignItems='center' gridGap='0.75rem'>
                            <></>
                            <Icon
                              name={ICON_NAMES.deactivateUser}
                              customSize='1.5rem'
                            />
                            <Text>Deactivate User</Text>
                          </Box>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )
                }
              </Grid>
            )
          }
          <UserForm
            editingUser={editingUser}
            setEditingUser={setEditingUser}
            disableEdit={disableEdit}
            setDisableEdit={setDisableEdit}
          />
        </FormProvider>
        {!isNew && (
          <AdvisorDetailTabs
            disabled={disableEdit}
            onTabChange={noop}
            generalTab={(
              <Grid container className={classes.viewTabs}>
                <AdvisorGeneralInfo
                  disableEdit={disableEdit}
                  setDisableEdit={setDisableEdit}
                />
              </Grid>
            )}
            clientsTab={(
              !allAccountsAccess ? (
                <Grid container className={classes.viewTabs}>
                  <Grid item xs={12}>
                    <ClientsTableForm
                      user={getValues()}
                      disableEdit={disableEdit}
                      setDisableEdit={setDisableEdit}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classes.viewTabs}>
                  <Grid item xs={12}>
                    <div>Advisor has access to all clients and accounts</div>
                  </Grid>
                </Grid>
              )
            )}
            securityTab={
              getValues('internalId') ? <SecurityTab userId={getValues('internalId')} /> : null
            }
          />
        )}
      </Container>
      <Dialog open={openDeactivateUser} onClose={closeDialog}>
        <Dialog.Header>
          <Text
            text='Deactivate User'
            color={theme.palette.summitBlue}
            customFontSize='1.5rem'
            customFontWeight='900'
          />
        </Dialog.Header>
        <Dialog.Body>
          <Text color={theme.palette.summitBlue} customFontSize='0.875rem'>
            Are you sure you want to deactivate {getValues('email')}?
          </Text>
        </Dialog.Body>
        <Dialog.Actions className={classes.modalActions}>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item lg={6} xs={12}>
              <RoundedButton
                fullWidth
                size={BUTTON_SIZES.medium}
                onClick={closeDialog}
              >
                Cancel
              </RoundedButton>
            </Grid>
            <Grid item lg={6} xs={12}>
              <RoundedButton
                fullWidth
                size={BUTTON_SIZES.medium}
                onClick={removeUser}
                className={classes.removeBtn}
              >
                Yes, deactivate
              </RoundedButton>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
      <SnackAlert alert={alert} />
    </>
  )
}

export default Advisor
