import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useAccountBillingDetails } from '../../../../../../api/billing'
import Loading from '../../../../../molecules/Loading'
import { Blade } from '../shared/Blade'
import AccountInfo from './AccountInfo'
import PositionSummaryList from './PositionSummaryList'
import BillingAccounts from './BillingAccounts'

function AccountFineDetail ({ account }) {
  const { data, isFetching } = useAccountBillingDetails(account?.accountId, { enabled: !!account })

  if (!account) {
    return null
  }

  if (isFetching) {
    return (
      <Blade>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AccountInfo account={account} />
          </Grid>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </Blade>
    )
  }

  return (
    <Blade>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <AccountInfo account={account} />
        </Grid>
        <Grid item xs={12}>
          <BillingAccounts account={account} billingAccounts={data?.body?.billingAccounts} />
        </Grid>
        <Grid item xs={12}>
          <PositionSummaryList positions={data?.body?.positions} />
        </Grid>
      </Grid>
    </Blade>
  )
}

AccountFineDetail.propTypes = {
  account: PropTypes.any
}

export default AccountFineDetail
