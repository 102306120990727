import React, { useCallback, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import 'react-multi-carousel/lib/styles.css'
import { noop } from 'lodash'
import CarouselSlider from '../CarouselSlider'
import fastDeepEqual from '../../../utils/fastDeepEqual'
import { modifyByIndex, removeByIndex } from '../../../utils'
import GroupTagFiltersSkeleton from './GroupTagFiltersSkeleton'
import GroupTagFilter from './GroupTagFilter'

const findTagFilter = (groupTypeId, clientTagFilters) => {
  const tagFilterIndex = clientTagFilters.findIndex(
    ({ groupTypeId: _groupTypeId }) => _groupTypeId === groupTypeId
  )
  const tagFilter = clientTagFilters[tagFilterIndex]
  return { tagFilter, tagFilterIndex }
}

const updateTagFilter = (groupTypeId, tags, clientTagFilters) => {
  const { tagFilter, tagFilterIndex } = findTagFilter(
    groupTypeId,
    clientTagFilters
  )

  const groupIds = tags[groupTypeId].groupIds

  return modifyByIndex(tagFilterIndex, clientTagFilters, {
    ...tagFilter,
    groupIds: [...new Set(groupIds)]
  })
}

export const mapDefaultTagFilters = (defaultTagFilters, groupTypes) => {
  const { groupIds, groupTypeId, groupName } = defaultTagFilters
  if (!groupTypes) return {}
  const groupType = groupTypes.find(({ value }) => value === groupTypeId)
  return groupIds.reduce((acc, groupId) => {
    const groups = [...groupType.groups, ...(groupType?.pinnedOptions ?? [])]
    const group = groups.find(({ value }) => value === groupId)
    if (!group) return acc
    return {
      ...acc,
      [groupId]: {
        label: group.label,
        value: groupId,
        payload: { groupTypeId, groupName }
      }
    }
  }, {})
}

const mapTagFilters = (selectedTags) => {
  const tags = selectedTags.reduce(
    (acc, tag) => {
      const { value: groupId, payload } = tag || {}
      const { groupTypeId, groupName } = payload
      return {
        ...acc,
        [groupTypeId]: {
          groupTypeId,
          groupName,
          groupIds: [...(acc[groupTypeId]?.groupIds || []), groupId]
        }
      }
    },
    {}
  )
  return tags
}

const GroupTagFilters = ({
  setTagFilters,
  tagFilters,
  groupTypes,
  disabled,
  isLoading,
  disableSlider
}) => {
  const onTagFiltersChange = useCallback(
    (groupTypeId, selectedTags) => {
      const tags = mapTagFilters(selectedTags)

      setTagFilters((prevTagFilters) => {
        let tagFilters = [...prevTagFilters]

        const { tagFilter, tagFilterIndex } = findTagFilter(
          groupTypeId,
          tagFilters
        )

        if (isEmpty(tags)) {
          tagFilters = tagFilters.filter(
            ({ groupTypeId: _groupTypeId }) => _groupTypeId !== groupTypeId
          )
        } else if (!tags?.[groupTypeId]) {
          tagFilters = removeByIndex(tagFilterIndex, tagFilters)
        } else if (tagFilter) {
          tagFilters = updateTagFilter(groupTypeId, tags, tagFilters)
        } else {
          tagFilters = [...tagFilters, ...Object.values(tags)]
        }

        const state = fastDeepEqual(prevTagFilters, tagFilters)
          ? prevTagFilters
          : Object.values(tagFilters).filter(({ groupIds }) => groupIds.length)
        return state
      })
    },
    [setTagFilters]
  )

  const selectedTagFilters = useMemo(() => {
    if (isEmpty(groupTypes)) return {}

    return tagFilters.reduce((acc, tagFilter) => {
      return {
        ...acc,
        [tagFilter.groupTypeId]: mapDefaultTagFilters(tagFilter, groupTypes)
      }
    }, {})
  }, [tagFilters, groupTypes])

  const renderGroupTagFilters = useMemo(() => {
    return (groupTypes || []).map(
      ({ groups, hidden, value, label, ...option }) => {
        if (hidden) return null
        const selectedFilters = selectedTagFilters[value] || {}
        return (
          <GroupTagFilter
            key={value}
            disabled={disabled}
            label={label}
            groupTypeId={value}
            groups={groups}
            onChange={onTagFiltersChange}
            selectedTags={selectedFilters}
            {...option}
          />
        )
      }
    )
  }, [disabled, groupTypes, onTagFiltersChange, selectedTagFilters])

  if (isLoading) {
    return <GroupTagFiltersSkeleton />
  }

  if (disableSlider) {
    return renderGroupTagFilters
  }

  return <CarouselSlider>{renderGroupTagFilters}</CarouselSlider>
}

GroupTagFilters.propTypes = {
  tagFilters: PropTypes.array,
  setTagFilters: PropTypes.func,
  disabled: PropTypes.bool,
  groupTypes: PropTypes.array,
  isLoading: PropTypes.bool,
  disableSlider: PropTypes.bool
}

GroupTagFilters.defaultProps = {
  tagFilters: [],
  setTagFilters: noop,
  disabled: false,
  groupTypes: [],
  isLoading: false,
  disableSlider: false
}

export default GroupTagFilters
