const suppression = new Map()

export const setSuppression = (key, value) => {
  suppression.set(key, !!value)
}

export const getSuppression = (key) => {
  if (suppression.has(key)) {
    return suppression.get(key)
  }
  return false
}

export const clearSuppression = (key) => {
  if (suppression.has(key)) {
    suppression.delete(key)
  }
}
