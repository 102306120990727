import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { isEmpty, isFunction, last } from 'lodash'
import PropTypes from 'prop-types'
import { useOperationalTable } from '../../../../organisms/OperationalTable'
import { useDeepCompareMemo } from '../../../../../hooks'
import { mapFlagFilter, mapGroupIdsToTagFilters, transformTagFiltersToGroupQueryParams } from '../../Clients/ClientListTab/helpers'
import { useSearchAccounts } from '../../../../../api/accounts'
import { mapSearch } from '../../../../organisms/ClientCardsViewV2/helpers'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { DIALOG_STORAGE_KEYS as STR_KEYS } from '../../../../organisms/GroupingProvider/GroupingCustomizeColumnsDialog/helpers'
import { parseSearchParams, stringifySearchParams } from '../../../../../utils'
import { DYNAMIC_FILTER_PREFIX, getFilterColumnId } from './useGroupTypeColumns'
import { COLUMN_FILTER_MAPPING, COLUMN_IDS, FILTER_OPTIONS_MAPPING, mapGroupTypeVisibleColIds, mapOptionsFromSearchParams } from './helpers'
import useAccountFilterOptions from './useAccountFilterOptions'

const ACCOUNT_SEARCH_FIELDS = ['accountNumber', 'accountName', 'displayNumber']

export const AccountsContext = createContext()

export const useAccountsContext = () => {
  const contextValue = useContext(AccountsContext)
  return contextValue
}

const accountsFiltersInitialState = {
  tagFilters: [],
  ...Object.values(COLUMN_FILTER_MAPPING).reduce((acc, filter) => {
    return {
      ...acc,
      [filter]: []
    }
  }, {})
}

const AccountsContextProvider = ({
  children,
  urlFiltersMap,
  configurationKey,
  defaultColumnConfig
}) => {
  const history = useHistory()
  const location = useLocation()

  const [visibleFilters, setVisibleFilters] = useState([
    getFilterColumnId('clients')
  ])

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange
  } = useOperationalTable({
    defaultSort: defaultColumnConfig.defaultSort
  })

  const [accountsFilters, setAccountsFilters] = useState(accountsFiltersInitialState)

  const currentSearchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const searchParams = useDeepCompareMemo(() => {
    const params = parseSearchParams(currentSearchParams)
    return !isEmpty(params) ? params : null
  }, [currentSearchParams])

  const { url } = useRouteMatch()

  useLayoutEffect(() => {
    const configuration = localStorageHelper.load(
        `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${configurationKey}`
    )
    if (configuration) {
      const { columnOrder } = configuration
      setVisibleFilters([...columnOrder])
    }
  }, [configurationKey])

  const {
    groupTypes,
    isLoadingGroupTypes,
    isLoadingOptions,
    tableColumnConfig,
    custodians,
    isLoadingCustodians,
    targetModels,
    isLoadingTargetModels
  } = useAccountFilterOptions(
    searchParams,
    visibleFilters,
    defaultColumnConfig
  )

  useEffect(() => {
    // grab filters form URL if any
    if (!isLoadingOptions && !isEmpty(groupTypes)) {
      if (searchParams) {
        setAccountsFilters((prevState) => {
          const filters = Object.values(COLUMN_FILTER_MAPPING).reduce((acc, filterKey) => {
            const filterIds = searchParams[filterKey] || []
            const filterOptions = FILTER_OPTIONS_MAPPING[filterKey]
            const filterValues = filterOptions.filter(({ value }) => filterIds.includes(value))
            if (isEmpty(filterValues)) return acc
            return {
              ...acc,
              [filterKey]: filterValues
            }
          }, {})

          const tagFilters = !isEmpty(prevState?.tagFilters)
            ? prevState.tagFilters
            : mapGroupIdsToTagFilters(searchParams?.tagFilters || [], groupTypes)

          const custodianFilters = mapOptionsFromSearchParams(
            searchParams, COLUMN_FILTER_MAPPING[COLUMN_IDS.CUSTODIANS], prevState, custodians
          )
          const targetModelsFilters = mapOptionsFromSearchParams(
            searchParams, COLUMN_FILTER_MAPPING[COLUMN_IDS.TARGET_MODELS], prevState, targetModels
          )
          // collect URL filter params and show its corresponding filter control
          const visibleFilterColIds = mapGroupTypeVisibleColIds(searchParams, groupTypes)

          if (!isEmpty(visibleFilterColIds)) {
            setVisibleFilters((prevState) => {
              return [...new Set([...prevState, ...visibleFilterColIds])]
            })
          }

          return {
            ...prevState,
            tagFilters,
            targetModelsFilters,
            custodianFilters,
            ...filters
          }
        })
      }
    }
  }, [
    groupTypes,
    targetModels,
    custodians,
    isLoadingOptions,
    searchParams,
    configurationKey
  ])

  const updateUrlSearchParams = useCallback(() => {
    if (Object.values(accountsFilters).every(isEmpty)) {
      return
    }

    const {
      tagFilters,
      ...accountFilters
    } = accountsFilters

    const groupFilterIds = tagFilters?.reduce(
      (acc, filter) => [...acc, ...filter.groupIds],
      []
    )

    const accountFilterIds = Object.entries(accountFilters).reduce(
      (acc, [filterKey, filterValue]) => {
        if (isEmpty(filterValue)) {
          return acc
        }
        return {
          ...acc,
          [filterKey]: filterValue.map(({ value }) => value)
        }
      },
      {}
    )

    const searchParams = {
      ...(!isEmpty(groupFilterIds) ? { tagFilters: groupFilterIds } : {}),
      ...(!isEmpty(accountFilterIds) ? accountFilterIds : {})
    }

    const params = new URLSearchParams(
      !isEmpty(searchParams) ? stringifySearchParams(searchParams) : {}
    )

    history.replace({
      pathname: url,
      search: params.toString()
    })
  }, [accountsFilters, history, url])

  useEffect(() => {
    // update URL params on each filter change
    updateUrlSearchParams()
  }, [updateUrlSearchParams])

  const urlFilters = useMemo(() => {
    if (!searchParams || isEmpty(searchParams)) return null

    return Object.entries(searchParams).reduce(
      (acc, [paramKey, paramValue]) => {
        if (!urlFiltersMap[paramKey] || !paramValue) return acc
        const { filterKey, op } = urlFiltersMap[paramKey]
        return { ...acc, [filterKey]: [{ op, value: paramValue }] }
      },
      {}
    )
  }, [searchParams, urlFiltersMap])

  const queryFilters = useMemo(() => {
    const {
      tagFilters,
      taxStatusFilters,
      benchmarksFilters,
      feeSchedulesFilters,
      hasClientsAssignedFilters,
      closeDateFilters,
      custodianFilters,
      targetModelsFilters
    } = accountsFilters

    const { groupIds, missingGroupType } = transformTagFiltersToGroupQueryParams(tagFilters)

    const taxStatusIds = taxStatusFilters.map(({ value }) => parseInt(value))
    const custodianIds = custodianFilters.map(({ value }) => parseInt(value))
    const targetModelIds = targetModelsFilters.map(({ value }) => parseInt(value))
    const hasBenchmarks = mapFlagFilter(benchmarksFilters)
    const hasFeeSchedules = mapFlagFilter(feeSchedulesFilters)
    const hasClientsAssigned = mapFlagFilter(hasClientsAssignedFilters)
    const isClosed = mapFlagFilter(closeDateFilters)

    return {
      ...(!isEmpty(groupIds) ? { groupIds } : {}),
      ...(!isEmpty(missingGroupType) ? { missingGroupType } : {}),
      ...(!isEmpty(taxStatusIds) ? { taxStatusId: [{ op: 'in', value: taxStatusIds }] } : {}),
      ...(!isEmpty(custodianIds) ? { custodianId: [{ op: 'in', value: custodianIds }] } : {}),
      ...(!isEmpty(targetModelIds) ? { hasTargetModel: [{ op: 'in', value: targetModelIds }] } : {}),
      ...(hasBenchmarks !== undefined ? { hasBenchmarks } : {}),
      ...(hasFeeSchedules !== undefined ? { hasFeeSchedules } : {}),
      ...(hasClientsAssigned !== undefined ? { hasClientsAssigned } : {}),
      ...(isClosed !== undefined ? { isClosed } : {}),
      ...(urlFilters ?? {})
    }
  }, [accountsFilters, urlFilters])

  const {
    query: accountsQuery,
    queryOptions: accountsQueryOptions
  } = useMemo(() => {
    const visibleGroupTypes = visibleFilters.reduce((acc, filter) => {
      if (!filter.startsWith(DYNAMIC_FILTER_PREFIX)) {
        return acc
      }
      const groupTypeId = parseInt(last(filter.split('_')))
      if ([null, undefined, NaN].includes(groupTypeId)) return acc
      return [...acc, groupTypeId]
    }, [])

    return {
      query: {
        sort: sort.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' })),
        skip: pageIndex * pageSize || 0,
        take: pageSize,
        textSearch: mapSearch(searchText, ACCOUNT_SEARCH_FIELDS),
        includes: {
          assignedClients: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'assignedClients'),
          targetModels: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'targetModels'),
          feeSchedules: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'feeSchedules'),
          benchmarks: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'benchmarks'),
          accountStatus: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'accountStatus'),
          custodian: tableColumnConfig.columns.some(x => visibleFilters.includes(x.id) && x.include === 'custodian'),
          groups: visibleGroupTypes
        },
        filters: queryFilters
      },
      queryOptions: {
        mapper: ({ data }) => data ?? []
      }
    }
  }, [
    sort,
    pageSize,
    pageIndex,
    searchText,
    queryFilters,
    visibleFilters,
    tableColumnConfig
  ])

  const onChangeFilters = useCallback(
    (filters) => {
      setAccountsFilters((prevState) => {
        const state = isFunction(filters)
          ? filters(prevState)
          : filters

        if (Object.values(state).every(isEmpty)) {
          history.replace({ pathname: url })
        }
        return state
      })
    },
    [url, history]
  )

  const { data, isLoading } = useSearchAccounts(
    accountsQuery,
    accountsQueryOptions
  )

  const options = useMemo(() => {
    return {
      custodians,
      targetModels,
      isLoadingCustodians,
      isLoadingTargetModels
    }
  }, [
    custodians,
    targetModels,
    isLoadingCustodians,
    isLoadingTargetModels
  ])

  const value = useMemo(
    () => ({
      accounts: data ?? [],
      isLoading,
      isLoadingOptions,
      groupTypes,
      isLoadingGroupTypes,
      operationalTable: {
        defaultPageSize,
        onPagingChange,
        onSortingChange,
        onTableModeChange,
        onSearchTextChange
      },
      searchText,
      columnConfig: tableColumnConfig,
      accountsFilters,
      setAccountsFilters,
      visibleFilters,
      setVisibleFilters,
      onChangeFilters,
      configurationKey,
      options
    }),
    [
      data,
      options,
      isLoading,
      groupTypes,
      searchText,
      isLoadingOptions,
      visibleFilters,
      onPagingChange,
      onSortingChange,
      onChangeFilters,
      defaultPageSize,
      accountsFilters,
      configurationKey,
      tableColumnConfig,
      onTableModeChange,
      setVisibleFilters,
      onSearchTextChange,
      isLoadingGroupTypes
    ]
  )

  return (
    <AccountsContext.Provider value={value}>
      {children}
    </AccountsContext.Provider>
  )
}

AccountsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultColumnConfig: PropTypes.object,
  configurationKey: PropTypes.string,
  urlFiltersMap: PropTypes.object
}

AccountsContextProvider.defaultProps = {
  configurationKey: 'adminAccountsListView',
  urlFiltersMap: {}
}

export default AccountsContextProvider
