import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Button, Container, Divider, Grid, makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { useOktaAuth } from '@okta/okta-react'
import { useGetRoleById, useSearchPermissions } from '../../../../api/users'
import { useSetViewTitle } from '../../../../redux/slices/appContext'
import { postNamedCommand } from '../../../../service'
import Text from '../../../atoms/Text'
import { useAuthState, useBoolean, usePermissions } from '../../../../hooks'
import Skeleton from '../../../atoms/Skeleton'
import TextInput from '../../../molecules/TextInput'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { ERROR_ALERT, SKELETON_VARIANTS, SUCCESS_ALERT, USER_TYPE } from '../../../../constants'
import { messageToUse } from '../common'
import { useGetTotalUsersByRoleId, usePermissionGroups, useUpdateRole } from '../Advisors/hooks'
import PermissionGroups from '../Advisors/PermissionGroups'
import PermissionGroupsDetails from '../Advisors/PermissionGroupsDetails'
import PermissionGroupsSelector from '../Advisors/PermissionGroupsSelector'
import { useCheckPolicies } from '../../../../api/policy'
import { ROLES } from '../../../../policies/admin'
import EmptySection from '../../../atoms/EmptySection'
import RoleViewsEdit from './RoleViewsEdit'

const SKELETON_HEIGHT = '65px'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 2.1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  totalAdvisorTitle: {
    marginBottom: '20px'
  },
  namesContainer: {
    marginBottom: '1.75rem'
  },
  pillBtn: {
    border: `1.5px solid ${theme.palette.summitBlue}`,
    color: theme.palette.darkJungle,
    borderRadius: 25,
    padding: '8px 26px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillEditing: {
    backgroundColor: theme.palette.culture,
    color: theme.palette.romanSilver,
    border: `1.5px solid ${theme.palette.culture}`
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  textSkeleton: {
    padding: '16px',
    marginTop: '60px'
  }
}))

const tabOptions = {
  permissions: 'Permissions',
  views: {
    label: 'Views',
    value: 'views'
  }
}

function getTitle (canEdit, isNew, baseTitle = 'Role') {
  if (!canEdit) return `${baseTitle} Details`
  return `${isNew ? 'Add' : 'Edit'} ${baseTitle}`
}

function mapFormDefaultValues (role) {
  if (isEmpty(role)) return {}
  return {
    name: role.name,
    description: role.description
  }
}

function RoleDetails () {
  const classes = useStyles()
  const theme = useTheme()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})

  const { roleId = null } = useParams()
  const [alert, setAlert] = useState({})
  const [permissionGroupsIds, setPermissionGroupsIds] = useState([])
  const [formSubmitting, setIsFormSubmitting] = useBoolean()
  const [editingRole, setEditingRole] = useState(false)
  const updateRole = useUpdateRole()

  const isNew = !roleId

  const setViewTitle = useSetViewTitle()
  const { data: role, isLoading: loadingRole } = useGetRoleById({ roleId })
  const { data: permissionGroups, isLoading: loadingPermissionGroups } = usePermissionGroups({ assignableToUserType: role?.assignableToUserType })
  const { data: defaultPermissions, isLoading: loadingPermissions } = useSearchPermissions({ filetrs: { defaultToAdmins: true } })
  const { data: totalUsers } = useGetTotalUsersByRoleId({ roleId, isSummitUser, userTypeId: role?.assignableToUserType })

  const { data: policies, isLoading: isLoadingPolicies } = useCheckPolicies()
  const canEditRole = useMemo(() => {
    if (isLoadingPolicies || !role?.assignableToUserType) return true
    if (role?.assignableToUserType === USER_TYPE.ADVISOR) {
      return policies?.[ROLES.editAdvisorRoles] ?? false
    }
    if (role?.assignableToUserType === USER_TYPE.WEALTH_OWNER) {
      return policies?.[ROLES.editWealthOwnerRoles] ?? false
    }
  }, [isLoadingPolicies, policies, role?.assignableToUserType])

  const canViewRole = useMemo(() => {
    if (isLoadingPolicies || !role?.assignableToUserType) return true
    if (role?.assignableToUserType === USER_TYPE.ADVISOR) {
      return policies?.[ROLES.viewAdvisorRoles] ?? false
    }
    if (role?.assignableToUserType === USER_TYPE.WEALTH_OWNER) {
      return policies?.[ROLES.viewWealthOwnerRoles] ?? false
    }
  }, [isLoadingPolicies, policies, role?.assignableToUserType])

  useEffect(() => {
    setViewTitle(getTitle(canEditRole, isNew, role?.assignableToUserType === USER_TYPE.WEALTH_OWNER ? 'Experience' : 'Role'))
    return () => {
      setViewTitle(null)
    }
  }, [isNew, setViewTitle, canEditRole, role?.assignableToUserType])

  const isLoading = useMemo(() => {
    return loadingRole || loadingPermissionGroups || loadingPermissions
  }, [loadingRole, loadingPermissionGroups, loadingPermissions])

  const defaultValues = useMemo(() => {
    if (role?.permissionGroups) {
      setPermissionGroupsIds(role?.permissionGroups.map(({ permissionGroupId }) => permissionGroupId))
    }
    return mapFormDefaultValues(role)
  }, [role])

  const associatedPermissionGroups = useMemo(() => {
    if (role?.isAdmin) {
      return defaultPermissions
    }
    return permissionGroupsIds.reduce((acc, pgId) => {
      if ((permissionGroups[pgId])) {
        return [...acc, permissionGroups[pgId]]
      }
      return acc
    }, [])
  }, [permissionGroupsIds, permissionGroups, role, defaultPermissions])

  const totalUserDisplay = useMemo(() => {
    const labels = {
      [USER_TYPE.ADVISOR]: 'Num of Advisors',
      [USER_TYPE.WEALTH_OWNER]: 'Num of Wealth Owners'
    }

    return (
      <>
        <Text
          text={labels[role?.assignableToUserType] ?? 'Num of Users'}
          className={classes.totalAdvisorTitle}
        />
        {totalUsers}
      </>
    )
  }, [classes.totalAdvisorTitle, role?.assignableToUserType, totalUsers])

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    defaultValues: {},
    mode: 'onChange'
  })

  useEffect(() => {
    if (!isEmpty(defaultValues)) {
      reset(defaultValues)
    }
  }, [reset, defaultValues])

  const onSubmit = useCallback(async (data) => {
    try {
      setIsFormSubmitting.on()
      const payload = {
        roleId,
        permissionGroups: permissionGroupsIds.map((groupId) => ({ permissionGroupId: groupId }))
      }
      await updateRole.mutate({ roleId, name: data.name, description: data.description })
      await postNamedCommand('users', 'updateRoleAssignments', payload)
      setAlert({
        ...SUCCESS_ALERT,
        alertMessage: 'Role details have been updated'
      })
    } catch (e) {
      console.error(e)
      setAlert(ERROR_ALERT)
    } finally {
      setIsFormSubmitting.off()
      setEditingRole(false)
    }
  }, [setIsFormSubmitting, updateRole, roleId, permissionGroupsIds])

  const onCancel = useCallback(() => {
    if (role?.permissionGroups) {
      setPermissionGroupsIds(role?.permissionGroups.map(({ permissionGroupId }) => permissionGroupId))
    } else {
      setPermissionGroupsIds([])
    }
    reset(defaultValues)
    setEditingRole(false)
  }, [role, defaultValues, reset])

  const handleChange = useCallback((value) => {
    setPermissionGroupsIds(value)
  }, [])

  if (!canViewRole) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          !isNew && !role?.isAdmin && !isLoading && canEditRole && (
            <Grid
              container
              justifyContent='flex-end'
              className={classes.flexItem}
              spacing={1}
            >
              <Grid item>
                <Button
                  className={clsx([classes.pillBtn], {
                    [classes.pillEditing]: editingRole
                  })}
                  variant='outlined'
                  onClick={() => setEditingRole(!editingRole)}
                >
                  {editingRole ? 'Editing' : 'Edit'}
                </Button>
              </Grid>
            </Grid>
          )
        }
        <Grid container spacing={4} className={classes.namesContainer}>
          <Grid item md={4} xs={12}>
            {isLoading
              ? (
                <Skeleton
                  width='100%'
                  height={SKELETON_HEIGHT}
                  variant={SKELETON_VARIANTS.rect}
                  className={classes.textSkeleton}
                />)
              : (
                <>
                  <Controller
                    name='name'
                    render={() => (
                      <TextInput
                        fullWidth
                        name='name'
                        placeholder='Display Name'
                        label='Display Name'
                        error={Boolean(errors.name)}
                        disabled={!isNew && !editingRole}
                        {...register('name', { required: true })}
                      />)}
                    control={control}
                  />
                  {errors.name && (
                    <Text
                      text={messageToUse(errors.name)}
                      color={theme.palette.error.main}
                      className={classes.confirmError}
                    />
                  )}
                </>
              )}
          </Grid>
          <Grid item md={4} xs={12}>
            {isLoading
              ? (
                <Skeleton
                  width='100%'
                  height={SKELETON_HEIGHT}
                  variant={SKELETON_VARIANTS.rect}
                  className={classes.textSkeleton}
                />)
              : (
                <>
                  <Controller
                    name='description'
                    render={() => (
                      <TextInput
                        fullWidth
                        name='description'
                        placeholder='Description'
                        label='Description'
                        error={Boolean(errors.description)}
                        disabled={!isNew && !editingRole}
                        {...register('description')}
                      />)}
                    control={control}
                  />
                  {errors.description && (
                    <Text
                      text={messageToUse(errors.description)}
                      color={theme.palette.error.main}
                      className={classes.confirmError}
                    />
                  )}
                </>
              )}
          </Grid>
          <Grid item md={4} xs={12}>
            {isLoading
              ? (
                <Skeleton
                  width='100%'
                  height={SKELETON_HEIGHT}
                  variant={SKELETON_VARIANTS.rect}
                  className={classes.textSkeleton}
                />)
              : totalUserDisplay}
          </Grid>
        </Grid>

        <Divider />
        <ViewTabs.Group tabOptions={tabOptions} onChange={noop}>
          <ViewTabs.Links
            tabsKey='admin_role_details'
          />
          <ViewTabs.Tab value='permissions'>
            <Grid container>
              <Grid item xs={6}>
                {editingRole && (
                  <PermissionGroupsSelector
                    permissionGroups={permissionGroups}
                    selectedPermissionGroups={permissionGroupsIds}
                    onSelectValue={handleChange}
                    loading={isLoading}
                  />
                )}
                {!editingRole && (
                  <PermissionGroups
                    permissionGroups={permissionGroups}
                    selectedPermissionGroups={permissionGroupsIds}
                    onSelectValue={handleChange}
                    loading={isLoading}
                    isAdmin={role?.isAdmin}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <PermissionGroupsDetails
                  permissionGroups={associatedPermissionGroups}
                  isAdmin={role?.isAdmin}
                  loading={isLoading}
                />
              </Grid>
            </Grid>
          </ViewTabs.Tab>
          <ViewTabs.Tab value='views'>
            <RoleViewsEdit isEditing={editingRole} role={role} />
          </ViewTabs.Tab>
        </ViewTabs.Group>
        {canEditRole && editingRole && (
          <SaveCancelButtons
            onCancel={onCancel}
            isSubmitting={isSubmitting || formSubmitting}
            isFormValid={!(!isValid || isSubmitting || (!editingRole && !isNew))}
            containerClassName={classes.actions}
          />
        )}
      </form>
      <SnackAlert alert={alert} />
    </Container>
  )
}

RoleDetails.propTypes = {}

export default RoleDetails
