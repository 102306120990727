import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'
import Carousel from 'react-multi-carousel'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '../atoms/Icon'
import {
  BUTTON_SIZES, ICON_NAMES,
  WEALTH_JOURNEY_DATE_PERIODS
} from '../../constants'
import 'react-multi-carousel/lib/styles.css'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: '0.25rem 2rem',
    zIndex: '100'
  },
  tab: {
    width: 'fit-content',
    cursor: 'pointer',
    padding: '6px 12px',
    background: theme.palette.seaFoam,
    borderRadius: '24px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    margin: 'auto',
    userSelect: 'none',
    '&:hover': {
      background: theme.palette.cloudBurst,
      color: theme.palette.white
    }
  },
  selected: {
    background: theme.palette.cloudBurst,
    color: theme.palette.white
  },
  carouselContainer: {
    zIndex: '99',
    width: '100%'
  },
  carouselLeftArrow: {
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  carouselRightArrow: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  carouselButtonGroup: {
    position: 'absolute',
    inset: '0'
  },
  carouselSlider: {
    flex: '1 1 auto'
  }
}))

const CAROUSEL_RESPONSIVE = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: WEALTH_JOURNEY_DATE_PERIODS
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
}

const DatePeriodTabs = ({
  tabs,
  onTabStepClick,
  selectedTabStep,
  onReachNavigationEdge,
  disableCarouselNavigation
}) => {
  const carouselRef = useRef()
  const classes = useStyles()

  const CustomCarouselButtonGroup = useCallback(
    ({ next, previous, carouselState, goToSlide }) => {
      const { currentSlide, totalItems, slidesToShow } = carouselState
      const prevEdgeReached = currentSlide === 0
      const nextEdgeReached = currentSlide + slidesToShow === totalItems

      return (
        <div className={classes.carouselButtonGroup}>
          <IconButton
            disabled={disableCarouselNavigation}
            onClick={(e) => {
              if (prevEdgeReached) {
                onReachNavigationEdge({ prevEdgeReached }, goToSlide, currentSlide)
              }
              previous(e)
            }}
            size={BUTTON_SIZES.small}
            className={classes.carouselLeftArrow}
          >
            <Icon name={ICON_NAMES.navigatePrevious} customSize='1.5rem' />
          </IconButton>
          <IconButton
            disabled={disableCarouselNavigation}
            onClick={(e) => {
              if (nextEdgeReached) {
                onReachNavigationEdge({ nextEdgeReached }, goToSlide, currentSlide)
              }
              next(e)
            }}
            size={BUTTON_SIZES.small}
            className={classes.carouselRightArrow}
          >
            <Icon name={ICON_NAMES.navigateNext} customSize='1.5rem' />
          </IconButton>
        </div>
      )
    },
    [
      onReachNavigationEdge,
      disableCarouselNavigation,
      classes.carouselLeftArrow,
      classes.carouselRightArrow,
      classes.carouselButtonGroup
    ]
  )

  return (
    <div className={classes.container}>
      <Carousel
        ref={carouselRef}
        arrows={false}
        swipeable={false}
        draggable={false}
        centerMode={false}
        autoPlay={false}
        shouldResetAutoplay={false}
        renderButtonGroupOutside
        responsive={CAROUSEL_RESPONSIVE}
        sliderClass={classes.carouselSlider}
        containerClass={classes.carouselContainer}
        customButtonGroup={<CustomCarouselButtonGroup />}
      >
        {tabs.map((tabStep, index) => (
          <Grid
            item
            key={tabStep.label}
            className={clsx(classes.tab, {
              [classes.selected]: tabStep.value === selectedTabStep?.value
            })}
            onClick={() => onTabStepClick(tabStep, index)}
          >
            {tabStep.label}
          </Grid>
        ))}
      </Carousel>
    </div>
  )
}

DatePeriodTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  })),
  onTabStepClick: PropTypes.func,
  selectedTabStep: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any])
  }),
  onReachNavigationEdge: PropTypes.func,
  disableCarouselNavigation: PropTypes.bool
}

DatePeriodTabs.defaultProps = {
  tabs: [],
  onTabStepClick: noop,
  selectedTabStep: undefined,
  onReachNavigationEdge: noop,
  disableCarouselNavigation: false
}

export default DatePeriodTabs
