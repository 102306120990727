import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { makeStyles, lighten, alpha } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import BarTooltip, { TooltipContext } from './BarTooltip'
import { useMarkerLayer } from './markers/useMarkerLayer'

const useStyles = makeStyles((theme) => ({
  chartArea: {
    height: ({ numItems, heightPerBar, minHeight }) => {
      const calcedHeight = (numItems || 1) * heightPerBar
      return calcedHeight < minHeight ? `${minHeight}px` : `${calcedHeight}px`
    },
    minHeight: '300px',
    marginBottom: '30px',
    '& .__target-box': {
      fill: 'url(#dots-pattern)',
      stroke: theme.palette.gray.darker,
      strokeWidth: 2
    },
    '& .__floating-target-marker': {
      fill: ({ targetColor }) => alpha(targetColor, 0.7),
      stroke: ({ targetColor }) => alpha(targetColor, 0.9),
      strokeWidth: 1
    },
    '& .__floating-tolerance-marker': {
      fill: ({ toleranceColor }) => alpha(toleranceColor, 0.7),
      stroke: ({ toleranceColor }) => alpha(toleranceColor, 0.9),
      strokeWidth: 1
    },
    '& text': {
      fontFamily: theme.typography.fontFamily
    }
  }
}))

function TargetAllocationBarChart ({
  data,
  valueAccessor,
  valueFormat,
  targetAccessor,
  tickFormat,
  tooltip,
  allocationColor,
  targetColor,
  toleranceColor,
  compareTo,
  markerType,
  padding,
  noGradient,
  heightPerBar,
  minHeight
}) {
  const { formatter } = useFormattingContext()
  const theme = useTheme()

  const targetLayer = useMarkerLayer(markerType, targetAccessor, tooltip, compareTo)

  const graphInfo = useMemo(() => {
    const getProjected = item => +(item[targetAccessor] || 0)
    const maxValue = data.allocation.reduce((p, d) => {
      const projected = getProjected(d)
      const compareValue = projected > d[valueAccessor] ? projected : d[valueAccessor]
      return p > compareValue ? p : compareValue
    }, 0) * 1.1

    return {
      layers: ['grid', 'axes', 'bars', 'markers', targetLayer, 'legends', 'annotations'],
      maxValue,
      numItems: data.allocation.length
    }
  }, [data, valueAccessor, targetAccessor, targetLayer])

  const labelFormatter = useCallback(d => {
    return formatter(d.value, valueFormat)
  }, [formatter, valueFormat])

  const tooltipConfig = useMemo(() => ({
    valueAccessor,
    targetAccessor,
    valueFormat,
    targetFormat: valueFormat,
    allocationColor,
    targetColor
  }), [valueAccessor, targetAccessor, valueFormat, allocationColor, targetColor])

  const colorStop = useMemo(() => {
    return alpha(lighten(allocationColor, 0.5), 0.3)
  }, [allocationColor])

  const classes = useStyles({
    numItems: graphInfo.numItems,
    targetColor,
    toleranceColor,
    heightPerBar,
    minHeight
  })

  const sortedData = useMemo(() => {
    // makes no sense - nivo seems to reverse the input here
    const result = [...(data?.allocation || [])]
    result.sort((a, b) => {
      return (b?.ordinal ?? Number.POSITIVE_INFINITY) - (a?.ordinal ?? Number.POSITIVE_INFINITY)
    })
    return result
  }, [data?.allocation])

  return (
    <TooltipContext.Provider value={tooltipConfig}>
      <div className={classes.chartArea}>
        <ResponsiveBar
          theme={{
            fontFamily: theme.typography.fontFamily,
            fontSize: '12px'
          }}
          data={sortedData}
          keys={[valueAccessor]}
          indexBy='levelName'
          layout='horizontal'
          colors={() => allocationColor}
          layers={graphInfo.layers}
          padding={padding}
          margin={{ top: 50, right: 130, bottom: 50, left: 200 }}
          label={labelFormatter}
          maxValue={graphInfo.maxValue}
          defs={[
            {
              id: 'dots-pattern',
              type: 'patternLines',
              spacing: 7,
              rotation: -45,
              lineWidth: 2,
              background: 'transparent',
              color: targetColor
            },
            {
              id: 'gradientC',
              type: 'linearGradient',
              gradientTransform: 'rotate(90 0.5 0.5)',
              colors: [
                { offset: 0, color: colorStop },
                { offset: 100, color: allocationColor }
              ]
            }
          ]}
          fill={noGradient ? undefined : [
            { match: '*', id: 'gradientC' }
          ]}
          borderRadius={2}
          borderWidth={1.5}
          labelSkipWidth={100}
          enableGridX
          enableGridY={false}
          axisBottom={{
            tickRotation: -45,
            format: (d) => formatter(d, tickFormat)
          }}
          tooltip={tooltip}
        />
      </div>
    </TooltipContext.Provider>
  )
}

TargetAllocationBarChart.propTypes = {
  data: PropTypes.shape({
    allocation: PropTypes.array
  }),
  valueAccessor: PropTypes.string,
  valueFormat: PropTypes.string,
  targetAccessor: PropTypes.string,
  tickFormat: PropTypes.string,
  tooltip: PropTypes.any,
  allocationColor: PropTypes.string,
  targetColor: PropTypes.string,
  toleranceColor: PropTypes.string,
  compareTo: PropTypes.array,
  markerType: PropTypes.string,
  padding: PropTypes.number,
  noGradient: PropTypes.bool,
  heightPerBar: PropTypes.number,
  minHeight: PropTypes.number
}

TargetAllocationBarChart.defaultProps = {
  valueAccessor: 'allocation',
  valueFormat: 'allocation',
  targetAccessor: 'targetAllocation',
  tickFormat: 'human',
  tooltip: BarTooltip,
  allocationColor: 'rgba(0, 153, 255, 1)',
  targetColor: 'rgba(0, 0, 100, .3)',
  toleranceColor: 'rgba(255, 0, 0, 1)',
  padding: 0.5,
  noGradient: false,
  heightPerBar: 100,
  minHeight: 300
}

export default TargetAllocationBarChart
