import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../../molecules/Tabs'
import { useTabGroup } from './tabContext'

const TabList = ({ rightAdornment }) => {
  const { selectedValue, selectValue, options } = useTabGroup()

  return (
    <Tabs.Wrapper noMargin>
      <Tabs
        value={selectedValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_, value) => {
          // eslint-disable-next-line no-debugger
          debugger
          selectValue(value)
        }}
      >
        {options.map((o) => (
          <Tabs.Tab
            key={o.value}
            value={o.value}
            label={o?.payload?.label || o?.payload}
            disabled={o?.payload?.disabled}
          />
        ))}
      </Tabs>
      {rightAdornment}
    </Tabs.Wrapper>
  )
}

TabList.propTypes = {
  rightAdornment: PropTypes.node
}

TabList.defaultProps = {
  rightAdornment: undefined
}

export default TabList
