import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import { INTERNAL_DATE_FORMAT } from '../../../../../constants'
import { disableHolidaysAndWeekends } from '../../../../../utils'
import CellDownload from './CellDownload'
import CellSettingsMenu from './CellSettingsMenu'

dayjs.extend(utc)

export const TYPE_OPTIONS = [
  { id: 'standard', checked: false, payload: { tagName: 'Standard' } },
  { id: 'custodial', checked: false, payload: { tagName: 'Custodial' } },
  { id: 'json', checked: false, payload: { tagName: 'JSON' } }
]

export const FREQUENCY_OPTIONS = [
  { tagName: 'Quarterly', id: 'Q' },
  { tagName: 'Annual', id: 'A' },
  { tagName: 'Monthly', id: 'M' }
]

export const CLIENT_LEVEL_VALUE = 'select_client'
export const ACCOUNT_LEVEL_VALUE = 'select_account'

export const LEVEL_OPTIONS = [
  { tagName: 'All Clients', id: 'client' },
  { tagName: 'All Accounts', id: 'account' },
  { tagName: 'Select Client(s)', id: CLIENT_LEVEL_VALUE },
  { tagName: 'Select Account(s)', id: ACCOUNT_LEVEL_VALUE }
]

export const columnsConfig = {
  columns: [
    { Header: 'Billing Run ID', accessor: 'billingRunId' },
    { Header: 'Run Date', accessor: 'createdAt' },
    { Header: 'Level', accessor: 'levelType' },
    { Header: 'As of Date', accessor: 'asOfDate' },
    { Header: 'Start Date', accessor: 'startDate' },
    { Header: 'End Date', accessor: 'endDate' },
    { Header: 'Frequency', accessor: 'frequency' },
    {
      Header: 'Report Status',
      accessor: (row) => {
        if (row.status.toLowerCase() === 'active') {
          return 'Review'
        }
        return row.status
      }
    },
    {
      Header: 'Run By',
      accessor: (row) => {
        return `${row.runBy.firstName} ${row.runBy.lastName}`
      }
    },
    {
      Header: 'Download',
      Cell: CellDownload
    },
    {
      Header: 'Options',
      Cell: CellSettingsMenu
    }
  ],
  defaultSort: []
}

export const RUN_STATUS = {
  ARCHIVED: 'ARCHIVED',
  ACTIVE: 'ACTIVE',
  MARK_AS_FINAL: 'MARK_AS_FINAL'
}

export const RUN_DISPLAY_STATUS = {
  ARCHIVED: 'Archived',
  ACTIVE: 'Active',
  MARK_AS_FINAL: 'Marked as Final'
}

export const RUN_REPORT_DISPLAY_STATUS = {
  REVIEW: 'Review',
  PENDING: 'Pending',
  ERROR: 'Error'
}

export const RUN_STATUS_LABELS = {
  ARCHIVED: 'Archive',
  MARK_AS_FINAL: 'Mark as Final'
}

const MONTHLY = 'M'
const QUARTERLY = 'Q'
const ANNUAL = 'A'

export const getNearestWorkableAsOfDate = (holidays, defaultAsOfDate, searchBackwards = true) => {
  if (!holidays || !defaultAsOfDate) return null

  let date = dayjs.isDayjs(defaultAsOfDate)
    ? defaultAsOfDate
    : dayjs.utc(defaultAsOfDate)

  let isHolidayOrWeekend = true
  do {
    isHolidayOrWeekend = disableHolidaysAndWeekends(date, holidays, false)
    if (isHolidayOrWeekend) {
      date = searchBackwards
        ? date.subtract(1, 'day')
        : date.add(1, 'day')
      continue
    }
    isHolidayOrWeekend = false
  } while (isHolidayOrWeekend)

  return date.format(INTERNAL_DATE_FORMAT)
}

function lastBusinessDayOfMonth (year, month) {
  const date = new Date()
  let counter = 0
  let result = null

  if (typeof year === 'undefined' || year === null) {
    year = date.getFullYear()
  }

  if (typeof month === 'undefined' || month === null) {
    month = date.getMonth()
  }

  do {
    result = new Date(year, month, counter)
    counter--
  } while (result.getDay() === 0 || result.getDay() === 6)

  return dayjs(result).format(INTERNAL_DATE_FORMAT)
}

function getAvailableYears (min, max) {
  const minDate = dayjs(min)
  const maxYear = dayjs(max).year()
  const availableYears = []

  let minYear = null

  for (let i = 0; minYear <= maxYear; i++) {
    minYear = minDate.add(i, 'year').year()
    if (minYear <= maxYear) {
      availableYears.push(minYear)
    }
  }

  return availableYears
}

export function getAvailableBusinessDays (min, max) {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const years = getAvailableYears(min, max) ?? []
  const businessDays = []
  let businessDay = null

  for (let i = 0; i <= years.length; i++) {
    const year = years[i]
    for (let j = 0; j <= months.length; j++) {
      const month = months[j]
      businessDay = lastBusinessDayOfMonth(year, month)

      if (!businessDays.includes(businessDay)) {
        businessDays.push(businessDay)
      }
    }
  }

  return businessDays
}

export function disableNoBusinnesDates (rawDate, businessDays) {
  const date = dayjs(rawDate).format(INTERNAL_DATE_FORMAT)

  return !businessDays.includes(date)
}

export function getPayloadDates (asOfDate, frequency) {
  const date = dayjs.utc(asOfDate)
  const endDate = date

  const isMonthly = frequency === MONTHLY
  const isQuarterly = frequency === QUARTERLY
  const isYearly = frequency === ANNUAL

  const unit =
    (isMonthly && 'month') ||
    (isQuarterly && 'quarter') ||
    (isYearly && 'year')

  const startDate = endDate.startOf(unit)

  return {
    startDate: startDate.format(INTERNAL_DATE_FORMAT),
    endDate: endDate.format(INTERNAL_DATE_FORMAT)
  }
}

export const REPORT_TYPES = {
  CASH_AVAILABLE_REPORT: 'CASH_AVAILABLE_REPORT'
}

export const REPORT_TYPE_NAMES = {
  [REPORT_TYPES.CASH_AVAILABLE_REPORT]: 'Cash Available'
}

export const BILLING_RUNS_CONTEXT_KEY = 'BILLING_RUNS_CONTEXT_KEY'
