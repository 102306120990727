import React from 'react'
import { Box } from '@material-ui/core'
import Avatar from '../../../atoms/Avatar'

const columns = [
  {
    Header: 'Client',
    accessor: (row) => (
      <Box display='flex' alignItems='center'>
        <Box sx={{ marginRight: '0.75rem' }}>
          <Avatar
            customSize='2rem'
            src={row?.profilePic}
            isPublicResource
            avatarLetters={row?.shortName}
          />
        </Box>
        <span>{row?.shortName}</span>
      </Box>
    ),
    id: 'shortName'
  },
  { Header: 'Full Name', accessor: 'longName', id: 'longName' }
]

export const columnsConfig = {
  columns,
  defaultSort: [
    { id: 'longName', desc: false }
  ]
}
