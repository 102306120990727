import { useMemo } from 'react'
import { useOktaAuth } from '@okta/okta-react'

const useIdentity = () => {
  const { authState } = useOktaAuth()
  return useMemo(() => {
    return {
      fullName: authState.idToken?.claims?.name,
      firstName: authState.idToken?.claims?.given_name,
      lastName: authState.idToken?.claims?.family_name,
      email: authState.idToken?.claims?.email,
      claims: authState.idToken?.claims,
      userId: authState.idToken?.sub
    }
  }, [authState])
}

export default useIdentity
