import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import AddClassOverrideForm from './form'

const AddClassOverrideDialog = React.forwardRef(function AddClassOverrideDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (client) => setDialogState({ open: true, processing: false, client })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Class Override'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='xmedium'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<AddClassOverrideForm client={dialogState.client} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

AddClassOverrideDialog.propTypes = {
}

export default AddClassOverrideDialog
