import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MilestoneDescription from '../molecules/MilestoneDescription'
import TasksList from '../molecules/TasksList'
import AttachmentList from '../molecules/AttachmentList'
import { ICON_NAMES } from '../../constants'
import useMilestone from '../../hooks/useMilestone'
import Icon from '../atoms/Icon'
import Menu from '../molecules/Menu'
import { useWealthJourneyPermissions } from './WealthJourney/v2/hooks'
import entryType, { ACTIONS } from './WealthJourney/v2/entryType'

export const useStyles = () => {
  const theme = useTheme()
  const styles = makeStyles(() => ({
    milestoneDetailContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5rem',
      paddingBottom: '5rem',
      fontSize: '1rem',
      width: '100%',
      maxWidth: '1200px'
    },
    milestoneDetailContent: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    milestoneHeader: {
      marginLeft: 'auto'
    },
    milestoneTitle: {
      fontWeight: 700,
      fontSize: '1.3rem',
      marginBottom: '1.5rem'
    },
    milestoneSubtitle: {
      fontWeight: 700,
      marginBottom: '0rem'
    },
    milestoneColumn: {
      width: '50%',
      marginBottom: '1.5rem',
      height: '100%',
      display: 'flex',
      padding: '0 2rem',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 0rem'
      }
    },
    iconButton: {
      cursor: 'pointer'
    }
  }))()

  return [styles, theme]
}

const WealthJourneyMilestoneDetails = ({
  milestone,
  onEditMilestone,
  onDeleteMilestone,
  taskTitle,
  taskButtonLabel
}) => {
  const [classes] = useStyles()

  const canEditMilestones = useWealthJourneyPermissions(entryType.MILESTONES, ACTIONS.EDIT)

  const {
    tasks,
    attachments
  } = useMilestone({ milestone })

  const menuOptions = useMemo(() => ([
    {
      iconName: ICON_NAMES.edit,
      label: 'Edit',
      onClick: onEditMilestone,
      value: 'edit'
    },
    {
      iconName: ICON_NAMES.delete,
      color: '#D44333',
      label: 'Archive',
      onClick: onDeleteMilestone,
      value: 'delete'
    }
  ]), [onEditMilestone, onDeleteMilestone])

  const renderMenuIcon = useCallback(({ setAnchorEl }) => {
    return (
      <div className={classes.iconButton} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' roundedBackground='#EFF3FF' />
      </div>
    )
  }, [classes.iconButton])

  return (
    <div className={classes.milestoneDetailContainer}>
      {milestone?.entryJson && (
        <>
          {canEditMilestones && (
            <div className={classes.milestoneHeader}>
              <Menu options={menuOptions}>{renderMenuIcon}</Menu>
            </div>
          )}
          <div className={classes.milestoneDetailContent}>
            <div className={classes.milestoneColumn}>
              <div className={classes.milestoneTitle}>
                {milestone.entryJson.title}
              </div>
              {milestone.entryJson.subtitle && (
                <div className={classes.milestoneSubtitle}>
                  {milestone.entryJson.subtitle}
                </div>
              )}
              <MilestoneDescription entry={milestone.entryJson} />
              <AttachmentList
                readOnly
                documents={attachments}
              />
            </div>
            <div className={classes.milestoneColumn}>
              <TasksList
                title={taskTitle}
                buttonLabel={taskButtonLabel}
                tasks={tasks}
                readOnly
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

WealthJourneyMilestoneDetails.propTypes = {
  milestone: PropTypes.object,
  onEditMilestone: PropTypes.func.isRequired,
  onDeleteMilestone: PropTypes.func.isRequired,
  taskTitle: PropTypes.string,
  taskButtonLabel: PropTypes.string
}

export default WealthJourneyMilestoneDetails
