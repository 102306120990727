import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import { LOCAL_STORAGE_KEYS } from '../../../constants'
import { isJson } from '../../../utils'
import AdvisorHomeContextProvider from '../../pages/advisorHome/AdvisorHomeContextProvider'

function replaceUrlQueryWithArrayParams (
  arrayParams,
  location,
  preserveCurrentSearch = false
) {
  const currentParams = Object.fromEntries([
    ...new URLSearchParams(location.search)
  ])
  const params = arrayParams.reduce(
    (acc, { pathSegment, values }) => ({ ...acc, [pathSegment]: values }),
    preserveCurrentSearch ? currentParams : {}
  )
  const urlParams = new URLSearchParams(params)
  return `${location.pathname}?${urlParams.toString()}`
}

const useScopeFromQueryString = (scopes) => {
  const history = useHistory()

  const scopeObject = useMemo(() => {
    const search = history.location.search
    const params = parseUrlSearchParams({ location: { search } })

    const appliedScopes = scopes.reduce((prev, cur) => {
      prev.push({
        ...cur,
        values: (params[cur.pathSegment] || []).filter(Boolean)
      })
      return prev
    }, [])

    const scope = appliedScopes?.[0] ?? null

    if (scope && isEmpty(scope?.values)) {
      const scopeValues = localStorage.getItem(
        LOCAL_STORAGE_KEYS.advisorHomeScopeValues
      )
      if (scopeValues && isJson(scopeValues)) {
        scope.values = JSON.parse(scopeValues)
      }
    }
    return { scope }
  }, [history.location.search, scopes])

  /** rewrite parameter array */
  useEffect(() => {
    if (scopeObject.scope) {
      history.replace(
        replaceUrlQueryWithArrayParams([scopeObject.scope], history.location, true)
      )
    }
  }, [history, scopeObject])

  return scopeObject
}

const AdvisorHomeScopeProvider = ({ children, scopes }) => {
  const scope = useScopeFromQueryString(scopes)

  return (
    <AdvisorHomeContextProvider scope={scope}>
      {children}
    </AdvisorHomeContextProvider>
  )
}

AdvisorHomeScopeProvider.propTypes = {
  children: PropTypes.node,
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      groupTypeId: PropTypes.number,
      pathSegment: PropTypes.string
    })
  )
}

export default AdvisorHomeScopeProvider
