import React from 'react'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import AccountFineDetail from './AccountFineDetail'
import FeeScheduleDetail from './FeeScheduleDetail'
import AccountOverridesDetails from './OverrideFineDetail/AccountOverrides'
import ClassOverridesDetails from './OverrideFineDetail/ClassOverrides'
import PositionOverridesDetails from './OverrideFineDetail/PositionOverrides'
import AssetOverridesDetails from './OverrideFineDetail/AssetOverrides'
import { useClientReviewContext } from './ClientReviewContext'

const useStyles = makeStyles((theme) => ({
  blade: {
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.tight,
    maxHeight: 'calc(100vh - 100px)',
    minHeight: 'calc(100vh - 100px)',
    overflowY: 'auto'
  },
  bladeContent: {
    position: 'relative',
    paddingBottom: '100px',
    width: '100%'
  }
}))

function FineDetailBlade () {
  const { detail } = useClientReviewContext()
  const classes = useStyles()

  if (!detail) return null

  if (detail.type === 'account') {
    return <AccountFineDetail account={detail.detail} />
  }

  if (detail.type === 'feeSchedule') {
    return <FeeScheduleDetail {...detail} />
  }

  if (detail.type === 'accountOverrides') {
    return <AccountOverridesDetails overrides={detail.detail} />
  }

  if (detail.type === 'classOverrides') {
    return <ClassOverridesDetails overrides={detail.detail} />
  }

  if (detail.type === 'positionOverrides') {
    return <PositionOverridesDetails overrides={detail.detail} />
  }

  if (detail.type === 'assetOverrides') {
    return <AssetOverridesDetails overrides={detail.detail} />
  }

  return (
    <div className={classes.blade}>
      <FadeIn className={classes.bladeContent}>
        Nothing here yet for "{detail?.type}"
      </FadeIn>
    </div>
  )
}

FineDetailBlade.propTypes = {
}

export default FineDetailBlade
