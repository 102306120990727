import React, { useContext, useMemo, useRef, useState } from 'react'
import { TextField, makeStyles } from '@material-ui/core'
import SydButton from '../../../commonDesign/Button'
import RoundedModal from '../../../molecules/RoundedModal'
import Select from '../../../molecules/Select'
import Switch from '../../../molecules/Switch'
import { FirmReportExportContext } from './FirmReportExportContext'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '10px 0 50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px'
  },
  format: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  formatSelect: {
    minWidth: '300px'
  },
  label: {
    fontSize: theme.typography.fontSizes.h5
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '20px'
  }
}))

function mapFormatLabel (value) {
  switch (value.toLowerCase()) {
    case 'csv':
      return 'Comma Separated (.csv)'
    case 'json':
      return 'JSON'
    case 'xml':
      return 'XML'
    case '13f.xml':
      return 'SEC 13F Compatible XML'
    default:
      return 'Unknown'
  }
}

function FirmReportExportDialog () {
  const { exportState, onCancel, onConfirm, defaultName } = useContext(FirmReportExportContext)
  const formats = useMemo(() => {
    if (exportState?.report?.configuration?.export?.formats?.length > 0) {
      const options = exportState.report.configuration.export.formats.map(value => ({
        label: mapFormatLabel(value),
        value
      }))

      const defaultOption = options.at(0)
      return { options, defaultOption }
    }

    return { options: [], defaultOption: null }
  }, [exportState?.report?.configuration?.export])
  const [format, setFormat] = useState()
  const [ignoreFilter, setIgnoreFilter] = useState(false)
  const filenameRef = useRef()
  const classes = useStyles()

  return (
    <RoundedModal
      title='Export'
      open={exportState.open}
      onClose={onCancel}
      position='top'
    >
      <div className={classes.dialogBody}>
        <div className={classes.form}>
          <TextField inputRef={filenameRef} placeholder='File Name (Optional)' defaultValue={defaultName} />
          <div className={classes.format}>
            <span className={classes.label}>Format</span>
            <span>
              <Select
                className={classes.formatSelect}
                value={format || formats.defaultOption?.value}
                onChange={setFormat}
                variant='outlined-rounded'
                options={formats.options}
                defaultValue={formats.defaultOption}
              />
            </span>
          </div>
          <div className={classes.format}>
            <span className={classes.label}>Ignore Filters?</span>
            <span>
              <Switch checked={ignoreFilter} onChange={(e) => setIgnoreFilter(e.target.checked)} />
            </span>
          </div>
        </div>
        <div className={classes.actions}>
          <SydButton disabled={exportState?.processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='lg'
            onClick={() => onConfirm({
              exportType: exportState.report.configuration.export.key,
              format: format || formats.defaultOption?.value,
              fileName: filenameRef.current.value || undefined,
              asOfDate: exportState.report.configuration.asOfDate
            }, { includeFilter: !ignoreFilter })}
            processing={exportState?.processing}
          >
            Confirm
          </SydButton>
        </div>
      </div>
    </RoundedModal>
  )
}

export default FirmReportExportDialog
