import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import numeral from 'numeral'
import { useDispatch } from 'react-redux'
import SydButton from '../../../commonDesign/Button'
import SydInput from '../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter } from '../../../commonDesign/SydLabel'
import SydDatePicker from '../../../commonDesign/SydDatePicker'
import useStyles from '../styles'
import { useAppContext, useAvailableDates } from '../../../../redux/slices/appContext'
import { useHolidays } from '../../../../api/coreData'
import { disableHolidaysAndWeekends } from '../../../../utils'
import {
  setEditingAccountId,
  setSelectedCategory,
  setStepNavigation,
  setToggleIsOpen,
  useAddAccountContext
} from '../../../../redux/slices/addAccountContext'
import { useCreateManualPositionMutation } from '../../../../api/accounts'
import LoadingPlaceholder from '../LoadingPlaceholder'
import { useCurrentClient } from '../../../../api/clients'
import { postNamedQuery } from '../../../../service'
import Alert from '../../../atoms/Alert'
import SydReadOnly from '../../../commonDesign/SydReadOnly'
import { usePolicy } from '../../../../hooks/usePolicy'

dayjs.extend(utc)

const EditManualDetails = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { editingAccountId } = useAddAccountContext()
  const { clientId } = useAppContext()
  const [isLoadingEditingAccount, setIsLoadingEditingAccount] = useState(false)
  const [existingAccount, setExistingAccount] = useState(null)
  const userCanViewAdminAccounts = usePolicy('admin_view_accts')

  const [availableDates, loadingAvailableDates] = useAvailableDates()
  const { data: holidays, isLoading: loadingHolidays } = useHolidays()

  const shouldDisableDate = useCallback(
    (date) => {
      return disableHolidaysAndWeekends(date, holidays) || date.isAfter(dayjs(availableDates.mainDate))
    },
    [availableDates.mainDate, holidays]
  )

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      assetName: '',
      currentBalance: '',
      accountNumber: null,
      asOfDate: availableDates.mainDate,
      assetSymbol: null
    },
    shouldFocusError: false
  })

  const { data } = useCurrentClient()
  const { selectedCategory, labels: { manualCreateForm: formLabels } } = useAddAccountContext()
  const { createPosition, isLoading } = useCreateManualPositionMutation()
  const onSubmit = useCallback(async (formData) => {
    const normalizedData = {
      name: `Manual Account: ${formData.assetName}`,
      groups: [selectedCategory.groupType.shortName, 'client'],
      tags: [],
      item: {
        valueDate: dayjs.utc(formData.asOfDate).format('YYYY-MM-DD'),
        endingValue: numeral(formData.currentBalance).value().toString(),
        accountNumber: formData.accountNumber || null,
        accountName: formData.assetName,
        assetName: formData.assetName,
        ...(editingAccountId ? {
          accountId: editingAccountId,
          assetIdentifier: formData.assetSymbol
        } : {}),
        data: {
          [`group_${selectedCategory.groupType.shortName}`]: selectedCategory.shortName,
          group_client: data?.shortName
        }
      },
      monitor: 12 // monitor progress for up to 1 minute
    }

    try {
      const result = await createPosition(normalizedData)
      if (result.status?.isError) {
        console.warn('There was a problem adding the account', result)
      }

      if (userCanViewAdminAccounts && !editingAccountId) {
        dispatch(
          setEditingAccountId({
            accountId: result.status.batch?.resultData?.createAccounts?.insertResult?.id ?? null
          })
        )
        dispatch(setStepNavigation({ key: 'manual_adminAccountSetupLink' }))
        return
      }

      dispatch(setToggleIsOpen(false))
    } catch (err) {
      alert('There was a problem adding your account. Please try again later.')
    }
  }, [selectedCategory, dispatch, createPosition, data, editingAccountId, userCanViewAdminAccounts])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = form.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [form, onSubmit])

  const validateBalance = useCallback((value, invalidResponse) => {
    return !numeral(value).value() ? (invalidResponse ?? 'You must enter in a valid value') : true
  }, [])

  const getFieldLabels = useCallback((field, type) => {
    const types = {
      input: ['placeholder'],
      label: ['label', 'description']
    }
    return types[type].reduce((acc, key) => {
      if (formLabels[field][key]) {
        acc[key] = formLabels[field][key]
      }
      return acc
    }, {})
  }, [formLabels])

  useEffect(() => {
    if (editingAccountId) {
      (async () => {
        form.clearErrors('root.blocking')
        setIsLoadingEditingAccount(true)
        try {
          const { data: { accounts } } = await postNamedQuery('accounts', 'get-editable-accounts', {
            assignedToClientIds: [clientId],
            filters: {
              groupTypeId: [{ op: 'eq', value: 203 }],
              accountId: [{ op: 'eq', value: editingAccountId }]
            },
            includes: {
              endingValue: true,
              groups: true,
              assets: true
            }
          })
          const [accountDetails] = accounts
          const vbsCategory = accountDetails.groups.find(group => group.groupTypeId === 203)

          form.reset({
            assetName: accountDetails.accountName,
            currentBalance: accountDetails.endingValue ? numeral(accountDetails.endingValue).format('0,0.00') : '',
            accountNumber: accountDetails.accountNumber,
            asOfDate: dayjs.utc(accountDetails.asOfDate).format('YYYY-MM-DD'),
            assetSymbol: accountDetails.assets?.length ? accountDetails.assets[0].symbol : null
          })
          dispatch(setSelectedCategory({
            selectedCategory: {
              groupId: vbsCategory.groupId,
              shortName: vbsCategory.shortName,
              groupType: {
                groupTypeId: vbsCategory.groupTypeId,
                shortName: vbsCategory.groupTypeShortName
              }
            }
          }))
          setExistingAccount({ accountDetails })
        } catch (err) {
          form.setError('root.blocking', { message: 'There was a problem loading this account. Close this modal and try again' })
        }
        setIsLoadingEditingAccount(false)
      })()
    } else {
      setExistingAccount(null)
    }
  }, [clientId, dispatch, editingAccountId, form])

  return (
    <div className={classes.modalInner}>
      {loadingAvailableDates || loadingHolidays || isLoadingEditingAccount ? (
        <LoadingPlaceholder />
      ) : (
        <>
          {form.formState.errors?.root && (
            <Alert
              severity='error'
              title='Error'
              variant='standard'
            >
              {form.formState.errors?.root?.blocking?.message || 'An error occurred'}
            </Alert>
          )}
          {!form.formState.errors?.root?.blocking && (
            <>
              <div className={classes.modalInnerContent}>
                {userCanViewAdminAccounts && editingAccountId && (
                  <Box justifyContent='end' display='flex' my='-12px'>
                    <SydButton variant='primary' size='sm' onClick={() => window.open(`/admin/accounts/${editingAccountId}`, '_blank')}>
                      {formLabels.adminLinkText}
                    </SydButton>
                  </Box>
                )}
                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                  <Grid item sm={12} md={7}>
                    <Controller
                      name='assetName'
                      control={form.control}
                      rules={{
                        required: formLabels.assetName.required,
                        maxLength: { value: 100, message: formLabels.assetName.maxLength }
                      }}
                      render={({ field, fieldState }) => (
                        <SydLabel
                          {...getFieldLabels('assetName', 'label')}
                          error={hookFormErrorAdapter(form, fieldState)}
                          required
                        >
                          <SydInput {...field} disabled={isLoading} {...getFieldLabels('assetName', 'input')} />
                        </SydLabel>
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Controller
                      name='currentBalance'
                      control={form.control}
                      rules={{
                        required: formLabels.currentBalance.required,
                        validate: (val) => validateBalance(val, formLabels.currentBalance.invalid)
                      }}
                      render={({ field, fieldState }) => (
                        <SydLabel
                          {...getFieldLabels('currentBalance', 'label')}
                          error={hookFormErrorAdapter(form, fieldState)}
                          required
                        >
                          <SydInput {...field} disabled={isLoading} {...getFieldLabels('currentBalance', 'input')} />
                        </SydLabel>
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={7}>
                    {existingAccount ? (
                      <SydLabel
                        {...getFieldLabels('accountNumber', 'label')}
                      >
                        <SydReadOnly disabled>{existingAccount.accountDetails.accountNumber}</SydReadOnly>
                      </SydLabel>
                    ) : (
                      <Controller
                        name='accountNumber'
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <SydLabel
                            {...getFieldLabels('accountNumber', 'label')}
                            error={hookFormErrorAdapter(form, fieldState)}
                          >
                            <SydInput {...field} autoComplete='off' disabled={isLoading} {...getFieldLabels('currentBalance', 'input')} />
                          </SydLabel>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Controller
                      name='asOfDate'
                      control={form.control}
                      rules={{ required: 'Date required' }}
                      render={({ field, fieldState }) => (
                        <SydLabel
                          {...getFieldLabels('asOfDate', 'label')}
                          error={hookFormErrorAdapter(form, fieldState)}
                        >
                          <SydDatePicker
                            {...field}
                            disabled={isLoading}
                            shouldDisableDate={shouldDisableDate}
                            {...getFieldLabels('asOfDate', 'input')}
                          />
                        </SydLabel>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.modalInnerFooter}>
                <SydButton variant='secondary' size='lg' disabled={isLoading} onClick={() => dispatch(setToggleIsOpen(false))}>Cancel</SydButton>
                <SydButton variant='primary' size='lg' processing={isLoading} onClick={submitter}>Save</SydButton>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

EditManualDetails.propTypes = {
}

export default EditManualDetails
