
export const ADMIN = {
  viewAdmin: 'admin_view'
}

export const ADVISOR = {
  viewAdvisor: 'admin_view_advisor_users',
  editAdvisor: 'admin_edit_advisor_users'
}

export const ROLES = {
  viewAdvisorRoles: 'admin_view_advisor_roles',
  editAdvisorRoles: 'admin_edit_advisor_roles',
  viewWealthOwnerRoles: 'admin_view_wo_roles',
  editWealthOwnerRoles: 'admin_edit_wo_roles'
}

export const ASSETS = {
  editAssetDetails: 'admin_edit_assetdetails',
  editAssetTags: 'admin_edit_assettags',
  editAssetHldgs: 'admin_edit_asset_hldgs',
  viewAssets: 'admin_view_assets',
  viewAssetsHldgs: 'admin_view_asset_hldgs'
}

export const ASSET_TAGS = {
  viewAssetTagTypes: 'admin_view_asset_tagtypes',
  editAssetTagTypes: 'admin_edit_asset_tagtypes'
}

export const BENCHMARKS = {
  viewBenchmarks: 'admin_view_benchmarks',
  editBlends: 'admin_edit_blends'
}

export const BILLING = {
  runBilling: 'admin_run_billing',
  viewFeeSchedules: 'admin_view_feeschedules',
  editFeeSchedules: 'admin_edit_feeschedules',
  clientReview: 'admin_billing_client_review'
}

export const DATA_HOME = {
  viewDataHome: 'admin_view_datahome'
}

export const INTEGRATIONS = {
  viewIntegrations: 'admin_view_integrations',
  editIntegrations: 'admin_edit_integrations'
}

export const REPORTS = {
  runReports: 'admin_run_reports'
}

export const ACCOUNTS = {
  viewAccounts: 'admin_view_accts',
  closeAccounts: 'admin_edit_close_acct',
  editDisplayName: 'admin_edit_acctdisplay',
  viewAccountBilling: 'admin_view_acct_billing',
  editAccountBilling: 'admin_edit_acct_billing',
  editBillingStartDate: 'admin_edit_billingStartDate',
  editPerformanceStartDate: 'admin_edit_performanceStartDate',
  editAccountClient: 'admin_edit_acct_client',
  editAccountTags: 'admin_edit_acct_tags',
  editPositionSettings: 'admin_edit_position_settings',
  editBillingAccounts: 'billing_edit_billing_accounts',
  editBillingOverrides: 'billing_edit_overrides',
  assignFeeSchedules: 'billing_assign_fee_schedules'
}
