import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
export const DenaliFilterContext = createContext({ filter: {} })

export const useDenaliFilter = () => {
  const { filter } = useContext(DenaliFilterContext)

  return filter
}

export function ClientFilter ({ as, children }) {
  const { clientId } = useAppContext()

  const _value = useMemo(() => {
    return {
      filter: {
        [as]: clientId
      }
    }
  }, [clientId, as])

  return (
    <DenaliFilterContext.Provider value={_value}>
      {children}
    </DenaliFilterContext.Provider>
  )
}

ClientFilter.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node
}

ClientFilter.defaultProps = {
  as: 'clientId'
}
