import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { postNamedCommand, postNamedQuery } from '../service'

export const useSearchAssignments = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: ['rebalancer.searchAssignments', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-assignments',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useSearchRebalancerClients = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: ['rebalancer.searchRebalancerClients', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-rebalancer-clients',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useListRebalancerClients = (modelPurpose = 'Rebalancing', options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: ['rebalancer.listRebalancerClients', userId, modelPurpose],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-rebalancer-clients',
        {
          filters: {
            modelPurpose
          }
        }
      )
      return data
    },
    select: mapper
  })
}

export const useCurrentClientAssignment = () => {
  const { userId, clientId } = useAppContext()

  return useQuery({
    queryKey: ['rabalancer.currentClientAssignment', userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-assignments',
        {
          filters: {
            levelTypeId: 201,
            levelId: clientId
          }
        }
      )

      return data?.length ? data.at(0) : null
    }
  })
}

export const useRebalancingRunReport = (rebalancingRunId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['rebalancer.getRebalancingRunReport', userId, rebalancingRunId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-rebalancing-run-report', { rebalancingRunId })

      return data
    }
  })
}

export const useSubmitRebalance = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'submit-rebalance', command)

      return data
    }
  })
}

function getAllocationVsTargetData (query) {
  return async () => {
    const { data } = await postNamedQuery('coreData', 'allocationVsTarget', query)

    return data
  }
}

export const useAllocationVsTargetQuery = (query) => {
  const { userId } = useAppContext()

  return useQuery({
    enabled: !!query,
    queryKey: ['rebalancer.getAllocationVsTarget', userId, query],
    queryFn: getAllocationVsTargetData(query)
  })
}

export const useAllocationVsTargetQueryMultiple = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  const queryMap = useMemo(() => {
    return queries.map((query) => ({
      enabled,
      queryKey: ['rebalancer.getAllocationVsTargetQueryMultiple', userId, query],
      queryFn: getAllocationVsTargetData(query)
    }))
  }, [queries, userId, enabled])

  const results = useQueries({
    queries: queryMap
  })

  /** React Query returns a new array every render, this helps determine us memoize the result array */
  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  const [safeResult, setSafeResult] = useState(results)
  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  const queryClient = useQueryClient()
  const fetchMore = useCallback(async query => {
    return await queryClient.fetchQuery({
      queryKey: ['rebalancer.getAllocationVsTargetQueryMultiple', userId, query],
      queryFn: getAllocationVsTargetData(query)
    })
  }, [queryClient, userId])

  return {
    results: safeResult,
    fetchMore
  }
}

export const useGetAssignedClientModel = (clientId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getAssignedModel', userId, 201, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-assigned-model', {
        levelId: clientId,
        levelTypeId: 201
      })

      return data
    }
  })
}

export const useGetRebalancerSettings = () => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getRebalancerSettings', userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-rebalancer-settings', {})

      return data
    }
  })
}

export const useClientAvailableRebalancerCash = (clientId, asOfDate, rebalancerSettings) => {
  const { userId } = useAppContext()
  const enabled = useMemo(() => !!(rebalancerSettings.cashTag && asOfDate), [rebalancerSettings, asOfDate])

  return useQuery({
    enabled,
    queryKey: ['rebalancer.availableCash', userId, rebalancerSettings, asOfDate, clientId],
    queryFn: async () => {
      const availableCashTask = postNamedQuery('coreData', 'getGroupedCoreData', {
        levelFilters: {
          levelTypes: ['client'],
          clientIds: [clientId],
          [`${rebalancerSettings.cashTag}TagIds`]: rebalancerSettings.cashTagIds,
          calcType: 'balance'
        },
        dateRange: {
          startDate: asOfDate,
          endDate: asOfDate
        }
      })

      const totalValueTask = postNamedQuery('coreData', 'getGroupedCoreData', {
        levelFilters: {
          levelTypes: ['client'],
          clientIds: [clientId],
          calcType: 'balance'
        },
        dateRange: {
          startDate: asOfDate,
          endDate: asOfDate
        }
      })

      const { data: availableCashResponse } = await availableCashTask
      const { data: totalValueResponse } = await totalValueTask

      const availableCash = availableCashResponse?.at(0)?.endingValue
      const totalValue = totalValueResponse?.at(0)?.endingValue

      return {
        availableCash,
        totalValue,
        availableValue: totalValue - availableCash
      }
    }
  })
}

export const useGetLastSession = (clientId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getLastSession', userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'list-previous-sessions', {
        levelId: clientId,
        levelTypeId: 201
      })

      return data.length ? data.at(0) : null
    }
  })
}

export const useGetTargetModelClosure = (targetModelId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getTargetModelClosure', userId, targetModelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-target-model-closure', {
        targetModelId
      })

      return data
    }
  })
}

export const useEffectiveTargetModel = (targetModelId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.useEffectiveTargetModel', userId, targetModelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-target-model-closure-details', {
        targetModelId,
        onlyEffectiveItems: true
      })

      return data
    }
  })
}

export const useGetTargetModel = (targetModelId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getTargetModel', userId, targetModelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-target-model', {
        targetModelId
      })

      return data
    }
  })
}

export const useTargetModelVisualizationData = (targetModelId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: ['rebalancer.getTargetModelVisualizationData', userId, targetModelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('rebalancer', 'get-target-model-visualization', {
        targetModelId
      })

      return data
    }
  })
}

export const useTargetModels = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = queryOptions

  return useQuery({
    queryKey: ['rebalancer.getTargetModels', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-target-models',
        query
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useTargetModelById = (targetModelId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = queryOptions

  return useQuery({
    queryKey: ['get-target-model', userId, targetModelId],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'get-target-model',
        { targetModelId }
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useComponentModelById = (componentModelId, includes = {}, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = queryOptions
  const includeNames = includes.includeNames ?? false
  return useQuery({
    queryKey: ['get-component-model', userId, componentModelId, includeNames],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'get-component-model',
        { componentModelId, includeNames }
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useComponentModels = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = queryOptions

  return useQuery({
    queryKey: ['rebalancer.searchComponentModels', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'search-component-models',
        query
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useListClientTargetAssignments = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = queryOptions

  return useQuery({
    queryKey: ['rebalancer.list-client-target-assignments', userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'rebalancer',
        'list-client-target-assignments',
        {
          clientId
        }
      )
      return data
    },
    enabled
  })
}

export const useModifyClientTargetAssignmentsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'modify-client-target-assignments', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { clientId } = variables
      queryClient.invalidateQueries({ queryKey: ['rebalancer.list-client-target-assignments', userId, clientId], exact: true }).catch(console.error)
    }
  })
}

export const useModifyTargetModelMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'modify-target-model', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.getTargetModels', userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['rebalancer.getTargetModel', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateComponentModelMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'create-component-model', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.searchComponentModels', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyComponentModelMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'modify-component-model', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.searchComponentModels', userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['get-component-model', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyComponentModelItemsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'modify-component-model-items', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.searchComponentModels', userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['get-component-model', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateTargetModelMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'create-target-model', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.getTargetModels', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyTargetModelComponentsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('rebalancer', 'modify-target-model-components', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['rebalancer.getTargetModels', userId], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['rebalancer.getTargetModel', userId], refetchType: 'all' }).catch(console.error)
    }
  })
}
